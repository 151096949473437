import React from "react";
import { IMAGES, checkFileSize, fileType } from "../../../shared";
import { useSnackbar } from "notistack";

const VerificationFileInput = ({
  name,
  file,
  set_File,
  errors,
  updateError,
  fileColumnName,
  statusColumnName,
  optionsColumnName,
  verificationStatusValue
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const checkfileSize = (file) => {
    let result = checkFileSize(file.size);
    let checkType = fileType(file.type);
    if (result && checkType) {
      updateError();
      set_File(file);
    } else {
      enqueueSnackbar("We only accept .jpg or .png files, files must not be larger than 5MB.", {
        variant: "error",
      });
    }
  };
  const onDeleteImage = () => {
    set_File("");
  };
  return (
    <div className="fileupload fileupload-new new-Msg file-Upld" data-provides="fileupload">
      <div className="btn-file neFs">
        <div className="fileupload-new neFs"></div>
        {!file ? (
          <input
            className="neFs"
            type="file"
            id="fileupload"
            accept=".jpg, .jpeg, .png"
            onChange={(event) => {
              checkfileSize(event.target.files[0]);
            }}
          />
        ) : null}
      </div>
      <div className={file ? "hd-Upse d-flex align-items-center justify-content-between h-100 w-100" : "hd-Upse"} aria-label="fileupload">
        <div className="row w-100 mx-auto">
          <div className="col-12 col-sm-5 mb-3 mb-sm-0">
            <div className="left-Iupse">
              <h3>{fileColumnName || "Filnamn"}</h3>
            </div>
            <div className="left_Imgup">
              <div className="casImg lftSe">
                {" "}
                <img src={IMAGES.CASINO} alt="..." />
              </div>
              <span className="left-Iupse">{name}</span>
              <div className="fileupload-preview"></div>
            </div>
          </div>
          <div className="col-12 col-sm-7">
            <div className="row no-gutters">
              <div className="col-5">
                <div className="left-Iupse">
                  <h3>{statusColumnName || "Status"}</h3>
                </div>
                <div className="casImg">
                  <span>{verificationStatusValue || "Pending"}</span>
                  {/* <img src={IMAGES.FILE_STATUS} alt="..." /> */}
                </div>
              </div>
              <div className="col-7 topRdt">
                <div className="left-Iupse">
                  <h3>{optionsColumnName || "Alternativ"}</h3>
                </div>
                <div className="d-flex justify-content-center align-items-center topRdt1">
                  <div className="fileupload-exists p-upld mx-1">
                    {/* to  change the uploaded image */}
                    <input
                      className="neFs2"
                      type="file"
                      onChange={(event) => {
                        checkfileSize(event.target.files[0]);
                      }}
                    />
                    <i className="fa fa-pencil-square-o edit" aria-hidden="true"></i>{" "}
                  </div>

                  <div className="delSe mx-1">
                    <a className="close fileupload-exists" data-dismiss="fileupload" style={{ float: "none" }}>
                      <i onClick={() => onDeleteImage()} className="fa fa-trash" aria-hidden="true"></i>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerificationFileInput;
