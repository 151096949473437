import React, { useState } from "react";
import Switch from "react-switch";

import { IMAGES } from "../../../../shared";
import "./styles.scss";

const MaintenanceScreen = ({ headerContent }) => {
  const [activeClass, setActiveClass] = useState("fi");

  const handleActiveClassChange = (val) => {
    if (val) {
      return setActiveClass("en");
    }
    return setActiveClass("fi");
  }

  return (
    <div className="banner-Mainse">
      <div className="container">
        <div className={`inner-Banse`} id="home-banner-div">
          <div className="logo-Main" id="logo-main-id">
            <a onClick={() => { }}>
              <img src={IMAGES.RISKIVAPAA_LOGO
              }
                alt="logo" />
            </a>
          </div>
          <div className="main-div">
            <div className="main-maintenance-content">
              <div className="toggle-btn-div mb-3 d-flex align-items-center justify-content-center">
                <label className={`${activeClass == "fi" ? "active-maintenance" : ""} mr-3 mb-0`}>Finnish</label>
                <Switch
                  className="underMaintenance-toggle-btn"
                  onChange={handleActiveClassChange}
                  checked={activeClass == "en"}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={25}
                  width={50}
                />
                <label className={`${activeClass == "en" ? "active-maintenance" : ""} ml-3 mb-0`}>English</label>
              </div>
              <h1 dangerouslySetInnerHTML={{
                __html:
                  (headerContent && activeClass == "fi") ? headerContent.title_Fi : headerContent.title_En
              }} className="content-heading"></h1>
              <div className="maintenance-content mt-2 px-2" dangerouslySetInnerHTML={{
                __html:
                  (headerContent && activeClass == "fi") ? headerContent.content_Fi : headerContent.content_En,
              }}>
              </div>
            </div>
          </div>
          <div className="ban-Botse">
            <img src={IMAGES.RABIT_ANIMATION} alt="Image" />
          </div>
        </div>
      </div>
      <div className="bottom-Layse">
        <img src={IMAGES.H_CURVE} alt="Curve" class="img-fluid w-100" />
      </div>
    </div>
  )
};

export default MaintenanceScreen;
