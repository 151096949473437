import { all, fork } from "redux-saga/effects";
import AuthSaga from "./auth";
import ProfileSaga from "./profile";
import FaQSaga from "./FAQ";
import HowItWorksSaga from "./HowItsWorks";
import HeaderSaga from "./headers";
import PagesSaga from "./pages";
import ReviewSaga from "./reviews";
import NewsSaga from "./news";
import WalletSaga from "./wallet";
import verificationHistorySagas from "./verificationHistory";
import NotificationSaga from "./notification";
import FooterSaga from "./Footer";
import DynamicContentSaga from "./dynamicContent";
import PopupContentSaga from "./popupContent";
import SideBarContentSaga from "./sideBarContent";
import LocationSaga from "./location";
import onBoardingSaga from "./onboardingModal";


function* dataSaga() {
  yield all([
    fork(AuthSaga),
    fork(ProfileSaga),
    fork(FaQSaga),
    fork(HowItWorksSaga),
    fork(HeaderSaga),
    fork(PagesSaga),
    fork(ReviewSaga),
    fork(NewsSaga),
    fork(WalletSaga),
    fork(verificationHistorySagas),
    fork(NotificationSaga),
    fork(FooterSaga),
    fork(DynamicContentSaga),
    fork(PopupContentSaga),
    fork(SideBarContentSaga),
    fork(LocationSaga),
    fork(onBoardingSaga)
  ]);
}

export default dataSaga;
