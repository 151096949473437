import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { getUserWallet, withdrawalApprovalRequest, getLastMoneyPaidList } from "../../../redux/actions";
import { IMAGES, ROUTE_CONSTANTS, TEXT_CONST } from "../../../shared";

const WithdrawalApprove = ({
    setSuccess = () => { },
    withdrawalApprovalRequest = () => { },
    withdrawalApprovalDynamicContent = {},
    setApprovePopup = () => { },
    getUserWallet = () => { },
    getLastMoneyPaidList = () => { }
}) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [withdrawalApprovalLabels, setWithdrawalApprovalLabels] = useState((withdrawalApprovalDynamicContent.withdrawalApprovalLabels) || {})

    const onSubmitClick = () => {
        withdrawalApprovalRequest((success) => {
            if (!success) {
                enqueueSnackbar(TEXT_CONST.WITHDRAWAL_ERROR, { variant: "error" });
            }
            else {
                enqueueSnackbar(TEXT_CONST.WITHDRAWAL_SUCCESS, { variant: "success" });
                setSuccess(true);
                setApprovePopup(false);
                getUserWallet(() => { });
                getLastMoneyPaidList({});
                history.push(ROUTE_CONSTANTS.DASHBOARD);
            }
        })
    }

    return (
        <div className="modal-Wrapse mt-0 text-right">
            <p
                className="go-back"
                style={{ cursor: 'pointer', fontSize: '14px' }}
                onClick={() => {
                    setSuccess(false);
                    setApprovePopup(false);
                }}
            ><img src={IMAGES.CloseIcon} alt="" /></p>
            <div className="login-Wrapse">
                <div className="row no-gutters">
                    <div className="col-lg-12">
                        <div className="right-Logse">
                            <div className="binny-Mose">
                                <img src={IMAGES.RABIT_ANIMATION} alt="Image" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="left-Logse">
                            <h3
                                dangerouslySetInnerHTML={{
                                    __html: withdrawalApprovalLabels.title || `${TEXT_CONST.APRROVE_TITLE}`
                                }}
                            ></h3>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: withdrawalApprovalLabels.content || `Grattis! Du har precis verifierat din uttagsprocess. Godkänn att du fortsätter. Du får dina pengar efter godkännande.`
                                }}
                            ></p>

                            <div className="lg-Btnse">
                                <a
                                    onClick={onSubmitClick}
                                    className="primary-Gbtn full-Width dark-Green "
                                >
                                    {/* <i>
                                        <img src={IMAGES.BANK_ID} alt="..." />
                                        </i> */}
                                    {withdrawalApprovalLabels.buttonLabels?.upper || TEXT_CONST.APPROVE_BUTTON}
                                    <span>{withdrawalApprovalLabels.buttonLabels?.lower || TEXT_CONST.APPROVE_SPAN}</span>{" "}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    let { setSuccess, setApprovePopup } = props;
    return { setSuccess: setSuccess, setApprovePopup: setApprovePopup };
};

const mapDispatchToProps = (dispatch) => {
    return {
        withdrawalApprovalRequest: (callback) => dispatch(withdrawalApprovalRequest(callback)),
        getUserWallet: (callback) => dispatch(getUserWallet(callback)),
        getLastMoneyPaidList: (payload) => dispatch(getLastMoneyPaidList(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalApprove);