import { takeLatest, all, put, select } from "redux-saga/effects";
import { postRequest, postRequestNoAuth, updateAuthToken } from "../../helpers";
import { API, getDeviceToken, STATUS_CODE, getAuthToken } from "../../shared";
import {
  LOGIN_USER_REQUEST,
  REGISTER_USER_REQUEST,
  VERIFY_USER_REQUEST,
  startLoading,
  stopLoading,
  updateReducerAuthToken,
  getUserDataSuccess,
  LOGOUT_REQUEST,
  resetReducerData,
  resetWalletData
} from "../actions";

const LOCATION_ID = process.env.REACT_APP_LOCATION_ID;

function* registerUserSaga({ payload: { payload, success = () => { }, fail = () => { } } = {} }) {
  try {
    yield put(startLoading());
    const { data } = yield postRequest({
      API: API.REGISTER,
      DATA: { ...payload, locationId: LOCATION_ID },
    });
    if (data.statusCode == STATUS_CODE.successful) {
      success(data.data._id);
      yield put(getUserDataSuccess(data.data || {}));
      yield put(updateReducerAuthToken(data.token));
      updateAuthToken(data.token);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail("Something went wrong");
  } finally {
    yield put(stopLoading());
  }
}

function* verifyUserSaga({ payload: { payload, success = () => { }, fail = () => { } } = {} }) {
  try {
    yield put(startLoading());

    const { data } = yield postRequest({
      API: API.VERIFY_USER,
      DATA: { ...payload },
    });
    if (data.statusCode == STATUS_CODE.successful) {
      success(data.statusCode);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail("Something went wrong");
  } finally {
    yield put(stopLoading());
  }
}

function* loginUserSaga({ payload: { payload, validateUser = false, success = () => { }, fail = () => { } } = {} }) {
  try {
    yield put(startLoading());

    const deviceToken = yield select(getDeviceToken)
    const { data } = yield postRequest({
      API: API.LOGIN,
      DATA: { ...payload, registrationToken: deviceToken },
    });

    if (data.statusCode == STATUS_CODE.successful) {
      if (validateUser) {
        success();
      } else {
        success();
        yield put(getUserDataSuccess(data.data || {}));
        yield put(updateReducerAuthToken(data.token));
        updateAuthToken(data.token);
      }
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* logoutSaga({ payload: { } = {}, callback }) {
  try {
    const auth = yield select(getAuthToken);
    yield put(startLoading());

    yield postRequest({
      API: API.LOGOUT,
      headers: {
        authorization: auth,
      },
    });
    yield put(resetReducerData());
    yield put(resetWalletData());
    callback(true);
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* AuthSaga() {
  yield all([
    takeLatest(REGISTER_USER_REQUEST, registerUserSaga),
    takeLatest(VERIFY_USER_REQUEST, verifyUserSaga),
    takeLatest(LOGIN_USER_REQUEST, loginUserSaga),
    takeLatest(LOGOUT_REQUEST, logoutSaga),
  ]);
}

export default AuthSaga;
