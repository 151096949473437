import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";

import {
    TEXT_CONST,
    SIDEBAR_CONTENT_TYPES,
} from "../../../shared";
import {
    uploadDocument,
    updateProfileRequest,
} from "../../../redux/actions";
import { VerificationFileInput } from "..";

const KYC = ({
    KYCDynamicContent,
    uploadDocument = () => { },
    close = () => {},
    updateProfileRequest = () => {}
}) => {
    const [errors, set_errors] = useState({});
    const [doc1, setDoc1] = useState("");
    const [doc2, setDoc2] = useState("");
    const [docId1, setDocId1] = useState("");
    const [docId2, setDocId2] = useState("");
    const [disable, setDisbale] = useState(true);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const uploadDocumentFunc = (file, set_id) => {
        let payload = {
            file,
        };
        uploadDocument({
            payload,
            success: (data) => {
                set_id(data._id);
            },
            fail: (message) => {
                enqueueSnackbar(TEXT_CONST.SOMETHING_WENT_WRONG, { variant: "error" });
            },
        });
    };

    useEffect(() => {
        if (typeof doc1 === "object" && doc1.lastModified) {
            uploadDocumentFunc(doc1, setDocId1)
        }
    }, [doc1])

    useEffect(() => {
        if (typeof doc2 === "object" && doc2.lastModified) {
            uploadDocumentFunc(doc2, setDocId2)
        }
    }, [doc2])

    useEffect(() => {
        if(docId1 && docId2){
            setDisbale(false);
        }
    }, [docId1, docId2])

    const onSubmit = () => {
        if(!disable){
            let payload = {
                kycDocument1 : docId1,
                kycDocument2 : docId2
            }
            updateProfileRequest({
                payload,
                success: (data) => {
                  enqueueSnackbar(TEXT_CONST.UPDATE_PROFILE.UPDATED_SUCCESSFULLY, { variant: "success" });
                  close();
                  setDisbale(true);
                  setDocId1("");
                  setDocId2("");
                  setDoc1("");
                  setDoc2("");
                },
                fail: (message) => {
                  enqueueSnackbar(message, { variant: "error" });
                },
              });
        }
    }


    return (
        <div className="left-Menuse open_side4 right-Menuse5">
            <div className="step-Wrapse kyc-inner">
                <div className="step-Topse">
                    <p dangerouslySetInnerHTML={{__html: KYCDynamicContent.kycTitle}}></p>
                </div>
            </div>
            <div className="scroll_div">
                <div className="input-Grupse">
                    <label
                        dangerouslySetInnerHTML={{
                            __html: KYCDynamicContent.kycDocumentLabel1,
                        }}
                    ></label>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: KYCDynamicContent.kycDocumentDescription1,
                        }}
                    ></p>

                    <div className="upld-Wrapse">
                        <div className="top-Uptxtse">
                            {/* view before the  first image uploads */}
                            {!doc1 && (
                                <div
                                    className="top-Uptxt"
                                    dangerouslySetInnerHTML={{
                                        __html: KYCDynamicContent.kycContentBeforeUpload1,
                                    }}
                                ></div>
                            )}
                            {/* view after the first image uploads */}
                            <div
                                className="fileupload fileupload-new new-Msg file-Upld"
                                data-provides="fileupload"
                            >
                                <div className="btn-file neFs"></div>
                                <VerificationFileInput
                                    errors={errors}
                                    updateError={() => {
                                        set_errors({ ...errors, firstImage: "" });
                                    }}
                                    name={doc1 && doc1.name}
                                    file={doc1}
                                    set_File={setDoc1}
                                    fileColumnName={KYCDynamicContent.kycFileNameLabel1}
                                    statusColumnName={KYCDynamicContent.kycStatusLabel1}
                                    verificationStatusValue={KYCDynamicContent.verificationStatusValue}
                                    optionsColumnName={KYCDynamicContent.kycOptionLabel1}
                                />
                            </div>
                        </div>
                        {errors.firstImage ? <span class="error text-danger">{errors.firstImage}</span> : null}
                    </div>
                    {errors.firstImage ? (
                        <span class="error text-danger">{errors.firstImage}</span>
                    ) : null}
                </div>

                <div className="input-Grupse">
                    <label
                        dangerouslySetInnerHTML={{
                            __html: KYCDynamicContent.kycDocumentLabel2,
                        }}
                    ></label>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: KYCDynamicContent.kycDocumentDescription2,
                        }}
                    ></p>

                    <div className="upld-Wrapse">
                        <div className="top-Uptxtse">
                            {/* view before the  first image uploads */}
                            {!doc2 && (
                                <div
                                    className="top-Uptxt"
                                    dangerouslySetInnerHTML={{
                                        __html: KYCDynamicContent.kycContentBeforeUpload2,
                                    }}
                                ></div>
                            )}
                            {/* view after the first image uploads */}
                            <div
                                className="fileupload fileupload-new new-Msg file-Upld"
                                data-provides="fileupload"
                            >
                                <div className="btn-file neFs"></div>
                                <VerificationFileInput
                                    errors={errors}
                                    updateError={() => {
                                        set_errors({ ...errors, firstImage: "" });
                                    }}
                                    name={doc2 && doc2.name}
                                    file={doc2}
                                    set_File={setDoc2}
                                    fileColumnName={KYCDynamicContent.kycFileNameLabel2}
                                    statusColumnName={KYCDynamicContent.kycStatusLabel2}
                                    verificationStatusValue={KYCDynamicContent.verificationStatusValue}
                                    optionsColumnName={KYCDynamicContent.kycOptionLabel2}
                                />
                            </div>
                        </div>
                        {errors.firstImage ? <span class="error text-danger">{errors.firstImage}</span> : null}
                    </div>
                    {errors.firstImage ? (
                        <span class="error text-danger">{errors.firstImage}</span>
                    ) : null}
                </div>
                <div
                    className="input-Grupse"
                    onClick={(e) => {
                        e.preventDefault();
                        onSubmit();
                    }}
                >
                    {" "}
                    <a
                        className="primary-Gbtn full-Width gray-Btn"
                        style={{ backgroundColor: disable ? "#b2b6b2" : "#65c82d"}}
                    >
                        {KYCDynamicContent.kycTitleLabel || "Submit"}
                    </a>{" "}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    return {
        KYCDynamicContent:
            (state.common.sideBarMenuContent &&
                state.common.sideBarMenuContent[
                SIDEBAR_CONTENT_TYPES.KYC
                ]) ||
            {},

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadDocument: (payload) => dispatch(uploadDocument(payload)),
        updateProfileRequest: (payload) => dispatch(updateProfileRequest(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(KYC);
