import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { defaultConfig } from "../../../config/default";

import { IMAGES, ROUTE_CONSTANTS, TEXT_CONST } from "../../../shared";
import "./style.scss";

const Left_SideMenu = ({ footerLinksData }) => {

  const [history, setHistory] = useState(useHistory())

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      e.stopImmediatePropagation();
    });
  });
  return (
    <div className="left-Menuse">
      <div className="logo-Main">
        {" "}
        <a onClick={() => history.push(ROUTE_CONSTANTS.ROOT)}>
          <img src={
            (defaultConfig.LOCATION).toLowerCase() === 'fi'
              ?
              IMAGES.RISKIVAPAA_LOGO
              :
              IMAGES.LOGO}
            alt="logo" />
        </a>
      </div>
      <div class="menu_scroll">
        <div className="binny-Mosco">
          <a onClick={() => history.push(ROUTE_CONSTANTS.ROOT)}>
            <img src={IMAGES.RABIT_ANIMATION} alt="..." />
          </a>
        </div>
        <div className="left-Menunab">
          <ul>
            {(footerLinksData.upper || []).map((item, index) => (
              <li key={index}>
                <a
                  className={index == 2 && "live-support"}
                  href={index == 2 ? null : `${item.link}`}
                  onClick={
                    index == 2
                      ? () => window.LC_API.open_chat_window()
                      : null
                  }
                ><i className="fa fa-angle-double-right"></i> {(item.text || "").toUpperCase()}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    footerLinksData: state.common.footerLinksData,
  };
};



export default connect(mapStateToProps)(Left_SideMenu);
