import React from "react";
import TextField from '@material-ui/core/TextField';


const MaterialInput = ({
    input,
    label,
    type,
    meta: { touched, error },
    config,
    placeholder,
    widthStyle,
    style,
    maxLength,
    data,
    classForSpanTag,
    value,
    onChange = () => { },
    ...props
}) => {
    widthStyle = widthStyle ? widthStyle : "";


    return (

        <React.Fragment>
            <TextField id="outlined-basic"
                variant="outlined"
                type={type}
                label={placeholder}
                {...input}
                {...config}
                onChange={(value) => {
                    input.onChange(value);
                    onChange(value);
                }} />

        </React.Fragment>
    );
};
export default MaterialInput;
