import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";

import "./styles.css";

import { IMAGES, HEADER_CONTENT_TYPES, ROUTE_CONSTANTS } from "../../../shared";
import { ReviewsSection } from "../../atoms";
import Authentication from "../Authentication";
import { defaultConfig } from "../../../config/default";

const HomeBanner = ({
  userToken,
  title = "",
  headerContent = {},
  userData = {},
  showLogin,
  showRegister
}) => {
  const [history, setHistory] = useState(useHistory());
  const [subTitle, setSubTitle] = useState("");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    let str = headerContent.subTitle
    str = str && str.replace(`{{name}}`, userData.name ? `${userData.name}` : "")
    setSubTitle(str)
  }, [userToken, Object.keys(headerContent).length])

  return (
    <div className="banner-Mainse">
      <div className="container">
        <div className={`inner-Banse ${userToken ? "login" : ""}`} id="home-banner-div">
          <div className="logo-Main" id="logo-main-id">
            {" "}
            <a onClick={() => history.push(ROUTE_CONSTANTS.ROOT)}>
              <img src={
                (defaultConfig.LOCATION).toLowerCase() === 'fi'
                  ?
                  IMAGES.RISKIVAPAA_LOGO
                  :
                  IMAGES.LOGO}
                alt="logo" />
            </a>
          </div>
          <div className="ban-Txtwrap">
            {title ? (
              <h1>{title}</h1>
            ) : (
              <h1
                dangerouslySetInnerHTML={{
                  __html:
                    (headerContent && headerContent.title) ||
                    `FÅ TILLBAKA UPP TILL 500 SEK<br>
            PÅ DIN FÖRSTA INSÄTTNING`,
                }}
              ></h1>
            )}
            {userToken ? (
              <h4
                dangerouslySetInnerHTML={{
                  __html:
                    (subTitle) ||
                    `Välkommen till Riskfritt.se, <span>${userData.name}</span>! Du kan nu jämföra och läsa recensioner <br>
              om alla nya och bästa casinon online som innehar en svensk casino licens.`,
                }}
              ></h4>
            ) : (
              <h4
                dangerouslySetInnerHTML={{
                  __html:
                    (subTitle) ||
                    `Välkommen till Riskfritt.se! Du kan nu jämföra och läsa recensioner <br>
              om alla nya och bästa casinon online som innehar en svensk casino licens.`,
                }}
              ></h4>
            )}
            <p
              dangerouslySetInnerHTML={{
                __html:
                  (headerContent && headerContent.content) ||
                  `Att spela och vinna på ett casino handlar oftast om ren tur och alla har inte turen att vinna. Därför erbjuder vi<br>
            alla våra medlemmar som gör sin första insättning på ett casino att få tillbaka upp till 500 KR från oss, vilket <br>
            ger dig möjligheten att testa flera olika casinon och ändå få dina pengar tillbaka om oturen slår till. `,
              }}
            ></p>
            {userToken ? null : (
              /***** login or register modals *****/
              <Authentication
                headerContent={headerContent}
                showRegister={showRegister}
                showLogin={showLogin}
              />
            )}
          </div>
          <ReviewsSection headerContent={headerContent} />
          <div className="ban-Botse">
            <img src={IMAGES.RABIT_ANIMATION} alt="Image" />
          </div>
        </div>
      </div>
      <div className="bottom-Layse">
        <img src={IMAGES.H_CURVE} alt="Curve" class="img-fluid w-100" />
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    userToken: state.common.userToken,
    userData: state.common.userData,
    headerContent:
      state.common.dynamicContent[HEADER_CONTENT_TYPES.MAIN_HEADER] || {},
    showRegister: props.showRegister,
    showLogin: props.showLogin
  };
};

export default connect(mapStateToProps)(HomeBanner);
