import Screen from "./screen";
import { connect } from "react-redux";
import { getPageByPageId } from "../../../../redux/actions";
import './style.scss';

const mapStateToProps = (state) => {
  return {
    userToken: state.common.userToken
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPageByPageId: (payload) => dispatch(getPageByPageId(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
