export const GET_FOOTER_LINKS = "GET_FOOTER_LINKS";
export const SET_FOOTER_LINKS_DATA = "SET_FOOTER_LINKS_DATA";


export const getFooterLinks = (payload) => {
  return {
    type: GET_FOOTER_LINKS,
    payload
  };
};

export const setFooterLinksData = (data) => {
    return {
        type: SET_FOOTER_LINKS_DATA,
        data
    }
}
