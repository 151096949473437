import { takeLatest, all, put } from "redux-saga/effects";
import { getRequest, putRequest } from "../../helpers";
import { API, STATUS_CODE } from "../../shared";
import { startLoading, stopLoading } from "../actions";
import { GET_LAST_MONEY_BACK_LIST, GET_LAST_MONEY_PAID_LIST, GET_VERIFICATION_DOCUMENTS_HISTORY, setLastMoneyPaidList, UPDATE_VERIFY_DOCUMENT_DETAILS } from "../actions/verificationHistory";
const LOCATION_ID = process.env.REACT_APP_LOCATION_ID;

function* getVerificationHistory({
  payload: {
    payload: { actionType = "", casino, startDate, endDate },
    validateUser = false,
    success = () => { },
    fail = () => { },
  } = {},
}) {
  try {
    yield put(startLoading());
    let apiLink = `${API.GET_DOCUMENTS_VERIFICATION_HISTORY}?locationId=${LOCATION_ID}`;
    if (actionType) {
      apiLink += `&actionType=${actionType}`;
    }
    if (casino) {
      // apiLink += `${
      //   apiLink.indexOf("?") !== -1 ? "&" : "?"
      // }casino=${casino}`;
      apiLink += `&casino=${casino}`;
    }
    if (startDate && endDate) {
      apiLink += `&startDate=${startDate}&endDate=${endDate}`;
    }
    const { data } = yield getRequest({
      API: apiLink,
    });

    if (data.statusCode == STATUS_CODE.successful) {
      success(data.data);
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}


function* updateDocumentsForVerification({
  payload: {
    payload,
    validateUser = false,
    success = () => { },
    fail = () => { },
  } = {},
}) {
  const id = payload.verifyDocsId;
  try {
    yield put(startLoading());
    delete payload.verifyDocsId;
    const { data } = yield putRequest({
      API: API.UPDATE_DOCUMENT + `/${id}`,
      DATA: { ...payload },
    });

    if (data.statusCode == STATUS_CODE.successful) {
      success(data.data);
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* getMoneyBackList({
  payload: {
    payload: { },
    validateUser = false,
    success = () => { },
    fail = () => { },
  } = {},
}) {
  try {
    yield put(startLoading());
    let apiLink = `${API.GET_LAST_MONEY_BACK_LIST}?locationId=${LOCATION_ID}`;
    const { data } = yield getRequest({
      API: apiLink
    });

    if (data.statusCode == STATUS_CODE.successful) {
      success(data.data);
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* getMoneyPaidList({
  payload: {
    payload = {},
    validateUser = false,
    success = () => { },
    fail = () => { },
  } = {},
}) {
  try {
    yield put(startLoading());
    let apiLink = `${API.GET_LAST_MONEY_PAID_LIST}?locationId=${LOCATION_ID}`;
    const { data } = yield getRequest({
      API: apiLink,
      instanceWithoutLogin: true
    });

    if (data.statusCode == STATUS_CODE.successful) {
      success(data.data);
      yield put(setLastMoneyPaidList(data.data));
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* VerificationHistorySaga() {
  yield all([
    takeLatest(GET_VERIFICATION_DOCUMENTS_HISTORY, getVerificationHistory),
    takeLatest(UPDATE_VERIFY_DOCUMENT_DETAILS, updateDocumentsForVerification),
    takeLatest(GET_LAST_MONEY_BACK_LIST, getMoneyBackList),
    takeLatest(GET_LAST_MONEY_PAID_LIST, getMoneyPaidList),
  ]);
}

export default VerificationHistorySaga;
