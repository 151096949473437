// import AUTOPAYMENTS from '../../assets/img/icons/automatic_payments_icon.png'
// import BELLICON from '../../assets/img/icons/bell_icon.svg'
// import CASINOICON from "../../assets/img/icons/casino_icon.png";
// import DOWNICON from "../../assets/img/icons/down_icon.svg";
// import HAMBURGERICON from "../../assets/img/icons/hamburger_icon.svg";
// import PROFILEICON from "../../assets/img/icons/profile_icon.svg";
// import REGICON from "../../assets/img/icons/reg_icon.png";
// import WALLETPNG from "../../assets/img/icons/wallet_icon.png";
// import WALLETSVG from "../../assets/img/icons/wallet_icon.svg";
// import BANKIDICON from '../../assets/img/front/bankid_icon.png';
// import FAQICON from '../../assets/img/front/check_icon.jpg';

// import DOCCHECKICON from '../../assets/img/icons/doc_check_icon.jpg';
// //ILLUSTRATION
// import WAVESHAPE from '../../assets/img/front/wave_shape.svg'

export const ICONS = {
  //AUTO_PAYMENTS: AUTOPAYMENTS,
  // BELL_ICON: BELLICON,
  // CASINO_ICON: CASINOICON,
  // DOWN_ICON: DOWNICON,
  // HOW_IT_WORKS: [REGICON, CASINOICON, DOCCHECKICON, AUTOPAYMENTS],
  // HAMBURGER_ICON: HAMBURGERICON,
  // PROFILE_ICON: PROFILEICON,
  // REG_ICON: REGICON,
  // WALLET_PNG: WALLETPNG,
  // WALLET_SVG: WALLETSVG,
  // BANKID_ICON: BANKIDICON,
  // FAQ_ICON: FAQICON,
};

export const ILLUSTRATIONS = {
  // WAVE_SHAPE: WAVESHAPE,
}