import Screen from "./screen";
import { connect } from "react-redux";
import { updateOnboardingModal, closeOnboardingModal, openOnboardingModal } from "../../../../redux/actions/onboardingModal";
import { getProfileRequest } from "../../../../redux/actions";
import { HEADER_CONTENT_TYPES } from "../../../../shared";

const mapStateToProps = (state, props) => {
  return {
    showLogin: props.showLogin || false,
    showRegister: props.showRegister || false,
    userToken: state.common.userToken || '',
    isLandingPage: props.isLandingPage || false,
    showApprovePopup: props.showApprovePopup || false,
    popupContent: state.common.popupContent && state.common.popupContent[5],
    maintenancePageContent: state.common.dynamicContent[HEADER_CONTENT_TYPES.MAINTENANCE] || {},
    token: state.common,
    showModal: state.common.showOnBoardingModal,
    initialModalOpen: state.common.showModalAPIData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateOnboardingModal: (id, body, call) => dispatch(updateOnboardingModal(id, body, call)),
    openOnboardingModal: () => dispatch(openOnboardingModal()),
    closeOnboardingModal: () => dispatch(closeOnboardingModal()),
    getProfileRequest: (payload) => dispatch(getProfileRequest(payload))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
