import { takeLatest, all, put } from "redux-saga/effects";
import { getRequest } from "../../helpers";
import { API, STATUS_CODE } from "../../shared";
import {
  GET_SIDEBAR_MENU_LIST,
  GET_SIDEBAR_MENU_CONTENT,
  GET_NOTIFICATION_SCREEN_CONTENT,
  setSideBarMenuContentList,
  setSideBarMenuContent,
  setNotificationContent,
} from "../actions";
const LOCATION_ID = process.env.REACT_APP_LOCATION_ID;

function* getSideBarMenuList({
  payload: { payload, success = () => {} } = {},
}) {
  try {
    const { data } = yield getRequest({
      API: `${API.GET_SIDEBAR_MENU_LIST}?locationId=${LOCATION_ID}`,
    });
    if (data.statusCode == STATUS_CODE.successful) {
      yield put(setSideBarMenuContentList(data.data ? data.data[0] : {}));
      success(true);
    } else {
      throw new Error(data.msg);
    }
  } catch (error) {
    yield put(setSideBarMenuContentList({}));
  }
}

function* getSideBarMenuContent({
    payload: { payload, success = () => {} } = {},
  }) {
    try {
      const { data } = yield getRequest({
        API: `${API.GET_SIDEBAR_MENU_CONTENT_DATA}?locationId=${LOCATION_ID}`,
      });
      if (data.statusCode == STATUS_CODE.successful) {
        yield put(setSideBarMenuContent(data.data || {}));
        success(true);
      } else {
        throw new Error(data.msg);
      }
    } catch (error) {
      yield put(setSideBarMenuContent({}));
    }
  }


function* getNotificationContent({
  payload: { payload, success = () => {} } = {},
}) {
  try {
    const { data } = yield getRequest({
      API: `${API.GET_NOTIFICATION_SCREEN_CONTENT}?locationId=${LOCATION_ID}`,
    });
    if (data.statusCode == STATUS_CODE.successful) {
      yield put(setNotificationContent(data.data ? data.data[0] : {}));
      success(true);
    } else {
      throw new Error(data.msg);
    }
  } catch (error) {
    yield put(setNotificationContent({}));
  }
}

function* SideBarContentSaga() {
  yield all([takeLatest(GET_SIDEBAR_MENU_LIST, getSideBarMenuList)]);
  yield all([takeLatest(GET_SIDEBAR_MENU_CONTENT, getSideBarMenuContent)]);
  yield all([takeLatest(GET_NOTIFICATION_SCREEN_CONTENT, getNotificationContent)]);
}

export default SideBarContentSaga;
