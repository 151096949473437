export const PUT_ONBOARDING_MODAL = "PUT_ONBOARDING_MODAL";
export const OPEN_ONBOARDING_MODAL = "OPEN_ONBOARDING_MODAL";
export const CLOSE_ONBOARDING_MODAL = "CLOSE_ONBOARDING_MODAL";
export const ONBOARDING_MODALE = "ONBOARDING_MODALE";

export const updateOnboardingModal = (id, body, callback) => {
    return {
        type: PUT_ONBOARDING_MODAL,
        id,
        body,
        callback
    }
}
export const openOnboardingModal = () => {
    return {
        type: OPEN_ONBOARDING_MODAL,
    }
}
export const closeOnboardingModal = () => {
    return {
        type: CLOSE_ONBOARDING_MODAL,
    }
}
export const onBoardModal = (data) =>{
    return{
        type: ONBOARDING_MODALE,
        data
    }
}