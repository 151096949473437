import Screen from "./screen";
import { connect } from "react-redux";
import { getNewsbyPermalink } from "../../../../../redux/actions";

const mapStateToProps = (statte) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNewsbyPermalink: (permalink, data) =>
      dispatch(getNewsbyPermalink(permalink, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
