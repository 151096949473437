import { EDIT_VERIFY_DOCUMENT, SET_LAST_MONEY_PAID_LIST, SET_WITHDRAWAL_AMOUNT, UPDATE_HISTORY_LIST } from "../actions";

const initialCommonState = {
  verificationDocumentDetails: null,
  updateList: false,
  moneyPaidList: []
};

const VerificationHistoryReducer = (state = { ...initialCommonState }, action) => {
  switch (action.type) {
    case EDIT_VERIFY_DOCUMENT:
      return {
        ...state,
        verificationDocument: action.payload,
      };
    case UPDATE_HISTORY_LIST:
      return {
        ...state,
        updateList: action.payload,
      };
    case SET_LAST_MONEY_PAID_LIST:
      return {
        ...state,
        moneyPaidList: action.payload,
      };
    case SET_WITHDRAWAL_AMOUNT:
      return {
        ...state,
        withdrawalAmount: action.amount
      }
    default:
      return state;
  }
};

export default VerificationHistoryReducer;
