import React from 'react';
import { IMAGES } from '../../../shared';

const BankList = ({
    withdrawalData,
    setShowBankList
}) => {
    return (
        <div className="bankList-component-container">
            <div className="bankList-container">
                <p
                    className="go-back"                    
                    onClick={() => {
                        setShowBankList(false);
                    }}
                ><img src={IMAGES.CloseIcon} alt="" /></p>
                <div className="iframe-container">
                    <iframe
                        id="bankList_iframe"
                        src={withdrawalData?.user_form_url}
                    ></iframe>
                </div>
            </div>
        </div>
    )
}

export default BankList;