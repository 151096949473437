import firebase from "firebase/app";
import 'firebase/messaging';

var firebaseConfig = {
  apiKey: "AIzaSyCQZi3ndlLTTuJAgqIv0a-mNPBnocwa7Y4",
  authDomain: "riskfritt-fb66e.firebaseapp.com",
  projectId: "riskfritt-fb66e",
  storageBucket: "riskfritt-fb66e.appspot.com",
  messagingSenderId: "413205495357",
  appId: "1:413205495357:web:bb6ab680747ecc75837454",
  measurementId: "G-ZZRE2LGZEC"
};

// firebase.initializeApp(firebaseConfig);

// const messaging = firebase.messaging();
let messaging = null;

if (firebase.messaging.isSupported()) {
  firebase.initializeApp(firebaseConfig);
  messaging = firebase.messaging();
} else {
  console.log("no-support :(");
}

export const getToken = async (setDeviceToken) => {
  return messaging && messaging
    .getToken({
      vapidKey: `BJiyNY2kDl-uOwlhEehZ1zZKE3TCYmVatS4s1rKAWGNYb9XqTDr_zI9L2BpP3eg-RetdLme_Kobde4am0wVaX1Y`,
    })
    .then((currentToken) => {
      if (currentToken) {
        setDeviceToken(currentToken);
      } else {
        setDeviceToken("");
      }
    })
    .catch((err) => {
      setDeviceToken("");
      console.log("An error occurred while retrieving token. ", err.message);
    });
}

export const onMessageListener = () => new Promise((resolve) => {
  messaging && messaging.onMessage((payload) => {
    resolve(payload);
  });
});