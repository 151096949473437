import { takeLatest, all, put } from "redux-saga/effects";
import { postRequest, getRequest } from "../../helpers";
import { API, STATUS_CODE } from "../../shared";
import {
  UPLOAD_DOCUMENTS_FOR_VERIFICATION,
  UPLOAD_DOCUMENTS,
  GET_CASIONS_LIST_FOR_VERIFICATION,
  startLoading,
  stopLoading,
} from "../actions";
const LOCATION_ID = process.env.REACT_APP_LOCATION_ID;

function* sendDocumentsForVerificartion({
  payload: { payload, validateUser = false, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    payload.locationId = LOCATION_ID;
    const { data } = yield postRequest({
      API: `${API.VERIFY_DOCUMENTS}?locationId=${LOCATION_ID}`,
      DATA: { ...payload },
    });

    if (data.statusCode == STATUS_CODE.successful) {
      success(data.data);
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* uploadDocuments({ payload: { payload, validateUser = false, success = () => { }, fail = () => { } } = {} }) {
  try {

    yield put(startLoading());
    const formdata = new FormData();
    formdata.append("image", payload.file);

    const { data } = yield postRequest({
      API: `${API.UPLOAD_DOCUMENT}`,
      DATA: formdata,
    });
    yield put(stopLoading());
    if (data.statusCode == STATUS_CODE.successful) {
      success(data.data);
    } else {
      fail(data.msg);
    }
  } catch (error) { }
}

function* getCasionAndCompensateList({
  payload: { payload, validateUser = false, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());

    let API_URL = `${API.GET_CASINOS_LIST_FOR_VERIFICATIOM}?locationId=${LOCATION_ID}`;
    // if (payload.isCashbackCasino) {
    //   API_URL += `&isCashbackCasino=${payload.isCashbackCasino}`;
    // }
    const { data } = yield getRequest({ API: API_URL });

    if (data.statusCode == STATUS_CODE.successful) {
      success(data.data);
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* HowItWorksSaga() {
  yield all([
    takeLatest(UPLOAD_DOCUMENTS_FOR_VERIFICATION, sendDocumentsForVerificartion),
    takeLatest(UPLOAD_DOCUMENTS, uploadDocuments),
    takeLatest(GET_CASIONS_LIST_FOR_VERIFICATION, getCasionAndCompensateList),
  ]);
}

export default HowItWorksSaga;
