import Screen from './screen';
import { connect } from 'react-redux';

import { HEADER_CONTENT_TYPES } from "../../../../shared";

const mapStateToProps = state => {
    return {
        headerContent: state.common.dynamicContent[HEADER_CONTENT_TYPES.MAINTENANCE] || {}
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);