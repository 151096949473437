export const GET_SIDEBAR_MENU_LIST= "GET_SIDEBAR_MENU_LIST"
export const SET_SIDEBAR_MENU_LIST = "SET_SIDEBAR_MENU_LIST"
export const GET_SIDEBAR_MENU_CONTENT= "GET_SIDEBAR_MENU_CONTENT"
export const SET_SIDEBAR_MENU_CONTENT = "SET_SIDEBAR_MENU_CONTENT"
export const GET_NOTIFICATION_SCREEN_CONTENT= "GET_NOTIFICATION_SCREEN_CONTENT"
export const SET_NOTIFICATION_SCREEN_CONTENT = "SET_NOTIFICATION_SCREEN_CONTENT"

export const getSideBarMenuList = (callback) => {
    return {
        type: GET_SIDEBAR_MENU_LIST,
        callback
    };
};


export const setSideBarMenuContentList = (data) => {
    return {
        type: SET_SIDEBAR_MENU_LIST,
        data
    }
}

export const getSideBarMenuContent = (callback) => {
    return {
        type: GET_SIDEBAR_MENU_CONTENT,
        callback
    };
};


export const setSideBarMenuContent = (data) => {
    return {
        type: SET_SIDEBAR_MENU_CONTENT,
        data
    }
}

export const getNotificationContent = (callback) => {
    return {
        type: GET_NOTIFICATION_SCREEN_CONTENT,
        callback
    };
};


export const setNotificationContent = (data) => {
    return {
        type: SET_NOTIFICATION_SCREEN_CONTENT,
        data
    }
}



