export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const SET_UNREAD_MSG_COUNT = "SET_UNREAD_MSG_COUNT";
export const SET_NOTIFICATION_DATA = "SET_NOTIFICATION_DATA";
export const SET_NOTIFICATION_DATA_FOR_LIST_POPUP = "SET_NOTIFICATION_DATA_FOR_LIST_POPUP";
export const CLEAR_ALL_NOTIFICATIONS = "CLEAR_ALL_NOTIFICATIONS";
export const MARK_AS_READ_MESSAGE = "MARK_AS_READ_MESSAGE";

export const getAllNotifications = (Data, callback) => {
    return {
        type: GET_ALL_NOTIFICATIONS,
        Data,
        callback
    }
}

export const setUnreadMsgCount = (data) => {
  return {
    type: SET_UNREAD_MSG_COUNT,
    data
  };
};

export const setNotificationData = (data) => {
  return {
    type: SET_NOTIFICATION_DATA,
    data
  };
};

export const setNotificationDataForListPopup = (data) => {
  return {
    type: SET_NOTIFICATION_DATA_FOR_LIST_POPUP,
    data
  };
}

export const clearAllNotifications = (callback) => {
  return {
    type: CLEAR_ALL_NOTIFICATIONS,
    callback
  };
};


export const markAsReadMessage = (messageId)=> {
  return {
    type: MARK_AS_READ_MESSAGE,
    messageId
  }
}
