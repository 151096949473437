import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { IMAGES, ROUTE_CONSTANTS } from "../shared";
import { defaultConfig } from "../config/default";

const NotFound = ({ loading }) => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(loading)
    }, 1000)
  })

  return (
    <div class="not_found d-flex align-items-center justify-content-center">{
      !isLoading &&
      <React.Fragment>
        <div class="not_found_logo mb-2"><Link to="/">
          <img
            src={
              (defaultConfig.LOCATION).toLowerCase() === 'fi'
                ?
                IMAGES.RISKIVAPAA_LOGO
                :
                IMAGES.LOGO}
            alt="logo"
            width="326"
            style={{ cursor: 'pointer' }} /></Link>
        </div>
        <h1>404 - Sivua ei löydy!</h1>
        <div class="not_found_logo mt-2"><Link to="/">Palaa Riskivapaa verkkosivulle</Link>
        </div>
      </React.Fragment>
    }

    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    loading: state.common.loading
  };
};


export default connect(mapStateToProps)(NotFound);
