import { takeLatest, all, put } from "redux-saga/effects";
import { getRequest } from "../../helpers";
import { API, STATUS_CODE } from "../../shared";
import { GET_HOW_ITS_WORKS_STEPS, startLoading, stopLoading } from "../actions";

const LOCATION_ID = process.env.REACT_APP_LOCATION_ID;

function* getHowItsWorksSteps({
  payload: { payload, validateUser = false, success = () => {}, fail = () => {} } = {},
}) {
  try {
    yield put(startLoading());

    const { data } = yield getRequest({
      API: `${API.GET_HOW_IT_WORKS_STEPS}?locationId=${LOCATION_ID}`,
      DATA: { ...payload },
    });

    if (data.statusCode == STATUS_CODE.successful) {
      if (validateUser) {
        success(data.data);
      } else {
        success(data.data);
      }
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* HowItWorksSaga() {
  yield all([takeLatest(GET_HOW_ITS_WORKS_STEPS, getHowItsWorksSteps)]);
}

export default HowItWorksSaga;
