import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import $ from 'jquery';

import { Footer, Header, CasinoAccordionItem } from "../../../../components";
import "./style.scss";
import { defaultConfig } from "../../../../config/default";
import { ROUTE_CONSTANTS } from "../../../../shared";

const CasinoReview = ({ location, id, getReviewDataByPermalink = () => { }, userToken, dynamicContent }) => {
  const [casinoReviews, setCasinoReview] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [data, set_data] = useState({});
  const [casinoLink, setCasinoLink] = useState("");

  // const useQuery = () => {
  //   return new URLSearchParams(useLocation().search);
  // };
  // let query = useQuery();
  // let review_id = query.get("reviewId");

  useEffect(() => {
    const review_slug = location.pathname.split('/').pop();
    let payload = {
      permalink: review_slug,
    };
    getReviewDataByPermalink({
      payload,
      success: (data) => {
        setCasinoReview(data);
      },
      fail: (message) => {
        enqueueSnackbar(message, { variant: "error" });
      },
    });
  }, []);

  useEffect(() => {
    if (casinoReviews) {
      if (Object.keys(casinoReviews).length) {
        if (!casinoReviews.affiliatedFields.gamesOfBonus) {
          casinoReviews.affiliatedFields.gamesOfBonus = {};
        }
        if (!casinoReviews.affiliatedFields.pointsOfGames) {
          casinoReviews.affiliatedFields.pointsOfGames = {};
        }

        const {
          title,
          permalink,
          content,
          featuredImage,
          affiliatedFields: {
            colorSettings,
            textContent,
            gamesOfBonus: { bonusOfList },
            pointsOfGames: { postivePoint, negativePoint },
            reviewMainFields,
            reviewFooterFields,
            isLocked
          },
        } = casinoReviews;

        set_data({
          ...data,
          title: title,
          permalink: permalink,
          content: content,
          featuredImage: featuredImage,
          colorSettings: colorSettings,
          textContent: textContent,
          bonusOfList: bonusOfList,
          postivePoint: postivePoint,
          negativePoint: negativePoint,
          reviewMainFields: reviewMainFields,
          reviewFooterFields: reviewFooterFields,
          isLocked:isLocked
        });

        setCasinoLink(userToken ? reviewMainFields?.affiliatedLink?.affiliateKey && `/${(defaultConfig.LOCATION).toLowerCase()}/go/${reviewMainFields.affiliatedLink.affiliateKey}` : ROUTE_CONSTANTS.LOGIN);
      }
    }

    trackScrolling();
    document.addEventListener('scroll', trackScrolling);

    // returned function will be called on component unmount 
    return () => {
      document.removeEventListener('scroll', trackScrolling);
    }
  }, [casinoReviews]);

  let { bonusValue, bonusButtonSubTitle, bonusButtonTitle } =
    (casinoReviews && casinoReviews.affiliatedFields && casinoReviews.affiliatedFields.reviewMainFields) || "";

  let isBottom = (el) => {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  let trackScrolling = () => {
    const wrappedElement = document.getElementById('tpbox-Wrapse-id');
    const stickyCasino = $('#stickyCasino');
    if (!wrappedElement) {
      return stickyCasino.fadeIn();
    }
    if (isBottom(wrappedElement) && ((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 767)) {
      return stickyCasino.fadeIn();
    }
    return stickyCasino.fadeOut();
  };

  return (
    <React.Fragment>
      <div className="main-content">
        <Header title={data.title || ""} link={data.reviewMainFields && data.reviewMainFields.affiliatedLink && data.reviewMainFields.affiliatedLink.affiliateKey && `${ data?.isLocked ? '/' : `/${(defaultConfig.LOCATION).toLowerCase()}/go/${data.reviewMainFields.affiliatedLink.affiliateKey}`}`} />
        {/* <HomeBanner data={location.state || {}} /> */}
        <div className="mid-Mainsec" id="casino-detail">
          <div className="container">
            <CasinoAccordionItem item={casinoReviews} isCollapse={false} isReviewPage={true} userToken={userToken} />
            <div className="brand_lists">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-4">
                      <div className="brand-col">
                        <h3>{dynamicContent.brandNameLabel || "Brand Name:"}</h3>
                        <p> <Link style={{ color: "black" }} to={casinoLink}>{(data && data.textContent && data.textContent.brandName) || ""}</Link></p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="brand-col">
                        <h3>{dynamicContent.brandURLLabel || "Brand URL:"}</h3>
                        <p style={{ "word-break": "break-all" }}> <Link style={{ color: "black" }} to={casinoLink}>{(data && data.textContent && data.textContent.brandUrl) || ""}</Link></p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="brand-col">
                        <h3>{dynamicContent.yearEstablishedLabel || "Year Established:"}</h3>
                        <p><Link style={{ color: "black" }} to={casinoLink}>{(data && data.textContent && data.textContent.yearEstablished) || ""}</Link></p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-lg-6">
                  <div className="row">
                   
                    
                  </div>
                </div> */}

                <div className="col-lg-12">
                  <div className="row">
                  <div className="col-4">
                      <div className="brand-col">
                        <h3>{dynamicContent.registeredAddressLabel || "Registered Address:"}</h3>
                        <p><Link style={{ color: "black" }} to={casinoLink}>{(data && data.textContent && data.textContent.registeredAddress) || ""}</Link> </p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="brand-col">
                        <h3>{dynamicContent.registeredCompanyNameLabel || "Registered Company name:"}</h3>
                        <p> <Link style={{ color: "black" }} to={casinoLink}>{(data && data.textContent && data.textContent.registeredCompanyName) || ""}</Link> </p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="brand-col">
                        <h3>{dynamicContent.regulationOrLicenceLabel || "Regulation/Licence:"}</h3>
                        <p><Link style={{ color: "black" }} to={casinoLink}>{(data && data.textContent && data.textContent.regulationOrLicence) || ""}</Link></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="review_des">
          <div className="container">
            <h4>{casinoReviews && casinoReviews.title}</h4>
            <div dangerouslySetInnerHTML={{ __html: casinoReviews && casinoReviews.content }} />
          </div>
        </div>

        <div className="pros_cons_sec text-left">
          <div className="container">
            <div className="row">
              {data && data.postivePoint && data.postivePoint.length ? (
                <div className="col-lg-6">
                  <h4>
                    <i class="fa fa-file-text"></i> {dynamicContent.positivePointLabel || "Positive Point"}
                  </h4>
                  <ul>
                    {data &&
                      data.postivePoint &&
                      data.postivePoint.map((item, idx) => {
                        return <li key={idx}>{item}</li>;
                      })}
                  </ul>
                </div>
              ) : null}
              {data && data.negativePoint && data.negativePoint.length ? (
                <div className="col-lg-6">
                  <h4>
                    <i class="fa fa-file-text"></i> {dynamicContent.negativePointLabel || "Negative Point"}
                  </h4>
                  <ul className="neg-pt">
                    {data &&
                      data.negativePoint &&
                      data.negativePoint.map((item, idx) => {
                        return <li key={idx}>{item}</li>;
                      })}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div id="stickyCasino">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-md-12 col-12 text-center">
                <Link to={data.reviewMainFields && data.reviewMainFields.affiliatedLink && data.reviewMainFields.affiliatedLink.affiliateKey && `/${(defaultConfig.LOCATION).toLowerCase()}/go/${data.reviewMainFields.affiliatedLink.affiliateKey}`}>

                  <button class="btn btn-block primary-Gbtn large-Btn mb-3" type="button" >
                    {bonusButtonTitle ?
                      <React.Fragment>
                        {bonusButtonTitle} <span>{bonusButtonSubTitle}</span>
                      </React.Fragment>

                      :
                      <React.Fragment>
                        FÅ {bonusValue ? bonusValue : 0} KR TILLBAKA <span>På din första insättning</span>
                      </React.Fragment>
                    }
                  </button>
                </Link>
              </div>
              <div class="col-md-12 col-12 text-center">
                {
                  data && data.reviewFooterFields && data.reviewFooterFields.linksList.map((field, index) => {
                    let lastIdx = data && data.reviewFooterFields && data.reviewFooterFields.linksList.length || 0
                    if (field.link) {
                      return <React.Fragment><a href={field.link} target="_blank" key={index}> {field.text} </a> {index < lastIdx - 1 ? "|" : ""} </React.Fragment>
                    }
                    else {
                      return ` ${field.text} ${index < lastIdx - 1 ? "|" : ""} `
                    }
                  })
                }

                {/* 18+ | <a href="#" >Regler &amp; villkor gäller</a> | Spela ansvarsfullt | <a href="#" target="_blank" >www.stodlinjen.se</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default CasinoReview;
