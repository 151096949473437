import React from "react";
import TextField from '@material-ui/core/TextField';
import moment from "moment"


const MaterialDateInput = ({
    input,
    defaultDate,
    placeholder,
    onChange = () => { },
    ...custom
}) => {
    
    return (

        <React.Fragment>
            <TextField
                id="date"
                variant="outlined"
                label={ placeholder || "Date of Birth"}
                type="date"
                required
                defaultValue={defaultDate}
                InputLabelProps={{
                    shrink: true,
                }}
                {...input}
                {...custom}
                onChange={(e) => {
                    input.onChange(e.target.value);
                    onChange(e.target.value);
                }}
                // inputProps ={{
                //     minDate:moment(date).format("YYYY-MM-DD")
                // }}
            />
        </React.Fragment>
    );
};
export default MaterialDateInput;
