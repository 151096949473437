import React, { useCallback, useEffect, useState } from "react";
import _debounce from "lodash/debounce";
import { connect } from "react-redux";
import VerificationFileInput from "../VerificationFileInput";
import { blockInvalidChar, EMAIL_REGX, percentToNumber, SIDEBAR_CONTENT_TYPES, TEXT_CONST } from "../../../shared";
import { Select } from "../../atoms";
import "./style.scss";
import { getCompensationByReviewDetails } from "../../../redux/actions";
import DatePicker from "react-date-picker";
import ReactDatePicker from "react-datepicker";
const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const VerificationFirstStep = ({
  // setVerifySecondScreen,
  set_verificationData,
  verification_data,
  sendDocumentsForVerification,
  casinoList,
  isSucess = false,
  updateClass = () => { },
  fileSecond,
  fileFirst,
  set_fileFirst,
  set_fileSecond,
  // isClose,
  defaultCasino,
  verificationDynamicContent = {},
  walletData = {},
  setDefaultCasino = () => { },
  isCashbackCasino,
  fetchCasinoDetailsDropdown = () => { }
}) => {
  // const [fileFirst, set_fileFirst] = useState();
  // const [fileSecond, set_fileSecond] = useState();
  // const [defaultAmount, setDefaultAmount] = useState();
  const [optionsCasino, set_optionsCasino] = useState([]);
  const [disable, set_disable] = useState(false);
  const [errors, set_errors] = useState({});
  const [verificationObj, setVerificationObj] = useState({});
  const [depositAmount, setDepositAmount] = useState("");
  const [calculatedAmount, setCalculatedAmount] = useState(0);
  const [emailValue, setEmailValue] = useState("");
  const [depositDate, setDepositDate] = useState("");
  const [casinoData, setCasinoData] = useState({});
  const [compensationMessage, setCompensationMessage] = useState("");
  const [remainingCompensation, setRemainingCompensation] = useState(0);
  const [activeRemainingCompensation, setActiveRemainingCompensation] = useState(0);
  const [forHeaderRemainingCompensation, setForHeaderRemainingCompensation] = useState([]);
  const [currentCasinoDetails,setCurrentCasinoDetails] = useState({});


  useEffect(() => {
    let {
      title,
      verificationStatusValue,
      verificationContent,
      casinoFieldLabel,
      noCasinoFoundText,
      approvedCasinoText,
      casinoFieldPlaceholder,
      compensationFieldLabel,
      compensationFieldPlaceholder,
      depositAmountImageUpload,
      lostAmountImageUpload,
      buttonLabel,
      depositAmountFieldLabel,
      depositAmountFieldPlaceholder,
      calculatedAmountFieldLabel,
      dailyLimitReachedLabel,
      emailLabel,
      emailDescription,
      depositDateLabel,
      depositDateDescription,
      compensationMessage,
      leftAmountLabel
    } = verificationDynamicContent || {};


    let obj = {
      title: title || "VERIFIERA OCH FÅ PENGAR TILLBAKA",
      dailyLimitReachedLabel: dailyLimitReachedLabel || "Daily limit reached",
      verificationContent:
        verificationContent ||
        `För att vi ska kunna betala tillbaka summan du förlorade på din första insättning på ett valfritt casino
      från vår topplista så lär vi på Riskfritt.se först bekräfta att du är en ny kund på casinot och att du hade
      oturen att förlora din första insättning, vänligen följ stegen nedanför så får du en notifikation när vi har
      verifierat dina uppladdade dokument. `,
      verificationStatusValue: verificationStatusValue || "Pending",
      casinoFieldLabel: casinoFieldLabel || `<span>1.</span> Vilket casino gjorde <br /> du din första insättning på?`,
      approvedCasinoText: approvedCasinoText || "Approved",
      noCasinoFoundText: noCasinoFoundText || TEXT_CONST.NO_OPTION,
      casinoFieldPlaceholder: casinoFieldPlaceholder || `Select Casino`,
      compensationFieldLabel:
        compensationFieldLabel ||
        `<span>2.</span> Ersättning du kan <br />
      få tillbaka fylls i automatiskt`,
      compensationFieldPlaceholder:
        compensationFieldPlaceholder ||
        `Välj casino och se hur mycket du kan få tillbaka`,
      depositAmountFieldLabel: depositAmountFieldLabel,
      depositAmountFieldPlaceholder: depositAmountFieldPlaceholder,
      calculatedAmountFieldLabel: calculatedAmountFieldLabel,
      buttonLabel: buttonLabel || "Verifiera och skicka in",
      labelForDepositImg:
        depositAmountImageUpload?.label ||
        ` <span>3.</span>Ladda upp en bild på
      <br />
      att du har gjort en insättning`,
      contentForDepositImg:
        depositAmountImageUpload?.content ||
        ` Logga in på ditt konto på det utvalda casinot. Gå till dina transaktioner och ta en bild på att du har gjort en insättning.`,
      contentBeforeSelectForDepositImg:
        depositAmountImageUpload?.contentBeforeSelect ||
        `<h3>
      Dra filer hit eller <span>klicka här för att Bläddra</span>
    </h3>
    <p>
      Vi accepterar endast .jpg eller .png filer.
      <br />
      Filerna får inte vara större än 2 MB.
    </p>`,
      fileNameLabelForDepositImg:
        depositAmountImageUpload?.contentAfterSelect?.fileNameLabel,
      statusLabelForDepositImg:
        depositAmountImageUpload?.contentAfterSelect?.statusLabel,
      alternativeLabelForDepositImg:
        depositAmountImageUpload?.contentAfterSelect?.alternativeLabel,

      labelForLostImg:
        lostAmountImageUpload?.label ||
        `<span>4.</span> Ladda upp en bild på
      <br />
      att du har förlorat pengarna`,
      contentForLostImg:
        lostAmountImageUpload?.content ||
        ` Logga in på ditt konto på det utvalda casinot. Gå till dina transaktioner och ta en bild på att du har
      förlorat pengarna.`,

      contentBeforeSelectForLostImg:
        lostAmountImageUpload?.contentBeforeSelect ||
        ` <h3>
      Dra filer hit eller <span>klicka här för att Bläddra</span>
    </h3>
    <p>
      Vi accepterar endast .jpg eller .png filer.
      <br />
      Filerna får inte vara större än 2 MB.
    </p>`,
      fileNameLabelForLostImg:
        lostAmountImageUpload?.contentAfterSelect?.fileNameLabel,
      statusLabelForLostImg:
        lostAmountImageUpload?.contentAfterSelect?.statusLabel,
      alternativeLabelForLostImg:
        lostAmountImageUpload?.contentAfterSelect?.alternativeLabel,
      emailLabel: emailLabel || "5. Syötä sähköpostiosoitteesi",
      emailDescription: emailDescription || "Jotta voit saada cashback-hyvityksen, sinun täytyy syöttää sähköpostiosoite, jota käytit kasinolla.",
      depositDateLabel: depositDateLabel,
      depositDateDescription: depositDateDescription,
      compensationMessage: compensationMessage,
      leftAmountLabel
    };
    setVerificationObj(obj);
  }, [Object.keys(verificationDynamicContent).length]);

  useEffect(() => {
    if (verification_data.casino_name) {
      let result = casinoList.filter(
        (item) => item._id == verification_data.casino_name
      );

      set_verificationData({
        ...verification_data,
        refunded_amount: result[0]?.compensation ? result[0]?.compensation : 0,
        cashbackPercentForCompensation: result[0]?.cashbackPercentForCompensation || 0
      });
    }
  }, [verification_data.casino_name]);

  useEffect(() => {
    if (verificationObj.compensationMessage) {

      let compensation_active = "";
  
      casinoData?.compensationCashbackPercent?.map((item) => {
        if (casinoData?.active == 100 && item?.compensation == 100) {
          compensation_active = item?.cashbackPercent ? `${item?.cashbackPercent}` : "100%";
        } else if (casinoData?.active == 50 && item?.compensation == 50) {
           compensation_active = item?.cashbackPercent ? `${item?.cashbackPercent}` : "50%";
        } else if (casinoData?.active == 25 && item?.compensation == 25) {
           compensation_active = item?.cashbackPercent ? `${item?.cashbackPercent}` : "25%";
        } else if (casinoData?.active == 10 && item?.compensation == 10) {
            compensation_active = item?.cashbackPercent ? `${item?.cashbackPercent}` : "10%";
        }
      });
     
      let calculated_compensation = "";
      let max_compensation = "";
      let remaining_compensation = "";
      if (casinoData?.active == 100) {
        if (Number(casinoData?.maxCompensationCashback100) > (calculatedAmount + casinoData?.userCompensationAmount100)) {
          calculated_compensation = calculatedAmount + casinoData?.userCompensationAmount100;
          remaining_compensation =
            Number(casinoData?.maxCompensationCashback100) - (casinoData?.userCompensationAmount100 + calculatedAmount);
        } else {
          calculated_compensation = casinoData?.maxCompensationCashback100;
          remaining_compensation = 0;
        }
        max_compensation = casinoData?.maxCompensationCashback100;
      } else if (casinoData?.active == 50) {
        if (Number(casinoData?.maxCompensationCashback50) > (calculatedAmount + casinoData?.userCompensationAmount50)) {
          calculated_compensation = calculatedAmount + casinoData?.userCompensationAmount50;
          remaining_compensation =
            Number(casinoData?.maxCompensationCashback50) - (casinoData?.userCompensationAmount50 + calculatedAmount);
        } else {
          calculated_compensation = casinoData?.maxCompensationCashback50;
          remaining_compensation = 0;
        }
        max_compensation = casinoData?.maxCompensationCashback50;
      } else if (casinoData?.active == 25) {
        if (Number(casinoData?.maxCompensationCashback25) > (calculatedAmount + casinoData?.userCompensationAmount25)) {
          calculated_compensation = calculatedAmount + casinoData?.userCompensationAmount25;
          remaining_compensation =
            Number(casinoData?.maxCompensationCashback25) - (casinoData?.userCompensationAmount25 + calculatedAmount);
        } else {
          calculated_compensation = casinoData?.maxCompensationCashback25;
          remaining_compensation = 0;
        }
        max_compensation = casinoData?.maxCompensationCashback25;
      } else if (casinoData?.active == 10) {
        if (Number(casinoData?.maxCompensationCashback10) > (calculatedAmount + casinoData?.userCompensationAmount10)) {
          calculated_compensation = calculatedAmount + casinoData?.userCompensationAmount10;
          remaining_compensation =
            Number(casinoData?.maxCompensationCashback10) - (casinoData?.userCompensationAmount10 + calculatedAmount);
        } else {
          calculated_compensation = casinoData?.maxCompensationCashback10;
          remaining_compensation = 0;
        }
        max_compensation = casinoData?.maxCompensationCashback10;
      }
      let compensationString = verificationObj.compensationMessage;
      compensationString = compensationString.replaceAll("{{compensation_active}}", `${compensation_active}%`);
      compensationString = compensationString.replaceAll("{{calculated_compensation}}",calculated_compensation);
      compensationString = compensationString.replaceAll("{{max_compensation}}", max_compensation);
      compensationString = compensationString.replaceAll("{{remaining_compensation}}",Math.round((remaining_compensation + Number.EPSILON) * 100) / 100);
      setCompensationMessage(compensationString);

      //old code
      // let compensationString = verificationObj.compensationMessage;
      // compensationString = compensationString.replaceAll("{{compensation_active}}", compensation_active);
      // compensationString = compensationString.replaceAll("{{calculated_compensation}}", `${casinoData?.active === 25 ? casinoData?.maxCompensationCashback25 > (calculatedAmount + casinoData?.userCompensationAmount25) ? calculatedAmount + casinoData?.userCompensationAmount25 : casinoData?.maxCompensationCashback25 : casinoData?.maxCompensationCashback50 > (calculatedAmount + casinoData?.userCompensationAmount50) ? calculatedAmount + casinoData?.userCompensationAmount50 : casinoData?.maxCompensationCashback50}`);
      // compensationString = compensationString.replaceAll("{{max_compensation}}", `${casinoData?.active === 25 ? casinoData?.maxCompensationCashback25 : casinoData?.maxCompensationCashback50}`);
      // compensationString = compensationString.replaceAll("{{remaining_compensation}}", `${casinoData?.active === 25 ? Number(casinoData?.maxCompensationCashback25) > (casinoData?.userCompensationAmount25 + calculatedAmount) ? Number(casinoData?.maxCompensationCashback25) - (casinoData?.userCompensationAmount25 + calculatedAmount) : 0 : Number(casinoData?.maxCompensationCashback50) > (casinoData?.userCompensationAmount50 + calculatedAmount) ? Number(casinoData?.maxCompensationCashback50) - (casinoData?.userCompensationAmount50 + calculatedAmount) : 0}`)
      // setCompensationMessage(compensationString)
    }
  }, [defaultCasino,casinoData])

  useEffect(() => {
    if (fileFirst && fileSecond && verification_data.casino_name && emailValue && depositDate) {
      set_disable(true); //class for button change
    }

  }, [verification_data, fileFirst, fileSecond, emailValue, depositDate]);

  useEffect(() => {
    if (isSucess) {
      setTimeout(() => {
        updateClass("");
      }, 1500);

      set_fileFirst("");
      set_fileSecond("");
    }
  }, [isSucess]);

  useEffect(() => {
    let arr = [];
    // let newCasinoList = casinoList && casinoList.filter(item => {

    //   let returnFlag = false;
    //   item?.enabledCashbackForCompensation?.map((value) => {
    //     if (value?.CompensationCashbackStatus) {
    //       returnFlag = true;
    //       }
    //   })
       
    //   if (returnFlag) {
    //     return item;
    //   }
    
    // })
    
    casinoList &&
      casinoList.map((item) => {
        let isLockedFlag = false;
        item?.lockedStatus?.map((value) => {
          if (value.isLocked) {
            isLockedFlag = true;
          }
        });
        let obj = {
          value: item._id,
          isDisabled: isLockedFlag,
          label: (
            <div className={`${isLockedFlag ? "disable-casino select_img" : "select_img"}`} key={item._id}>
              {isLockedFlag ? (
                <div className="approved">
                  <div className="approved_icon">
                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                    <i className="fa fa-caret-down ryt-Se" aria-hidden="true"></i>
                  </div>
                  <div className="approved-text">
                    <span>{verificationObj.dailyLimitReachedLabel}</span>
                  </div>
                </div>
              ) : null}
              {item?.needModificationRequestsCount ? (
                <div className="approved">
                  <div className="approved_icon">
                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                    <i className="fa fa-caret-down ryt-Se" aria-hidden="true"></i>
                  </div>
                  <div className="modification-text">
                    <span>Need Modifications</span>
                  </div>
                </div>
              ) : null}
              {item.featuredImage ? (
                <div className="casino-image" style={{ backgroundColor: item.logoBackgroundColor || "transparent" }}>
                  <img src={`${REACT_APP_API_BASE_URL}${item.featuredImage}`} height="12px" width="12px" />
                </div>
              ) : null}
              <p id="casinoTitle">{item.title}</p>
            </div>
          ),
          // isdisabled: (!item.isCashbackEnabled ? true : false)
        };
        arr.push(obj);
      });
    set_optionsCasino(arr);

    if(defaultCasino?.value){
      let casinoDetails = casinoList?.filter((casino)=> casino?._id == defaultCasino?.value)?.[0];
      let  compensations = [
        {
          compensationPercent: 100,
          amount: casinoDetails?.userCompensationAmount100
        },
        {
          compensationPercent: 50,
          amount: casinoDetails?.userCompensationAmount50
        },
        {
          compensationPercent: 25,
          amount: casinoDetails?.userCompensationAmount25
        },
        {
          compensationPercent: 10,
          amount: casinoDetails?.userCompensationAmount10
        }
      ];
      fetchCasinoDetailsDropdown({casino : casinoDetails?._id,compensations:compensations,enabledCashbackForCompensation:casinoDetails?.enabledCashbackForCompensation
      },(data)=>{
        casinoDetails.active = data.active;
        casinoDetails.maxCompensationCashback100=data.maxCompensation100;
        casinoDetails.maxCompensationCashback50=data.maxCompensation50;
        casinoDetails.maxCompensationCashback25=data.maxCompensation25;
        casinoDetails.maxCompensationCashback10=data.maxCompensation10;
        setCasinoData(casinoDetails);
      });
     }
  }, [casinoList]);

  useEffect(() => {
    setDepositAmount("");
    setCalculatedAmount(0);
    set_errors({});
    setEmailValue("");
    setDepositDate("");
    // setCasinoData(casinoList.filter(casino => casino._id === defaultCasino.value)[0])
  }, [defaultCasino]);

  let filterOption = ({ label, value, data }, string) => {
    if (string) {
      let children = label?.props?.children;
      let path = children[children.length - 1]?.props;
      return path?.children?.toLowerCase()?.includes(string && string.toLowerCase());
    } else {
      return true;
    }
  };
  
  const onSubmit = () => {
    if (
      fileFirst &&
      fileSecond &&
      verification_data.casino_name &&
      calculatedAmount &&
      emailValue &&
      depositDate
    ) {

      let tempCompensationPercentage = 0;
      casinoData?.compensationCashbackPercent?.map((item) => { 
        if (casinoData?.active == 100 && item?.compensation == 100) {
          tempCompensationPercentage = item?.cashbackPercent ? item?.cashbackPercent : 100;
        } else if (casinoData?.active == 50 && item?.compensation == 50) {
          tempCompensationPercentage = item?.cashbackPercent ? item?.cashbackPercent : 50;
        } else if (casinoData?.active == 25 && item?.compensation == 25) {
          tempCompensationPercentage = item?.cashbackPercent ? item?.cashbackPercent : 25;
        } else if (casinoData?.active == 10 && item?.compensation == 10) {
          tempCompensationPercentage = item?.cashbackPercent ? item?.cashbackPercent : 10;
        }
      })
      if (!tempCompensationPercentage) {
         tempCompensationPercentage = casinoData?.active;
       }

      let tempMaxReachedCompensationPercent = 0;
      if (remainingCompensation) {
        casinoData?.compensationCashbackPercent?.map((item) => {
          if (activeRemainingCompensation == 50 && item?.compensation == 50) {
            tempMaxReachedCompensationPercent = item?.cashbackPercent;
          } else if (activeRemainingCompensation == 25 && item?.compensation == 25) {
            tempMaxReachedCompensationPercent = item?.cashbackPercent;
          } else if (activeRemainingCompensation == 10 && item?.compensation == 10) {
            tempMaxReachedCompensationPercent = item?.cashbackPercent;
          }
        });
      }
        if (casinoData?.docsId) {
          sendDocumentsForVerification({
            firstFile: fileFirst,
            secondFile: fileSecond,
            casinoName: verification_data.casino_name,
            _compensationAmount: calculatedAmount,
            _depositAmount: depositAmount,
            emailValue,
            depositDate,
            casinoData,
            docId: casinoData?.docsId,
            compensationPercentage: tempCompensationPercentage,
            remainingCompensation,
            maxReachedCompensationPercent: tempMaxReachedCompensationPercent,
            forHeaderRemainingCompensation
          });
        } else {
          sendDocumentsForVerification({
            firstFile: fileFirst,
            secondFile: fileSecond,
            casinoName: verification_data.casino_name,
            _compensationAmount: calculatedAmount,
            _depositAmount: depositAmount,
            emailValue,
            depositDate,
            casinoData,
            compensationPercentage: tempCompensationPercentage,
            remainingCompensation,
            maxReachedCompensationPercent: tempMaxReachedCompensationPercent,
            activeRemainingCompensation,
            forHeaderRemainingCompensation,
          });
        }

    }
    else {
      checkvalidation();
    }
  };
  const handleEmailChange = (value) => {
    setEmailValue(value);
    if (value.match(EMAIL_REGX)) {
      set_errors({ ...errors, emailValue: "" });
    } else {
      set_errors({ ...errors, emailValue: "*Vaaditaan" });
    }
  }

  const checkvalidation = () => {
    if (!verification_data.casino_name) {
      set_errors({ ...errors, casino: "*Vaaditaan" });
      return;
    } else if (!calculatedAmount) {
      set_errors({
        ...errors,
        calculatedAmount: "*Anna kelvollinen summa.",
      });
      return;
    } else if (verification_data.casino_name) {
      set_errors({ ...errors, casino: "" });
    }
    if (!fileFirst) {
      set_errors({ ...errors, firstImage: "*Vaaditaan" });
      return;
    } else if (fileFirst) {
      set_errors({ ...errors, firstImage: "" });
    }
    if (!fileSecond) {
      set_errors({ ...errors, secondImage: "*Vaaditaan" });
      return;
    } else if (fileSecond) {
      set_errors({ ...errors, secondImage: "" });
    }
    if (!emailValue) {
      set_errors({ ...errors, emailValue: "*Vaaditaan" });
      return;
    } else if (emailValue && !emailValue.match(EMAIL_REGX)) {
      set_errors({ ...errors, emailValue: "*Vaaditaan" });
      return;
    } else if (emailValue && emailValue.match(EMAIL_REGX)) {
      set_errors({ ...errors, emailValue: "" });
    }
    return;
  };



  const handleDepositAmountChange = (value) => {
  
    // old code
    // let amount =
    //   casinoData?.active === 25
    //     ? ( casinoData?.cashbackPercentForCompensation
    //       ? ( percentToNumber(value, casinoData?.cashbackPercentForCompensation).toFixed(2) < casinoData?.maxCompensationCashback25 - casinoData?.userCompensationAmount25
    //         ? percentToNumber(value, casinoData?.cashbackPercentForCompensation).toFixed(2)
    //         : casinoData.maxCompensationCashback25 - casinoData.userCompensationAmount25
    //         )
    //       : ( percentToNumber(value, 25).toFixed(2) < casinoData?.maxCompensationCashback25 - casinoData?.userCompensationAmount25
    //           ? percentToNumber(value, 25).toFixed(2)
    //           : casinoData?.maxCompensationCashback25 - casinoData?.userCompensationAmount25
    //         )
    //      )
    //     : ( percentToNumber(value, 50).toFixed(2) < casinoData?.maxCompensationCashback50 - casinoData?.userCompensationAmount50
    //        ? percentToNumber(value, 50).toFixed(2)
    //       : casinoData?.maxCompensationCashback50 - casinoData?.userCompensationAmount50
    //       );

    const calculateAmountBasedOnPercentage = (
      totalValueForPercentage = 0,
      cashbackPercentForCompensation = 0,
      maxCompensationCashback = 0,
      userCompensationAmount = 0
    ) => {
      let newComp = 0;

      if (
        percentToNumber(totalValueForPercentage, cashbackPercentForCompensation).toFixed(2) <
        maxCompensationCashback - userCompensationAmount
      ) {
        newComp = percentToNumber(totalValueForPercentage, cashbackPercentForCompensation).toFixed(2);
      } else {

        newComp = maxCompensationCashback - userCompensationAmount;
      }
      return newComp
    };

    let enabledCompensationCashbackPercent50 = false;
    let enabledCompensationCashbackPercent25 = false;
    let enabledCompensationCashbackPercent10 = false;

    let tempForHeaderRemainingCompensation = [];
    let tempRemainingCompensation = 0;
    let tempActiveRemainingCompensation = 0;

    casinoData?.enabledCashbackForCompensation?.map((item) => {
      if (item.enabledCompensationCashbackPercent == 50) {
        enabledCompensationCashbackPercent50 = item.CompensationCashbackStatus;
      }
      if (item.enabledCompensationCashbackPercent == 25) {
        enabledCompensationCashbackPercent25 = item.CompensationCashbackStatus;
      }
      if (item.enabledCompensationCashbackPercent == 10) {
        enabledCompensationCashbackPercent10 = item.CompensationCashbackStatus;
      }
    });

    let cashbackPercentForCompensation100 = 0;
    let cashbackPercentForCompensation50 = 0;
    let cashbackPercentForCompensation25 = 0;
    let cashbackPercentForCompensation10 = 0;

    casinoData?.compensationCashbackPercent?.map((item) => {
      if (item?.compensation == 100) {
        cashbackPercentForCompensation100 = item?.cashbackPercent ? item?.cashbackPercent : 0;
      }
      if (item?.compensation == 50) {
        cashbackPercentForCompensation50 = item?.cashbackPercent ? item?.cashbackPercent : 0;
      }
      if (item?.compensation == 25) {
        cashbackPercentForCompensation25 = item?.cashbackPercent ? item?.cashbackPercent : 0;
      }
      if (item?.compensation == 10) {
        cashbackPercentForCompensation10 = item?.cashbackPercent ? item?.cashbackPercent : 0;
      }
    });
  
    let prevExists = false;
    let tempCompensationPercent = [];
    if (casinoData?.active == 100) {
      let valueTillNow = value;
      let calculatedAmount = 0;
      let totalValueForPercentage100 =  ( 100/cashbackPercentForCompensation100) * (casinoData?.maxCompensationCashback100 - casinoData?.userCompensationAmount100);
      
      // let remainingAmount = 0;

      if (totalValueForPercentage100 <= value) {
        totalValueForPercentage100 = totalValueForPercentage100;
      } else {
        totalValueForPercentage100 = value;
      }
      
      let newComp = calculateAmountBasedOnPercentage(
        totalValueForPercentage100,
        cashbackPercentForCompensation100,
        casinoData?.maxCompensationCashback100,
        casinoData?.userCompensationAmount100
      );

      calculatedAmount = Number(newComp);
      tempCompensationPercent.push({
        compensationPercentage: 100,
        amount: calculatedAmount,
      });
  
      let is50Exists = false;
      //if 50% enabled 
      let totalValueForPercentage50 = 0;
      if (
        calculatedAmount == casinoData?.maxCompensationCashback100 - casinoData?.userCompensationAmount100 &&
        enabledCompensationCashbackPercent50
      ) {
        let isTotalLesserThanValue = false;
        totalValueForPercentage50 = ( 100/cashbackPercentForCompensation50) * (casinoData?.maxCompensationCashback50 - casinoData?.userCompensationAmount50);
        valueTillNow = value - (totalValueForPercentage100);

        if (totalValueForPercentage50 <= valueTillNow) {
          isTotalLesserThanValue = false;
        } else {
          isTotalLesserThanValue = true;
        }

         newComp = calculateAmountBasedOnPercentage(
           isTotalLesserThanValue ? valueTillNow : totalValueForPercentage50,
           cashbackPercentForCompensation50,
           casinoData?.maxCompensationCashback50,
           casinoData?.userCompensationAmount50
         );
       
        calculatedAmount = Number(newComp);
        tempCompensationPercent.push({
          compensationPercentage: 50,
          amount: calculatedAmount,
        });

        prevExists = true;
        is50Exists = true;
      }

      //if 25% enabled
      let totalValueForPercentage25 = 0;
      let is25Exists = false;

      if (
        calculatedAmount ==
          (prevExists
            ? casinoData?.maxCompensationCashback50 - casinoData?.userCompensationAmount50
            : casinoData?.maxCompensationCashback100 - casinoData?.userCompensationAmount100) &&
        enabledCompensationCashbackPercent25
      ) {
        let isTotalLesserThanValue = false;
        totalValueForPercentage25 =
          (100 / cashbackPercentForCompensation25) *
          (casinoData?.maxCompensationCashback25 - casinoData?.userCompensationAmount25);
        valueTillNow = value - (totalValueForPercentage100 + totalValueForPercentage50);

        if (totalValueForPercentage25 <= valueTillNow) {
          isTotalLesserThanValue = false;
        } else {
          isTotalLesserThanValue = true;
        }

        newComp = calculateAmountBasedOnPercentage(
          isTotalLesserThanValue ? valueTillNow : totalValueForPercentage25,
          cashbackPercentForCompensation25,
          casinoData?.maxCompensationCashback25,
          casinoData?.userCompensationAmount25
        );

        calculatedAmount = Number(newComp);
        tempCompensationPercent.push({
          compensationPercentage: 25,
          amount: calculatedAmount,
        });


        prevExists = true;
        is25Exists = true;
      }


      //if 10% enabled
      let totalValueForPercentage10 = 0;

      if (
        calculatedAmount ==
          (is25Exists
            ? casinoData?.maxCompensationCashback25 - casinoData?.userCompensationAmount25
            : is50Exists
            ? casinoData?.maxCompensationCashback50 - casinoData?.userCompensationAmount50
            : casinoData?.maxCompensationCashback100 - casinoData?.userCompensationAmount100) &&
        enabledCompensationCashbackPercent10
      ) {
        let isTotalLesserThanValue = false;
        totalValueForPercentage10 =
          (100 / cashbackPercentForCompensation10) *
          (casinoData?.maxCompensationCashback10 - casinoData?.userCompensationAmount10);
        valueTillNow = value - (totalValueForPercentage100 + totalValueForPercentage50 + totalValueForPercentage25);
        if (totalValueForPercentage10 <= valueTillNow) {
          isTotalLesserThanValue = false;
        } else {
          isTotalLesserThanValue = true;
        }

        newComp = calculateAmountBasedOnPercentage(
          isTotalLesserThanValue ? valueTillNow : totalValueForPercentage10,
          cashbackPercentForCompensation10,
          casinoData?.maxCompensationCashback10,
          casinoData?.userCompensationAmount10
        );
        calculatedAmount = Number(newComp);
        tempCompensationPercent.push({
          compensationPercentage: 10,
          amount: calculatedAmount,
        });
      }
      
    }


    if (casinoData?.active == 50) {
       let valueTillNow = value;
      let calculatedAmount = 0;
      let totalValueForPercentage50 =  ( 100/cashbackPercentForCompensation50) * (casinoData?.maxCompensationCashback50 - casinoData?.userCompensationAmount50);
      
      // let remainingAmount = 0;

      if (totalValueForPercentage50 <= value) {
        totalValueForPercentage50 = totalValueForPercentage50;
      } else {
        totalValueForPercentage50 = value;
      }

      
      let newComp = calculateAmountBasedOnPercentage(
        totalValueForPercentage50,
        cashbackPercentForCompensation50,
        casinoData?.maxCompensationCashback50,
        casinoData?.userCompensationAmount50
      );
          

      calculatedAmount = Number(newComp);
      tempCompensationPercent.push({
        compensationPercentage: 50,
        amount: calculatedAmount,
      });


      //if 25% enabled
      let totalValueForPercentage25 = 0;

      if (
        calculatedAmount == casinoData?.maxCompensationCashback50 - casinoData?.userCompensationAmount50 &&
        enabledCompensationCashbackPercent25
      ) {
        let isTotalLesserThanValue = false;
        totalValueForPercentage25 = (100 / cashbackPercentForCompensation25) *
         (casinoData?.maxCompensationCashback25 - casinoData?.userCompensationAmount25);
        valueTillNow = value - (totalValueForPercentage50);
        
        if (totalValueForPercentage25 <= valueTillNow) {
          isTotalLesserThanValue = false;
        } else {
          isTotalLesserThanValue = true;
        }

          newComp = calculateAmountBasedOnPercentage(
            isTotalLesserThanValue ? valueTillNow : totalValueForPercentage25,
            cashbackPercentForCompensation25,
            casinoData?.maxCompensationCashback25,
            casinoData?.userCompensationAmount25
          );
  
        calculatedAmount = Number(newComp);
        tempCompensationPercent.push({
          compensationPercentage: 25,
          amount: calculatedAmount,
        });
        prevExists = true;
      }


      //if 10% enabled
      let totalValueForPercentage10 = 0;

      if (
        calculatedAmount ==
          (prevExists
            ? casinoData?.maxCompensationCashback25 - casinoData?.userCompensationAmount25
            : casinoData?.maxCompensationCashback50 - casinoData?.userCompensationAmount50) &&
        enabledCompensationCashbackPercent10
      ) {
        let isTotalLesserThanValue = false;
        totalValueForPercentage10 =
          (100 / cashbackPercentForCompensation10) *
          (casinoData?.maxCompensationCashback10 - casinoData?.userCompensationAmount10);
        valueTillNow = value - (totalValueForPercentage50 + totalValueForPercentage25);

        if (totalValueForPercentage10 <= valueTillNow) {
          isTotalLesserThanValue = false;
        } else {
          isTotalLesserThanValue = true;
        }

        newComp = calculateAmountBasedOnPercentage(
          isTotalLesserThanValue ? valueTillNow : totalValueForPercentage10,
          cashbackPercentForCompensation10,
          casinoData?.maxCompensationCashback10,
          casinoData?.userCompensationAmount10
        );

        calculatedAmount = Number(newComp);
        tempCompensationPercent.push({
          compensationPercentage: 10,
          amount: calculatedAmount,
        });
  
      }
      
    }



    if (casinoData?.active == 25) {
      let valueTillNow = value;
      let calculatedAmount = 0;
      let totalValueForPercentage25 =  ( 100/cashbackPercentForCompensation25) * (casinoData?.maxCompensationCashback25 - casinoData?.userCompensationAmount25);
      
      // let remainingAmount = 0;

      if (totalValueForPercentage25 <= value) {
        totalValueForPercentage25 = totalValueForPercentage25;
      } else {
        totalValueForPercentage25 = value;
      }

      
      let newComp = calculateAmountBasedOnPercentage(
        totalValueForPercentage25,
        cashbackPercentForCompensation25,
        casinoData?.maxCompensationCashback25,
        casinoData?.userCompensationAmount25
      );
          

      calculatedAmount = Number(newComp);
      tempCompensationPercent.push({
        compensationPercentage: 25,
        amount: calculatedAmount,
      });

      //if 10% enabled
      let totalValueForPercentage10 = 0;

      if (
        calculatedAmount == casinoData?.maxCompensationCashback25 - casinoData?.userCompensationAmount25 &&
        enabledCompensationCashbackPercent10
      ) {
        let isTotalLesserThanValue = false;
        totalValueForPercentage10 = (100 / cashbackPercentForCompensation10) *
         (casinoData?.maxCompensationCashback10 - casinoData?.userCompensationAmount10);
        valueTillNow = value - (totalValueForPercentage25);
        
        if (totalValueForPercentage10 <= valueTillNow) {
          isTotalLesserThanValue = false;
        } else {
          isTotalLesserThanValue = true;
        }

          newComp = calculateAmountBasedOnPercentage(
            isTotalLesserThanValue ? valueTillNow : totalValueForPercentage10,
            cashbackPercentForCompensation10,
            casinoData?.maxCompensationCashback10,
            casinoData?.userCompensationAmount10
          );
  
        calculatedAmount = Number(newComp);
        tempCompensationPercent.push({
          compensationPercentage: 10,
          amount: calculatedAmount,
        });
   
      }
      
    }


    if (casinoData?.active == 10) {
      let calculatedAmount = 0;
      let totalValueForPercentage10 =  ( 100/cashbackPercentForCompensation10) * (casinoData?.maxCompensationCashback10 - casinoData?.userCompensationAmount10);
      
      // let remainingAmount = 0;

      if (totalValueForPercentage10 <= value) {
        totalValueForPercentage10 = totalValueForPercentage10;
      } else {
        totalValueForPercentage10 = value;
      }

      
      let newComp = calculateAmountBasedOnPercentage(
        totalValueForPercentage10,
        cashbackPercentForCompensation10,
        casinoData?.maxCompensationCashback10,
        casinoData?.userCompensationAmount10
      );
          

      calculatedAmount = Number(newComp);
      tempCompensationPercent.push({
        compensationPercentage: 10,
        amount: calculatedAmount,
      });
      
    }
      let amount = Number(tempCompensationPercent?.length && tempCompensationPercent[0].amount) || 0;
    // old code
    // if (
    //   casinoData?.active === 50 &&
    //   casinoData?.enableCompensationCashback25 &&
    //   amount === casinoData?.maxCompensationCashback50 - casinoData?.userCompensationAmount50
    // ) {
    //   const remainingAmount = value - 2 * amount;
    //   const newComp = casinoData?.cashbackPercentForCompensation
    //     ? percentToNumber(remainingAmount, casinoData?.cashbackPercentForCompensation).toFixed(2)
    //     : percentToNumber(remainingAmount, 25).toFixed(2);
    //   setRemainingCompensation(Number(newComp));
    // } else {
    //   setRemainingCompensation(0);
    // }
    if (!amount) {
      set_errors({ ...errors, calculatedAmount: "*Anna kelvollinen summa." });
    } else if (amount) {
      set_errors({ ...errors, calculatedAmount: "" });
    }

    if (verificationObj.compensationMessage) {
      let compensation_active = "";

      casinoData?.compensationCashbackPercent?.map((item) => {
        if (casinoData?.active == 100 && item?.compensation == 100) {
          compensation_active = item?.cashbackPercent ? `${item?.cashbackPercent}` : "100%";
        } else if (casinoData?.active == 50 && item?.compensation == 50) {
          compensation_active = item?.cashbackPercent ? `${item?.cashbackPercent}` : "50%";
        } else if (casinoData?.active == 25 && item?.compensation == 25) {
          compensation_active = item?.cashbackPercent ? `${item?.cashbackPercent}` : "25%";
        } else if (casinoData?.active == 10 && item?.compensation == 10) {
          compensation_active = item?.cashbackPercent ? `${item?.cashbackPercent}` : "10%";
        }
      });

      let calculated_compensation = "";
      let max_compensation = "";
      let remaining_compensation = "";
      if (casinoData?.active == 100) {
        if (Number(casinoData?.maxCompensationCashback100) > amount + casinoData?.userCompensationAmount100) {
          calculated_compensation = amount + casinoData?.userCompensationAmount100;
          remaining_compensation =
            Number(casinoData?.maxCompensationCashback100) - (casinoData?.userCompensationAmount100 + amount);
        } else {
          calculated_compensation = casinoData?.maxCompensationCashback100;
          remaining_compensation = 0;
        }
        max_compensation = casinoData?.maxCompensationCashback100;
      } else if (casinoData?.active == 50) {
        if (Number(casinoData?.maxCompensationCashback50) > amount + casinoData?.userCompensationAmount50) {
          calculated_compensation = amount + casinoData?.userCompensationAmount50;
          remaining_compensation =
            Number(casinoData?.maxCompensationCashback50) - (casinoData?.userCompensationAmount50 + amount);
        } else {
          calculated_compensation = casinoData?.maxCompensationCashback50;
          remaining_compensation = 0;
        }
        max_compensation = casinoData?.maxCompensationCashback50;
      } else if (casinoData?.active == 25) {
        if (Number(casinoData?.maxCompensationCashback25) > amount + casinoData?.userCompensationAmount25) {
          calculated_compensation = amount + casinoData?.userCompensationAmount25;
          remaining_compensation =
            Number(casinoData?.maxCompensationCashback25) - (casinoData?.userCompensationAmount25 + amount);
        } else {
          calculated_compensation = casinoData?.maxCompensationCashback25;
          remaining_compensation = 0;
        }
        max_compensation = casinoData?.maxCompensationCashback25;
      } else if (casinoData?.active == 10) {
        if (Number(casinoData?.maxCompensationCashback10) > amount + casinoData?.userCompensationAmount10) {
          calculated_compensation = amount + casinoData?.userCompensationAmount10;
          remaining_compensation =
            Number(casinoData?.maxCompensationCashback10) - (casinoData?.userCompensationAmount10 + amount);
        } else {
          calculated_compensation = casinoData?.maxCompensationCashback10;
          remaining_compensation = 0;
        }
        max_compensation = casinoData?.maxCompensationCashback10;
      }
      let compensationString = verificationObj.compensationMessage;
      compensationString = compensationString.replaceAll("{{compensation_active}}", `${compensation_active}%`);
      compensationString = compensationString.replaceAll("{{calculated_compensation}}", calculated_compensation);
      compensationString = compensationString.replaceAll("{{max_compensation}}", max_compensation);
      compensationString = compensationString.replaceAll("{{remaining_compensation}}", Math.round((remaining_compensation + Number.EPSILON) * 100) / 100);
      setCompensationMessage(compensationString);
    }
        setCalculatedAmount(Number(amount || 0));
        setForHeaderRemainingCompensation(tempCompensationPercent || []);
  };

  // const debounceFn = useCallback(_debounce(handleDepositAmountChange, 300), []);

  const onDepositAmountChange = (value) => {
     
    if(!value){
      setDepositAmount("");
      setCalculatedAmount(0);
      return ;
    }
    setDepositAmount(parseInt(value));
    // debounceFn(value, casinoData, verificationObj);
    handleDepositAmountChange(value);
  };

  const showAmount = () => {
    // let maxCompensation100 = 0;
    // let maxCompensation50 = 0;
    // let maxCompensation25 = 0;
    // let maxCompensation10 = 0;

    // if (casinoData?.active == 100) {
    //   maxCompensation100 = casinoData?.maxCompensationCashback100;
    // } else if (casinoData?.active == 50) {
    //   maxCompensation50 = casinoData?.maxCompensationCashback50;
    // } else if (casinoData?.active == 25) {
    //   maxCompensation25 = casinoData?.maxCompensationCashback25;
    // } else if (casinoData?.active == 10) {
    //   maxCompensation10 = casinoData?.maxCompensationCashback10;
    // }

    let result = 
      ((casinoData?.active == 100
        ? casinoData?.maxCompensationCashback100 - Number(casinoData?.userCompensationAmount100 + calculatedAmount)
        : casinoData?.active == 50
        ? casinoData?.maxCompensationCashback50 - Number(casinoData?.userCompensationAmount50 + calculatedAmount)
        : casinoData?.active == 25
        ? casinoData?.maxCompensationCashback25 - Number(casinoData?.userCompensationAmount25 + calculatedAmount)
        : casinoData?.maxCompensationCashback10 - Number(casinoData?.userCompensationAmount10 + calculatedAmount)) || 0);
        console.log(result,"result", casinoData?.maxCompensationCashback25,Number(casinoData?.userCompensationAmount25 + calculatedAmount));
    if (casinoData?.active == 100) {
      result = result < 0 ? 0 : result;
    } else if (casinoData?.active == 50) {
      result = result < 0 ? 0 : result;
    } else if (casinoData?.active == 25) {
      result = result < 0 ? 0 : result;
    } else if (casinoData?.active == 10) {
      result = result < 0 ? 0 : result;
    }
    return result;
  };

  const getMaxCompensation = ()=>{
    return (casinoData?.active == 100
      ? casinoData?.maxCompensationCashback100
      : casinoData?.active == 50
      ? casinoData?.maxCompensationCashback50
      : casinoData?.active == 25
      ? casinoData?.maxCompensationCashback25
      : casinoData?.maxCompensationCashback10) || 0;
  }

  const showRemainingSumMessage = () =>{
    let leftAmountLabel = verificationObj.leftAmountLabel;
    leftAmountLabel = leftAmountLabel.replaceAll("{{remaining_compensation}}", `${showAmount()} ${walletData?.currency || "EUR"}`);
    leftAmountLabel = leftAmountLabel.replaceAll("{{max_compensation}}", `${getMaxCompensation()} ${walletData?.currency || "EUR"}`);
    return leftAmountLabel;
  }


  return (
    <div className="left-Menuse open_side2 right-Menuse2">
      <div className="step-Wrapse">
        <div className="step-Topse">
          <p dangerouslySetInnerHTML={{ __html: verificationObj.title }}></p>
        </div>

        <div className="scroll_div">
          <div className="step-Secondse">
            <p
              dangerouslySetInnerHTML={{
                __html: verificationObj.verificationContent,
              }}
            ></p>
          </div>
          <div className="input-Wrapse">
            <div className="input-Grupse">
              <label
                dangerouslySetInnerHTML={{
                  __html: verificationObj.casinoFieldLabel,
                }}
              ></label>

              <div className="casino-Selctmenu new-Msg">
                {/* todo: images from api for casinos  */}
                <Select
                  label="select"
                  options={optionsCasino}
                  placeholder={verificationObj.casinoFieldPlaceholder}
                  // isClose={isClose}
                  defaultVal={defaultCasino}
                  isSearchable={true}
                  onValueChange={(value) => {
                    let casinoDetails = casinoList?.filter((casino) => casino?._id == value)?.[0];
                    let compensations = [
                      {
                        compensationPercent: 100,
                        amount: casinoDetails?.userCompensationAmount100,
                      },
                      {
                        compensationPercent: 50,
                        amount: casinoDetails?.userCompensationAmount50,
                      },
                      {
                        compensationPercent: 25,
                        amount: casinoDetails?.userCompensationAmount25,
                      },
                      {
                        compensationPercent: 10,
                        amount: casinoDetails?.userCompensationAmount10,
                      },
                    ];
                    fetchCasinoDetailsDropdown(
                      {
                        casino: casinoDetails?._id,
                        compensations: compensations,
                        enabledCashbackForCompensation: casinoDetails?.enabledCashbackForCompensation,
                      },
                      (data) => {
                        // setCurrentCasinoDetails(data);
                        casinoDetails.active = data.active;
                        casinoDetails.maxCompensationCashback100 = data.maxCompensation100;
                        casinoDetails.maxCompensationCashback50 = data.maxCompensation50;
                        casinoDetails.maxCompensationCashback25 = data.maxCompensation25;
                        casinoDetails.maxCompensationCashback10 = data.maxCompensation10;
                        setCasinoData(casinoDetails);
                      }
                    );
                    set_verificationData({
                      ...verification_data,
                      casino_name: value,
                    });
                    set_errors({ ...errors, casino: "" });
                  }}
                  handleSelectedOption={(value) => setDefaultCasino(value)}
                  filterOption={filterOption}
                  noCasinoFoundText={verificationObj.noCasinoFoundText}
                  // config={{ isOptionDisabled: (option) => option.isdisabled }}
                />
                {errors.casino ? <span class="error text-danger">{errors.casino}</span> : null}
              </div>

              {/* {!isCashbackCasino && (
                <div className="tab-content">
                  <div className="input-Grupse">
                    <label
                      dangerouslySetInnerHTML={{
                        __html: verificationObj.compensationFieldLabel,
                      }}
                    ></label>
                  </div>
                  <div className="tab-pane active" id="settings0">
                    <div className="input-Grpsec">
                      <p style={{ color: "#919191" }}>
                        {isNaN(verification_data && verification_data.refunded_amount)
                          ? verificationObj.compensationFieldPlaceholder
                          : `${verification_data && verification_data.refunded_amount} ${walletData.currency || "EUR"}`
                        }
                      </p>
                    </div>
                  </div>
                </div>
              )} */}

              <div className="tab-content">
                <div className="input-Grupse">
                  <label
                    dangerouslySetInnerHTML={{
                      __html: verificationObj.depositAmountFieldLabel || "Deposit Amount",
                    }}
                  ></label>
                </div>
                <div className="tab-pane active" id="settings0">
                  <div className="input-Grpsec">
                    <p style={{ color: "#919191" }}>
                      {!defaultCasino ? (
                        verificationObj.depositAmountFieldPlaceholder || "Enter Deposit Amount"
                      ) : (
                        <input
                          type="number"
                          min="1"
                          value={depositAmount}
                          placeholder={verificationObj.depositAmountFieldPlaceholder}
                          onChange={({ target: { value } }) => onDepositAmountChange(value)}
                          // onBlur={({ target: { value } }) => setDepositAmount( !value || value <= 0 ? 0 : value)}
                          onKeyDown={blockInvalidChar}
                          onWheel={(e) => e?.target?.blur()}
                        />
                      )}
                    </p>
                  </div>
                </div>
              </div>

              {defaultCasino && (
                <div className="tab-content">
                  <div className="input-Grupse">
                    <label
                      dangerouslySetInnerHTML={{
                        __html: verificationObj.calculatedAmountFieldLabel || "Calculated Amount",
                      }}
                    ></label>
                  </div>
                  <div className="tab-pane active" id="settings0">
                    <div className="input-Grpsec">
                      <p style={{ color: "#919191" }}>
                        {verificationObj?.leftAmountLabel ? showRemainingSumMessage() : `${showAmount()} ${walletData.currency || "EUR"} summasta ${getMaxCompensation()} ${walletData.currency || "EUR"} jäljellä`}
                        {/* {showAmount()} {walletData.currency || "EUR"} summasta{" "}
                        {`${getMaxCompensation()} ${walletData.currency || "EUR"} jäljellä`} */}
                      </p>
                    </div>
                    {errors?.calculatedAmount ? <span class="error text-danger">{errors.calculatedAmount}</span> : null}
                  </div>
                </div>
              )}
              {defaultCasino ? (
                <div>
                  {/* {`${casinoData?.active === 25 ? "25%" : "50%"} cashback compensation paid out: 
                ${casinoData?.active === 25 ? casinoData.maxCompensationCashback25 > (calculatedAmount + casinoData?.userCompensationAmount25) ? calculatedAmount + casinoData?.userCompensationAmount25 : casinoData.maxCompensationCashback25 : casinoData.maxCompensationCashback50 > (calculatedAmount + casinoData?.userCompensationAmount50) ? calculatedAmount + casinoData?.userCompensationAmount50 : casinoData.maxCompensationCashback50} 
                euro of ${casinoData?.active === 25 ? casinoData?.maxCompensationCashback25 : casinoData?.maxCompensationCashback50} euro. ${casinoData?.active === 25 ? Number(casinoData?.maxCompensationCashback25) > (casinoData?.userCompensationAmount25 + calculatedAmount) ? Number(casinoData?.maxCompensationCashback25) - (casinoData?.userCompensationAmount25 + calculatedAmount) : 0 : Number(casinoData?.maxCompensationCashback50) > (casinoData?.userCompensationAmount50 + calculatedAmount) ? Number(casinoData?.maxCompensationCashback50) - (casinoData?.userCompensationAmount50 + calculatedAmount) : 0} 
                euro more can be paid out`} */}
                  {compensationMessage}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="input-Grupse">
              <label
                dangerouslySetInnerHTML={{
                  __html: verificationObj.labelForDepositImg,
                }}
              ></label>
              <p
                dangerouslySetInnerHTML={{
                  __html: verificationObj.contentForDepositImg,
                }}
              ></p>

              <div className="upld-Wrapse">
                <div className="top-Uptxtse">
                  {/* view before the  first image uploads */}
                  {!fileFirst && (
                    <div
                      className="top-Uptxt"
                      dangerouslySetInnerHTML={{
                        __html: verificationObj.contentBeforeSelectForDepositImg,
                      }}
                    ></div>
                  )}
                  {/* view after the first image uploads */}
                  <div className="fileupload fileupload-new new-Msg file-Upld" data-provides="fileupload">
                    <div className="btn-file neFs"></div>
                    <VerificationFileInput
                      errors={errors}
                      updateError={() => {
                        set_errors({ ...errors, firstImage: "" });
                      }}
                      name={fileFirst && fileFirst.name}
                      file={fileFirst}
                      set_File={set_fileFirst}
                      fileColumnName={verificationObj.fileNameLabelForDepositImg}
                      statusColumnName={verificationObj.statusLabelForDepositImg}
                      verificationStatusValue={verificationObj.verificationStatusValue}
                      optionsColumnName={verificationObj.alternativeLabelForDepositImg}
                    />
                  </div>
                </div>
                {errors.firstImage ? <span class="error text-danger">{errors.firstImage}</span> : null}
              </div>
              {errors.firstImage ? <span class="error text-danger">{errors.firstImage}</span> : null}
            </div>
          </div>
          <div className="input-Grupse">
            <label
              dangerouslySetInnerHTML={{
                __html: verificationObj.labelForLostImg,
              }}
            ></label>
            <p
              dangerouslySetInnerHTML={{
                __html: verificationObj.contentForLostImg,
              }}
            ></p>
            <div className="upld-Wrapse">
              <div className="top-Uptxtse">
                {/* view before the second image uploads */}
                {!fileSecond && (
                  <div
                    className="top-Uptxt"
                    dangerouslySetInnerHTML={{
                      __html: verificationObj.contentBeforeSelectForLostImg,
                    }}
                  ></div>
                )}
                {/* view after the second image uploads */}
                <div className="fileupload fileupload-new new-Msg file-Upld" data-provides="fileupload">
                  <div className=" btn-file neFs">
                    <VerificationFileInput
                      errors={errors}
                      name={fileSecond && fileSecond.name}
                      file={fileSecond}
                      set_File={set_fileSecond}
                      updateError={() => {
                        set_errors({ ...errors, secondImage: "" });
                      }}
                      fileColumnName={verificationObj.fileNameLabelForLostImg}
                      statusColumnName={verificationObj.statusLabelForLostImg}
                      optionsColumnName={verificationObj.alternativeLabelForLostImg}
                      verificationStatusValue={verificationObj.verificationStatusValue}
                    />
                  </div>
                </div>
              </div>
              {errors.secondImage ? <span class="error text-danger">{errors.secondImage}</span> : null}
            </div>
            {errors.secondImage ? <span class="error text-danger">{errors.secondImage}</span> : null}
          </div>
          <div className="input-Grupse">
            <label
              dangerouslySetInnerHTML={{
                __html: verificationObj.emailLabel,
              }}
            ></label>
            <p
              dangerouslySetInnerHTML={{
                __html: verificationObj.emailDescription,
              }}
            ></p>
            <div className="input-Grpsec">
              <p style={{ color: "#919191" }}>
                <input
                  type="text"
                  value={emailValue}
                  onChange={({ target: { value } }) => {
                    handleEmailChange(value);
                  }}
                  placeholder={"example@email.com"}
                />
              </p>
            </div>
            {errors.emailValue ? <span class="error text-danger">{errors.emailValue}</span> : null}
          </div>
          <div className="input-Grupse">
            <label
              dangerouslySetInnerHTML={{
                __html: verificationObj.depositDateLabel,
              }}
            ></label>
            <p
              dangerouslySetInnerHTML={{
                __html: verificationObj.depositDateDescription,
              }}
            ></p>
            <div className="input-Grpsec">
              <p style={{ color: "#919191" }}>
                <ReactDatePicker
                 selected={depositDate}
                 dateFormat={"dd/MM/yyyy"}
                 onChange={(Date) => {
                  setDepositDate(Date);
                 }}
                 placeholderText={"dd/mm/yyyy"}
                 maxDate={new Date()}
                 showFullMonthYearPicker={true}
                /> 
              </p>
            </div>
            {errors.depositDate ? <span class="error text-danger">{errors.depositDate}</span> : null}
          </div>

          <div
            className="input-Grupse"
            onClick={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            {" "}
            <a
              className="primary-Gbtn full-Width gray-Btn"
              style={{ backgroundColor: disable ? "#65c82d" : "#b2b6b2" }}
            >
              {verificationObj.buttonLabel}
            </a>{" "}
            <div className="input-Grupse color-green text-center thx-message">
              {isSucess ? <p>Kiitos asiakirjojen lähettämisestä</p> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    walletData: state.wallet.walletData,
    verificationDynamicContent:
      state.common.sideBarMenuContent && state.common.sideBarMenuContent[SIDEBAR_CONTENT_TYPES.VERIFICATION] || {},
    isCashbackCasino: props.isCashbackCasino
  };
};
const mapDispatchToProps = (dispatch)=>{
  return {fetchCasinoDetailsDropdown :(payload, callback) => dispatch(getCompensationByReviewDetails(payload, callback)) 
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(VerificationFirstStep);
