import React, { useState } from "react";
import { useHistory } from "react-router";
import { ROUTE_CONSTANTS, TEXT_CONST } from "../../../shared";
import { AppTypeModal, LoginModal, SignUpModal } from "../../atoms";

const Authentication = ({
  headerContent,
  showLogin = false,
  showRegister = false
}) => {
  const history = useHistory();
  const [showLoginModal, toggleLoginModal] = useState(showLogin);
  const [showSignupModal, toggleSignupModal] = useState(showRegister);
  const [showAppTypeModal, toggleAppTypeModal] = useState("");

  return (
    <div>
      {!!showLoginModal && (
        <LoginModal
          onClose={() => history.goBack() || history.push(ROUTE_CONSTANTS.ROOT)}
          onOpenSignup={() => {
            history.push(ROUTE_CONSTANTS.REGISTER);
          }}
          show={showLoginModal}
        />
      )}
      {!!showAppTypeModal && (
        <AppTypeModal data={showAppTypeModal} onClose={() => toggleAppTypeModal("")} show={!!showAppTypeModal} />
      )}
      {!!showSignupModal && (
        <SignUpModal
          onClose={() => history.goBack() || history.push(ROUTE_CONSTANTS.ROOT)}
          onOpenLogin={() => {
            history.push(ROUTE_CONSTANTS.LOGIN)
          }}
          show={showSignupModal}
        />
      )}
      <div className="play-Btnse">
        <a onClick={() => {
          history.push(ROUTE_CONSTANTS.LOGIN);
        }} className="primary-Gbtn small-Btn dark-Green pop-Clcik">
          {headerContent.signInButtonLabel || TEXT_CONST.LOGIN}
        </a>
        <a onClick={() => {
          history.push(ROUTE_CONSTANTS.REGISTER);
        }} className="primary-Gbtn small-Btn dark-Green pop-Clcik">
          {headerContent.registerButtonLabel || TEXT_CONST.REGISTER}
        </a>
      </div>

    </div>
  );
};

export default Authentication
