import { takeLatest, all, put } from "redux-saga/effects";
import { getRequest } from "../../helpers";
import { API, STATUS_CODE } from "../../shared";
import { GET_HEADER_CONTENT, setHeaderDynamicContent } from "../actions";
const LOCATION_ID = process.env.REACT_APP_LOCATION_ID;


function* getHeaderDynamicContent({ payload: { payload, success = () => { } } = {} }) {
    try {

        const { data } = yield getRequest({
            API: `${API.GET_HEADER_CONTENT}?locationId=${LOCATION_ID}`,
        });
        if (data.statusCode == STATUS_CODE.successful) {
            yield put(setHeaderDynamicContent(data.data));
            success(true)
        } else {
            throw new Error(data.msg);
        }
    } catch (error) {
        yield put(setHeaderDynamicContent({}));
    }
}


function* DynamicContentSaga() {
    yield all([takeLatest(GET_HEADER_CONTENT, getHeaderDynamicContent)]);

}

export default DynamicContentSaga;
