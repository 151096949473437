import React from "react";
import { Modal } from "react-bootstrap";
import AuthService from "../../../shared/services/authServices";
import "./styles.css";

let data = [
  {
    label: "BankID på denna enhet",
    val: "urn:grn:authn:se:bankid:same-device",
  },
  {
    label: "BankID på annan enhet",
    val: "urn:grn:authn:se:bankid:another-device",
  },
];

const AppTypeModal = ({ onClose = () => {}, show, data: { ssn = "", token = "", type = "" } = {} }) => {
  const onPress = (acr_values) => {
    new AuthService({
      acr_values,
      params: {
        login_hint: `sub:${ssn}`,
      },
      redirectQuery: `?token=${token}&type=${type}`,
    }).signinRedirect();
  };
  return (
    <Modal onHide={onClose} show={show} size="lg">
      <div class=" md-2ndID">
        <div className="close-Modse">
          <a onClick={onClose} >
            +
          </a>
        </div>
        {data.map((item, index) => (
          <div key={index} className="lg-Btnse">
            <a onClick={() => onPress(item.val)} className="primary-Gbtn full-Width dark-Green ">
              {item.label}
            </a>
          </div>
        ))}
      </div>
    </Modal>
  );
};
export default AppTypeModal;
