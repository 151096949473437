import { takeLatest, all, put, take } from "redux-saga/effects";
import { getRequest, postRequest } from "../../helpers";
import { API, STATUS_CODE, CATEGORY_TYPE } from "../../shared";
import {
  GET_CASINO_CATEGORIES,
  GET_CASINO_REVIEWS_BY_CATEGORY_ID,
  GET_REVIEW_DATA_BY_REVIEW_ID,
  GET_REVIEW_DATA_BY_PERMALINK,
  GET_AFFILATE_LINK_BY_AFFILATE_KEY,
  startLoading,
  stopLoading,
  GET_CASHBACK_CASINOS_OF_USER,
  GET_COMPENSATION_BY_REVIEW_DETAILS,
} from "../actions";

const LOCATION_ID = process.env.REACT_APP_LOCATION_ID;

function* getCasinoCategories({
  payload: { payload, validateUser = false, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());

    const { data } = yield getRequest({
      API: `${API.GET_CASINO_CATEGORIES_FOR_REVIEWS}?locationId=${LOCATION_ID}&type=${CATEGORY_TYPE.REVIEWS}`,
      DATA: { ...payload },
    });

    if (data.statusCode == STATUS_CODE.successful) {
      success(data.data);
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* getReviewsByCategoryId({
  payload: { payload, validateUser = false, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());

    let API_URL = `${API.GET_REVIEW_LIST_BY_CATEGORY_ID}?locationId=${LOCATION_ID}&categoryId=${payload.categoryId}`;
    if (payload.filterType) {
      API_URL += `&filterType=${payload.filterType}`;
    }
    const { data } = yield getRequest({
      API: API_URL,
      DATA: { ...payload },
    });

    if (data?.statusCode == STATUS_CODE.successful) {
      success(data.data);
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* getCashbackCasinosOfUser({
  payload: { payload, validateUser = false, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());

    let API_URL = `${API.GET_CASHBACK_CASINOS_OF_USER}?locationId=${LOCATION_ID}`;
    const { data } = yield getRequest({
      API: API_URL
    });

    if (data.statusCode == STATUS_CODE.successful) {
      success(data.data);
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* getReviewByReviewId({
  payload: { payload, validateUser = false, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const { data } = yield getRequest({
      API: `${API.GET_REVIEW_BY_REVIEWID}?locationId=${LOCATION_ID}&reviewId=${payload.reviewId}`,
      DATA: { ...payload },
    });

    if (data.statusCode == STATUS_CODE.successful) {
      success(data.data);
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* getReviewByPermalink({
  payload: {
    payload,
    validateUser = false,
    success = () => { },
    fail = () => { },
  } = {},
}) {
  try {
    yield put(startLoading());
    const { data } = yield getRequest({
      API: `${API.GET_REVIEW_BY_PERMALINK}?locationId=${LOCATION_ID}&permalink=${payload.permalink}`,
      DATA: { ...payload },
    });

    if (data.statusCode == STATUS_CODE.successful) {
      success(data.data);
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* getAffliateLinkbyAffliateKey({
  payload: {
    payload,
    validateUser = false,
    success = () => { },
    fail = () => { },
  } = {},
}) {
  try {
    yield put(startLoading());
    const { data } = yield getRequest({
      API: `${API.GET_AFFILATE_LINK_BY_AFFILATE_KEY}?locationId=${LOCATION_ID}&affiliateKey=${payload.affiliateKey}`,
      DATA: { ...payload },
    });

    if (data.statusCode == STATUS_CODE.successful) {
      success(data.data);
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* getCompensationByReviewDetails({payload,success=()=>{}}
 ){
  try {
 
    yield put(startLoading());
    const { data } = yield postRequest({
      API: `${API.GET_COMPENSATION_BY_REVIEW}`,
      DATA: { ...payload },
    });

    if (data.statusCode == STATUS_CODE.successful) {
      success(data.data);
    } else {
      // fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}


function* ReviewsSaga() {
  yield all([
    takeLatest(GET_CASINO_CATEGORIES, getCasinoCategories),
    takeLatest(GET_CASINO_REVIEWS_BY_CATEGORY_ID, getReviewsByCategoryId),
    takeLatest(GET_CASHBACK_CASINOS_OF_USER, getCashbackCasinosOfUser),
    takeLatest(GET_REVIEW_DATA_BY_REVIEW_ID, getReviewByReviewId),
    takeLatest(GET_REVIEW_DATA_BY_PERMALINK, getReviewByPermalink),
    takeLatest(GET_AFFILATE_LINK_BY_AFFILATE_KEY, getAffliateLinkbyAffliateKey),
    takeLatest(GET_COMPENSATION_BY_REVIEW_DETAILS, getCompensationByReviewDetails),
  ]);
}

export default ReviewsSaga;
