export const UPLOAD_DOCUMENTS_FOR_VERIFICATION = "UPLOAD_DOCUMENTS_FOR_VERIFICATION";
export const UPLOAD_DOCUMENTS = "UPLOAD_DOCUMENTS";
export const GET_CASIONS_LIST_FOR_VERIFICATION = "GET_CASIONS_LIST_FOR_VERIFICATION";

export const sendDocumentsToVerify = (payload) => {
  return {
    type: UPLOAD_DOCUMENTS_FOR_VERIFICATION,
    payload,
  };
};

export const uploadDocument = (payload) => {
  return {
    type: UPLOAD_DOCUMENTS,
    payload,
  };
};

export const getCasinosListForVerification = (payload) => {
  return {
    type: GET_CASIONS_LIST_FOR_VERIFICATION,
    payload,
  };
};
