export const GET_ALL_PAGES = "GET_ALL_PAGES";
export const GET_PAGE_DATA_BY_PAGE_ID = "GET_PAGE_DATA_BY_PAGE_ID";

export const getAllPages = (payload) => {
  return {
    type: GET_ALL_PAGES,
    payload,
  };
};

export const getPageByPageId = (payload) => {
  return {
    type: GET_PAGE_DATA_BY_PAGE_ID,
    payload,
  };
};
