import React from "react";
import MuiPhoneNumber from "material-ui-phone-number";

import "./style.css"
import { defaultConfig } from "../../../config/default";

const MaterialPhoneInput = ({
  input,
  meta: { touched, error },
  widthStyle,
  placeholder,
  onChange = () => { },
  ...custom
}) => {
  widthStyle = widthStyle ? widthStyle : "";

  return (
    <React.Fragment>
      <MuiPhoneNumber
        id="outlined-basic"
        variant="outlined"
        name="phone"
        label={placeholder || "Phone Number"}
        data-cy="user-phone"
        required
        {...input}
        {...custom}
        defaultCountry={(defaultConfig.LOCATION || "fi").toLowerCase()}
        onlyCountries={[(defaultConfig.LOCATION || "fi").toLowerCase()]}
        enableLongNumbers={true}
        countryCodeEditable={true}
        onChange={(value, country) => {
          input.onChange({ ...country, value });
          onChange({ ...country, value });
        }}
      />
    </React.Fragment>
  );
};
export default MaterialPhoneInput;
