export const TEXT_CONST = {
  // Home Screen Nav bar text
  WELCOME: "Välkommen ",
  MONEY: (money) => `${money} KR`,

  //SITE_INFO
  YOU_HAVE_MONEY: (money) => `Sinulla on: ${TEXT_CONST.MONEY(money)}`,
  USER_NAME: (name) => `${name}`,
  WITHDRAW_MONEY: "Nosta rahasi tänne",
  GET_BACK_UPTO: (sekAmount) => `ALOITA TAKAISIN €${sekAmount}`,
  ON_YOUR_FIRST_DEPOSIT: "ENSIMMÄISELLE TALLETUKSELLE",
  USER_WELCOME_MSG: "Tervetuloa Riskivapaa.com,",
  SITE_INFO:
    "Voit nyt verrata ja lukea arvosteluja kaikista uusista ja parhaista online-kasinoista, joilla on Ruotsin kasinolisenssi",
  OFFERS_INFO: (sekAmount) =>
    `Kasinolla pelaaminen ja voittaminen on yleensä puhdasta onnea, eikä kaikilla ole onni voittaa. Siksi tarjoamme kaikille jäsenillemme, jotka tekevät ensimmäisen talletuksen kasinolla, saada takaisin ${sekAmount} kruunua meiltä, ​​mikä antaa sinulle mahdollisuuden kokeilla useita erilaisia ​​kasinoita ja silti saada rahasi takaisin, jos epäonnea sattuu.`,
  SEE_OUR_THIS_MUCH_REVIEWS: (reviewCount) => `Katso ${reviewCount} arvostelut`,
  VERIFICATION: "TODENTAMINEN",
  VERIFY_N_MONEY_BACK: "Vahvista ja palauta rahasi",
  WITHDRAWAL_SUCCESS: "Saat rahaa pian!",
  WITHDRAWAL_ERROR: "Jotain meni pieleen!",
  APPROVE_BUTTON: "Hyväksyä",
  APPROVE_SPAN: "Hyväksy nostosi saadaksesi rahaa",
  APRROVE_TITLE: "Peruuttamislupa",
  AMOUNT_REQUIRED: "Anna kelvollinen summa.",
  AMOUNT_INAPPROPRIATE: "Syötä sopiva summa.",
  AMOUNT_LIMIT: "Voit nostaa enintään 2 kt yhdellä tapahtumalla",
  AMOUNT_MIN_LIMIT: "Voit nostaa vähintään 20 yhtä tapahtumaa",
  AMOUNT_PERCISION: "Anna kelvollinen summa kahden desimaalin tarkkuudella.",
  // Buttons labels
  REGISTER: "Rekisteröidy",
  LOGIN: "Kirjaudu sisään",

  //Register field headings

  ENTER_EMAIL: "Enkeli sähköpostista",
  ENTER_NAME: "Kirjoita nimesi",
  ENTER_SSN: "lähetä sosiaaliturvatunnus",

  //Header Headings
  MY_PROFILE: "Profiilini",
  VERIFICATION: "Todentaminen",
  CASHBACK: "Vaihtorahat",
  HISTORY: "HISTORIA",
  LOGOUT: "Kirjautua ulos",
  UTTAG: "Pistoke",
  KYC: "KYC",

  //HOW DOES IT WORK
  HOW_DOES_IT_WORK: "Kuinka se toimii?",
  HOW_IT_WORK_CARDS: [
    {
      id: 1,
      firstHalf: "Registrera dig",
      secondHalf: "med BankID",
      DETAILS:
        "Det är enkelt och gratis att skapa ett konto hos oss, klicka på Registrera dig-knappen, fyll i namn och epost så verifierar du dig själv med BankID efter du har gjort det så är du en registrerad medlem hos oss!",
    },
    {
      id: 2,
      firstHalf: "Välj ett casino",
      secondHalf: "att spela på",
      DETAILS:
        "I topplistan nedan har vi tagit fram både nya och de bästa casinon som innehar en svensk licens. Läs och välj ett casino som du inte har spelat på tidigare, klicka på gröna knappen och gör din första insättning på casinot.",
    },
    {
      id: 3,
      firstHalf: "Hade du otur att",
      secondHalf: "förlora pengarna? ",
      DETAILS:
        "Ingen fara, vi är här för att hjälpa! Gå till menyn Verifiering. Där kan du skicka in en bild på att du har gjort en insättning på casinot och förlorat det. Vi granskar det och betalar sedan tillbaka summan som du förlorade!",
    },
    {
      id: 4,
      firstHalf: "Automatiska",
      secondHalf: "utbetalningar",
      DETAILS:
        "När granskningen har godkänds så uppdateras din kontobalans automatiskt. Där kan du sedan välja att göra ett uttag. Verifiera dig med BankID och pengarna är tillbaka på ditt personliga bankkonto på några minuter.",
    },
  ],

  //CASSIONS
  LOAD_MORE_CASSINOS: "LISÄÄ LISÄÄ KASINONIA",
  NO_CASINO_TO_SHOW: "Ei näytettävää arvostelua!",

  //START PLAYING
  COMPARE_SELECT_N_START_PLAYING: "Vertaa, valitse ja aloita pelaaminen!",
  HOW_TO_START_N_PLAY_INFO:
    "Valitse sinulle parhaiten sopiva kasino, tarkista, mitä heillä on tarjottavanaan, lue arvostelut ja katso kuinka paljon rahaa voit saada takaisin ensimmäisestä talletuksestasi, jos sinun on epäonnea menettää ne. On tärkeää, että olet rekisteröinyt tilin BankID: ään meihin osoitteessa Riskivapaa.com ennen kuin aloitat pelaamisen, muuten et voi saada meiltä rahaa takaisin, jos menetät ensimmäisellä talletuksellasi tekemäsi rahat. Vihreällä painikkeella näet, kuinka paljon voit saada takaisin ensimmäisestä talletuksestasi, ja tämä tarjous koskee vain ensimmäistä talletustasi ja jos olet uusi asiakas kasinolla, jossa päätät pelata.",

  //LATEST NEWS
  LATEST_NEWS: "Viimeisimmät uutiset ruotsalaisista kasinoista",
  READ_NEWS:
    "Lue viimeisimmät uutiset ruotsalaisista kasinoista ja niiden tarjouksista sekä viimeisimmät uutiset Riskivapaa.com-sivustosta.",
  LOAD_MORE_NEWS: "LATAA LISÄÄ UUTISIA",
  NO_NEWS_TO_SHOW: "Ei näytettäviä uutisia!",
  //LATEST MEMBERS
  LATEST_MEMBERS: "Viimeisimmät jäsenet, jotka ovat vastaanottaneet",
  WHO_RECEIVED_MONEY: "rahat takaisin ja maksettu Riskivapaa.com-sivustolta",
  WHY_LATEST_MEMBERS:
    "Me Riskfivapaa.com-sivustolla olemme hyvin avoimia ja olemme iloisia voidessamme näyttää kaikille uusille jäsenille, joille heidän hyväksyntänsä on hyväksytty. Näyttää myös jäsenet, jotka ovat pyytäneet maksua ja saaneet sen muutamassa minuutissa hänen henkilökohtaiselle pankkitililleen. Turvallisuussyistä voimme näyttää vain kyseisen henkilön etunimen.",
  LAST_WHO_GOT_MONEY_BACK: "Viimeinen, joka sai rahat takaisin",
  LAST_WHO_GOT_THEIR_MONEY_PAID_OUT: "Viimeinen, joka sai rahansa maksetuksi",

  // Questions and Answers

  QUESTION_AND_ANSWER: "Kysymykset ja vastaukset",
  HEADING:
    "Alla on lueteltu yleisimmät kysymykset, joita ihmiset ovat esittäneet, ja toivomme, että löydät myös etsimäsi vastauksen.",
  SUB_HEADING:
    "Jos ei, voit aina ottaa yhteyttä live-tukeen napsauttamalla oikealla olevaa pyöreää kuvaketta. Autamme sinua ja autamme sinua!",

  //FOOTER
  WIN_OR_MONEY_BACK: "VOITTAA TAI SAA RAHA TAKAISIN",
  LOGO_TEXT: "Riskivapaa.com",
  FOOTER_HEADINGS: [
    {
      title: "MEISTÄ",
      link: "/about-us"
    },
    {
      title: "KUINKA SE TOIMII",
      link: "/how-it-works"
    },
    {
      title: "LIVE-TUKI",
    },
    {
      title: "OTA MEIHIN YHTEYTTÄ",
      link: "/about-us"
    },
  ],
  FOOTER_SUB_HEADINGS: [
    {
      title: "KÄYTTÖEHDOT",
      link: "/terms-of-use"
    },
    {
      title: "TIETOSUOJAKÄYTÄNTÖ",
      link: "/privacy-policy"
    },
    {
      title: "KEKSIT",
      link: "/cookies"
    },
  ],
  PLAY_TOO_CONTACT: "Pelaatko liian paljon? Ottaa yhteyttä",
  GAME_INSPECTOR: "Pelin tarkastajat",

  // My Profile modal labels and input strings
  MY_PROFILE: "Min Profiili",
  FIRST_NAME_LABEL: "Etunimi",
  LAST_NAME_LABEL: "Sukunimi",
  MOBILE_NUMBER_LABEL: "Matkapuhelinnumero",
  EMAIL_ADDRESS_LABEL: "Sähköpostiosoite",
  YOUR_ADDRESS_LABEL: "Osoitteesi",
  SSN_LABEL: "SSN",

  // String Contents
  TITLE: "Riskivapaa.com",
  META_DESCRIPTION: "Riskivapaa.com",

  SOMETHING_WENT_WRONG: "Jotain meni pieleen",

  SSN_INVALID: "SSN on virheellinen.",

  HEADER: {
    REFUNDED_AMOUNT: "Valitse kasino ja katso kuinka paljon voit saada takaisin",
    DOC_SUBMITTED: "Asiakirjat on lähetetty vahvistettavaksi",
    DOC_SUBMITTED_FOR_UPDATION: "Asiakirjat on lähetetty päivitettäväksi",
  },
  LOGIN_OR_SIGNUP: {
    PHONE_NUMBER_REQUIRED: "Puhelinnumero vaaditaan.",
    PHONE_NUMBER_INVALID: "Puhelinnumero on virheellinen.",
    OTP_REQUIRED: "OTP vaaditaan.",
    OTP_INVALID: "OTP on virheellinen.",
  },
  SIGNUP: {
    NAME_REQUIRED: "Nimi vaaditaan.",
    NAME_INVALID: "Nimi on virheellinen.",
    DOB: "Ikäsi on oltava yli 18-vuotias.",
    ACCEPT_TERMS: "Hyväksy käyttöehdot.",
    EMAIL_REQUIRED: "Sähköposti vaaditaan.",
    EMAIL_INVALID: "Sähköpostiosoite on virheellinen.",
    GENDER_REQUIRED: "Sukupuoli vaaditaan."
  },
  UPDATE_PROFILE: {
    UPDATED_SUCCESSFULLY: "Profiilin päivitys onnistui",
    NAME_REQUIRED: "Nimi vaaditaan.",
    NAME_INVALID: "Nimi on virheellinen.",
    DOB: "Ikäsi on oltava yli 18-vuotias.",
    ACCEPT_TERMS: "Hyväksy käyttöehdot.",
    EMAIL_REQUIRED: "Sähköposti vaaditaan.",
    EMAIL_INVALID: "Sähköpostiosoite on virheellinen.",
    GENDER_REQUIRED: "Sukupuoli vaaditaan."
  },
  /********validations*********/
  // Profile validations
  EMAIL_VALIDATION: "Virheellinen sähköpostiosoite",
  PHONE_NUMBER_VALIDATION: "Väärä puhelinnumero",
  PHONE_NUMBER_REQUIRED: "Puhelinnumero vaaditaan",
  ADDRESS_VALIDATION: "Pakollinen osoite",

  //Currency Strings 
  SWEDISH_KORONA: "SEK",

  // Notification Messages
  NOTIFICATION_CLEAR_SUCCESS: "Viestin poisto onnistui",
  NOTIFICATION_CLEAR_ERROR: "Ilmoituksia ei voi tyhjentää",
  NOTIFICATION_INBOX_EMPTY: "Saapuneet-kansio on tyhjä.",

  // Dropdown Messages
  NO_OPTION: "Ei vaihtoehtoa"
};
