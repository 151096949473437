import { all, put, select, takeEvery } from "redux-saga/effects";
import { getRequest, postRequest } from "../../helpers";
import { API, STATUS_CODE, getAuthToken, getWithdrawalData } from "../../shared";

import {
  GET_USER_WALLET,
  WITHDRAWAL_REQUEST,
  WITHDRAWAL_APRROVE_REQUEST,
  startLoading,
  stopLoading,
  setUserWalletData,
  setWithdrawalData,
  setWithdrawalPendingStatus
} from "../actions";

const LOCATION_ID = process.env.REACT_APP_LOCATION_ID;

function* getWallet({ callback }) {
  try {
    yield put(startLoading());
    const auth = yield select(getAuthToken);
    let params = `?locationId=${LOCATION_ID}`;
    const response = yield getRequest({
      API: `${API.GET_USER_WALLET}${params}`,
      headers: {
        authorization: auth,
      },
    });

    if (response.status !== STATUS_CODE.successful) callback({});
    else {
      yield put(setUserWalletData(response.data.data));
      callback(response.data.data);
    }
  } catch (error) {
    callback({});
  } finally {
    yield put(stopLoading());
  }
}

function* withdrawalRequest({ data, callback }) {
  try {
    yield put(startLoading());
    const auth = yield select(getAuthToken);
    let params = `?locationId=${LOCATION_ID}`;
    const response = yield postRequest({
      API: `${API.WITHDRAWAL_REQUEST}${params}`,
      DATA: data,
      headers: {
        authorization: auth,
      },
    });
    if (response.status !== STATUS_CODE.successful) callback({});
    else {
      let {state, user_form_url, id, amount} = response.data.data
      if(state == "pending"){
        yield put(setWithdrawalData({state, user_form_url, id, amount}))
        yield put(setWithdrawalPendingStatus(true))
      }
      else {
        yield put(setWithdrawalData({}))
        yield put(setWithdrawalPendingStatus(false))
      }
      callback({success:true, user_form_url});
    }
  } catch (error) {
    callback({});
  } finally {
    yield put(stopLoading());
  }
}

function* withdrawalApprovalRequest({ callback }) {
  try {
    yield put(startLoading());
    const auth = yield select(getAuthToken);
    const withdrawalData = yield select(getWithdrawalData);
    const response = yield postRequest({
      API: `${API.WITHDRAWAL_APPROVAL_REQUEST}?locationId=${LOCATION_ID}`,
      DATA: { withdrawalId: withdrawalData.id, amount: withdrawalData.amount },
      headers: {
        authorization: auth,
      },
    });
    if (response.status !== STATUS_CODE.successful) callback(false);
    else {
        yield put(setWithdrawalData({}))
        yield put(setWithdrawalPendingStatus(false))
        callback(true)
    }
  } catch (error) {
    callback(false);
  } finally {
    yield put(stopLoading());
  }
}

function* WalletSaga() {
  yield all([
    takeEvery(GET_USER_WALLET, getWallet),
    takeEvery(WITHDRAWAL_REQUEST, withdrawalRequest),
    takeEvery(WITHDRAWAL_APRROVE_REQUEST, withdrawalApprovalRequest)
  ]);
}

export default WalletSaga;
