import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getPrettyDate, VERIFICATION_STATUS } from "../../../shared";
import { useSnackbar } from "notistack";
import "./style.css";

const CustomTableView = ({
  columns = [],
  rows = [],
  actions = {},
  renderTableFooter = () => { },
  currentPage = 0,
  pageSize = 3,
  noDataFound = "",
  allSelected = false,
  onSelectAll = () => { },
  openToggleInMobile,
  toggleRowData,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <div className="table_custom">
        <table className="list-Wrmainse">
          <thead>
            <tr>
              {columns.map((c) => {
                /** START: To show checkbox in first column */
                if (c.htmlThCol)
                  return (
                    <th scope="col" key={c._id} className="checkbox-column">
                      <div className="custom-control custom-checkbox chkBox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck0"
                          disabled={!rows.length}
                          checked={allSelected && rows.length}
                          onChange={(e) => onSelectAll(e, !allSelected)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck0"
                        ></label>
                      </div>
                    </th>
                  );
                /** END: To show checkbox in first column */
                return (
                  <th key={c._id} scope={c.title} /*width={c.width} */>
                    <div className={c.class_name}>
                      <h6>{c.title}</h6>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {!rows.length /** No Data to show */ ? (
              <tr>
                <td colSpan="8" style={{ paddingLeft: "10px" }}>
                  <p className="no-media d-flex justify-content-center align-items-center">
                    {noDataFound}
                  </p>
                </td>
              </tr>
            ) : (
              rows
                .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                .map((r) => (
                  <tr
                    key={r._id}
                    className={toggleRowData[r._id] ? "is-expanded" : ""}
                  >
                    {columns.map((c, idx) => {
                      return (
                        <td data-col={c.title} key={idx}>
                          <div
                            className={`post-Title image-div-height ${c.isImage ? "image-dive" : ""}`}
                            key={idx}
                          >
                            {c.isImage /** START: To show single image */ ? (
                              <>
                                <img
                                  className="image-height"
                                  src={`${c.baseURL}${r[c.fieldName]}`}
                                  alt=""
                                />
                              </>
                            ) : (
                              /** END: To show single image */
                              <span>
                                {c.fieldName1 ? (
                                  <span>
                                    {" "}
                                    {/* {r['c.fieldName']}{" "} */}
                                    {getPrettyDate(r[c.fieldName])}
                                  </span>
                                ) : (
                                  // ) :
                                  // c.fieldName == "comment" ? (
                                  //   r[c.fieldName] ? (
                                  //     r[c.fieldName]
                                  //   ) : (
                                  //     <span>-</span>
                                  //   )
                                  // ) : (
                                  //   r[c.fieldName]
                                  // )}
                                  r[c.fieldName]
                                )}
                              </span>
                            )}
                          </div>
                          {idx ===
                            actions.actionIdx /** To show Edit actions on specific column */ && (
                              <div className={actions.class_name + " post-Title image-div-height"}>
                                <ul class="action_perform">
                                  {actions.data.map((d, idx) => (
                                    <li key={idx}>
                                      {r.status ===
                                        VERIFICATION_STATUS.NEED_MODIFICATIONS
                                          .title ? (
                                        // {r.status ? (
                                        <a
                                          // to={d.to && `${d.to}/${r._id}`}
                                          onClick={() =>
                                            d.action && d.action(r)
                                          }
                                        >
                                          {/* {d.name} */}
                                          <i
                                            class="fa fa-pencil-square-o"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      ) : (
                                        <span style={{ color: 'black' }}>-</span>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                        </td>
                      );
                    })}
                  </tr>
                ))
            )}
          </tbody>
        </table>
      </div>

      <div className="pagWrap w-100">
        {rows.length !== 0 && renderTableFooter()}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateAffiliateLink: (affiliateId, affiliateData, callback) =>
    //   dispatch(updateAffiliateLink(affiliateId, affiliateData, callback)),
  };
};

export default connect(null, mapDispatchToProps)(CustomTableView);
