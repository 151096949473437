export const GET_USER_WALLET = "GET_USER_WALLET"
export const SET_USER_WALLET_DATA = "SET_USER_WALLET_DATA"
export const SET_WITHDRAWAL_DATA = "SET_WITHDRAWAL_DATA"
export const SET_WITHDARWAL_PENDING_STATUS = "SET_WITHDARWAL_PENDING_STATUS"
export const SET_WITHDARWAL_APRROVE_SUCCESS = "SET_WITHDARWAL_APRROVE_SUCCESS"
export const RESET_WALLET = 'RESET_WALLET';
export const WITHDRAWAL_REQUEST = "WITHDRAWAL_REQUEST"
export const WITHDRAWAL_APRROVE_REQUEST = "WITHDRAWAL_APRROVE_REQUEST"


export const getUserWallet = (callback) => {
    return {
        type: GET_USER_WALLET,
        callback
    }
}

export const withdrawalRequest = (data, callback) => {
    return {
        type: WITHDRAWAL_REQUEST,
        data,
        callback
    }
}

export const withdrawalApproveRequest = (data, callback) => {
    return {
        type: WITHDRAWAL_APRROVE_REQUEST,
        data,
        callback
    }
}

export const setUserWalletData = (data) => {
    return {
        type: SET_USER_WALLET_DATA,
        data
    }
}

export const setWithdrawalApprovalSuccess = (data = false) => {
    return {
        type: SET_WITHDARWAL_APRROVE_SUCCESS,
        data
    }
}

export const setWithdrawalData = (data) => {
    return {
        type: SET_WITHDRAWAL_DATA,
        data
    }
}

export const setWithdrawalPendingStatus = (data) => {
    return {
        type: SET_WITHDARWAL_PENDING_STATUS,
        data
    }
}

export const withdrawalApprovalRequest = (callback) => {
    return {
        type: WITHDRAWAL_APRROVE_REQUEST,
        callback
    }
}

export const resetWalletData = () => {
    return {
        type: RESET_WALLET
    }
}