export const SERVER_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:3000"; //Live URL
const VERSION = "/v1";
const URL = SERVER_URL + VERSION;

export const API = {
  REGISTER: URL + `/user/verifyAndRegister`,
  VERIFY_USER: URL + `/user/generateOTP`,
  LOGIN: URL + `/user/login`,
  LOGOUT: URL + `/user/logout`,
  GET_PROFILE: URL + `/user/getProfile`,
  UPDATE_PROFIE: URL + `/user/updateProfile`,
  GET_LOCATION_BY_ID: URL + `/location/getById`,
  GET_FAQ_QUESTIONS: URL + `/faq/getAll`,
  GET_HOW_IT_WORKS_STEPS: URL + `/steps/get`,
  UPLOAD_DOCUMENT: URL + `/document/upload`,
  VERIFY_DOCUMENTS: URL + `/verifyDocuments/upload`,
  GET_CASINOS_LIST_FOR_VERIFICATIOM: URL + `/review/getReviewsForUserPanel`,
  GET_ALL_PAGES: URL + `/page/getPages`,
  GET_PAGE_DATA_BY_PAGE_ID: URL + `/page/getPage`,
  GET_CASINO_CATEGORIES_FOR_REVIEWS: URL + `/category/getCategory`,
  GET_REVIEW_LIST_BY_CATEGORY_ID: URL + `/review/getReviewsByCategoryId`,
  GET_COMPENSATION_BY_REVIEW : URL + "/review/compensation",
  GET_CASHBACK_CASINOS_OF_USER: URL + `/review/getCashbackCasinosOfUser`,
  GET_REVIEW_BY_REVIEWID: URL + `/review/getReviews`,
  GET_ALL_NEWS: URL + `/newsArticle/getNewsArticlesForUserPanel`,
  GET_NEWS_BY_PERMALINK: URL + `/newsArticle/getNewsArticleByPermalink`,
  GET_NEWS_LIST_BY_CATEGORY_ID:
    URL + `/newsArticle/getNewsArticlesByCategoryId`,
  GET_USER_WALLET: URL + `/wallet/get`,
  WITHDRAWAL_REQUEST: URL + `/wallet/withdrawal`,
  WITHDRAWAL_APPROVAL_REQUEST: URL + `/wallet/withdrawal/approve`,
  GET_REVIEW_BY_PERMALINK: URL + `/review/getReviewByPermalink`,
  GET_DOCUMENTS_VERIFICATION_HISTORY:
    URL + `/verifyDocuments/getDocumentsOfUser`,
  UPDATE_DOCUMENT: URL + `/verifyDocuments/updateDocumentOfUser`,
  GET_LAST_MONEY_BACK_LIST: URL + `/whoGotMoneyBack`,
  GET_LAST_MONEY_PAID_LIST: URL + `/automaticPayouts/getAllAutomaticPayouts`,
  GET_ALL_NOTIFICATIONS: URL + `/notification/getAllForUser`,
  PUT_ONBOARD_DATA: URL + `/popupFormContent/updateByUser`,
  CLEAR_ALL_NOTIFICATIONS: URL + `/notification/clearAll`,
  MARK_AS_READ_MESSAGE: URL + `/notification/read`,
  GET_ALL_FOOTER_LINKS: URL + `/footerLink/getAll`,
  GET_AFFILATE_LINK_BY_AFFILATE_KEY: URL + '/affiliateLink/getAffliateLinkbyAffliateKey',
  GET_HEADER_CONTENT: URL + "/blockContent/getAllForUserPanel",
  GET_POPUP_CONTENT_DATA: URL + "/popupFormContent/getAllForUserPanel",
  GET_SIDEBAR_MENU_LIST: URL + "/dropdownContent/getAll",
  GET_SIDEBAR_MENU_CONTENT_DATA: URL + "/dropdownInnerContent/getAllForUserPanel",
  GET_NOTIFICATION_SCREEN_CONTENT: URL + "/notificationContent/getAll"
};
