import React from "react";
import './style.scss';

const CardForSteps = ({ index, cardIcon, heading, description }) => (
  <div key={index} className="slideWrapse">
    <div className="play-Rmain">
      <span className="countSe">{`${index + 1}.`}</span>
      <div className="ply-Iconse">
        <img src={cardIcon} alt="..." />
      </div>
      <div className="ply-Txtsec">
        <h3>{heading}</h3>
        <p>{description}</p>
      </div>
    </div>
  </div>
);

export default CardForSteps;
