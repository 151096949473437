import { takeLatest, all, put } from "redux-saga/effects";
import { getRequest } from "../../helpers";
import { API, STATUS_CODE } from "../../shared";
import { closeOnboardingModal, GET_POPUP_CONTENT_DATA, onBoardModal, openOnboardingModal, setPopupContentData } from "../actions";

const LOCATION_ID = process.env.REACT_APP_LOCATION_ID;

function* getPopupContent({ payload: { payload, success = () => { } } = {} }) {
    try {
        const { data } = yield getRequest({
            API: `${API.GET_POPUP_CONTENT_DATA}?locationId=${LOCATION_ID}`,
        });
        if (data.statusCode == STATUS_CODE.successful) {
            yield put(setPopupContentData(data.data));
            success(true)
        } else {
            throw new Error(data.msg);
        }
    } catch (error) {
        yield put(setPopupContentData({}));
    }
}


function* PopupContentSaga() {
    yield all([takeLatest(GET_POPUP_CONTENT_DATA, getPopupContent)]);

}

export default PopupContentSaga;
