import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Footer,
  Header,
  NewsList,
} from "../../../../../components";
import { SERVER_URL } from "../../../../../shared";
import "./styles.scss";

function innerHtml(content) {
  return { __html: content }
}

const NewsItem = ({ location, getNewsbyPermalink }) => {
  const [newsData, setNewsData] = useState({});

  useEffect(() => {
    // window.scrollTo(0, 0);
    const permalink = location.pathname.split("/").pop();
    getNewsbyPermalink(permalink, (data) => {
      setNewsData(data);
    });
  }, [getNewsbyPermalink]);

  return (
    <>
      {newsData.title ?
        <Helmet>
          <title>{newsData.title}</title>
        </Helmet>
        : null}
      <div className="main-content">
        <div className="sub_header">
          <Header />
        </div>
        <div className="mid-Mainsec">
          <div className="container">
            <div className="row news_details">
              <div className="col-md-12 text-left pt-4">
                {/* <figure class="featured_img">
                  {newsData.featuredImage && (
                    <img src={SERVER_URL + newsData.featuredImage} alt="" class="img-fluid"/>
                  )}
                </figure> */}
                <h2 class="page_title">{newsData.title || ''}</h2>
                <div class="News_detail_block" dangerouslySetInnerHTML={innerHtml(newsData.content || '')}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NewsItem;
