import { takeLatest, all, put, select } from "redux-saga/effects";
import { getRequest, postRequest, putRequest } from "../../helpers";
import { API, getUserData, STATUS_CODE } from "../../shared";
import { PUT_ONBOARDING_MODAL, onBoardModal, closeOnboardingModal } from "../actions/onboardingModal";

function* updateOnboardingModal({ id, body }) {
  try {
    let apiURL = `${API.UPDATE_PROFIE}`;
    if (id)
      apiURL = `${apiURL}`;
    const { data } = yield postRequest({
      API: apiURL,
      DATA: body
    });
    if (data.statusCode == STATUS_CODE.successful) {
      yield put(onBoardModal(data.data.dontShowAgain));
      yield put(closeOnboardingModal())
    } else {
      throw new Error(data.msg);
    }
  } catch (error) {
  }
}

function* onBoardingSaga() {
  yield all([takeLatest(PUT_ONBOARDING_MODAL, updateOnboardingModal)]);
}

export default onBoardingSaga;