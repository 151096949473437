import Screen from "./screen";
import { connect } from "react-redux";
import {
  getReviewDataByReviewId,
  getReviewDataByPermalink,
} from "../../../../redux/actions";
import { HEADER_CONTENT_TYPES } from "../../../../shared";
const mapStateToProps = (state) => {
  return {
    isReviewPage: true,
    userToken: state.common.userToken,
    dynamicContent: state.common.dynamicContent[HEADER_CONTENT_TYPES.REVIEW_DETAILS_PAGE] || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReviewDataByReviewId: (payload) =>
      dispatch(getReviewDataByReviewId(payload)),
    getReviewDataByPermalink: (payload) =>
      dispatch(getReviewDataByPermalink(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
