import { NavLink } from "react-router-dom";

const ListHeader = ({
  handleSelectChange,
  calenderMonths = [],
  casinos = [],
  applyFilter,
  selectedMonth = "",
  selectedCasino = "",
  casinoFilterLabel,
  dateFilterLabel,
  filterLabel
}) => (
  <>
    <div class="col-md-12 filter-options">
      <div class="row align-items-center justify-content-between">
        <select
          class="form-control"
          name="Select Casino"
          id="Select-Casino"
          value={selectedCasino}
          onChange={(e) => handleSelectChange(e, "casino")}
        >
          {casinos.map((casino, idx) => (
            <option key={idx} value={casino._id || ""}>{`${casino === (casinoFilterLabel || "All Casinos") ? casino : casino.casinoName
              }`}</option>
          ))}
        </select>
        <select class="form-control" name="Select Date" id="Select-Date">
          {calenderMonths.map((month, idx) => (
            <option key={idx}>{`${month === (dateFilterLabel || "All Dates") ? month : month.monthName + " " + month.year
              }`}</option>
          ))}
        </select>
        <a onClick={applyFilter} className="primary-Gbtn">
          {filterLabel || "Apply"}
        </a>
      </div>
    </div>
  </>
);

export default ListHeader;
