export const GET_CASINO_CATEGORIES = "GET_CASINO_CATEGORIES";
export const GET_CASINO_REVIEWS_BY_CATEGORY_ID = "GET_CASINO_REVIEWS_BY_CATEGORY_ID";
export const GET_CASHBACK_CASINOS_OF_USER = "GET_CASHBACK_CASINOS_OF_USER";
export const GET_REVIEW_DATA_BY_REVIEW_ID = "GET_REVIEW_DATA_BY_REVIEW_ID";
export const GET_REVIEW_DATA_BY_PERMALINK = "GET_REVIEW_DATA_BY_PERMALINK";
export const GET_AFFILATE_LINK_BY_AFFILATE_KEY = "GET_AFFILATE_LINK_BY_AFFILATE_KEY";
export const GET_COMPENSATION_BY_REVIEW_DETAILS = "GET_COMPENSATION_BY_REVIEW_DETAILS";

export const getCasinoCategories = (payload) => {
  return {
    type: GET_CASINO_CATEGORIES,
    payload,
  };
};

export const getCasinoReviewsByCategoryId = (payload) => {
  return {
    type: GET_CASINO_REVIEWS_BY_CATEGORY_ID,
    payload,
  };
};

export const getCashbackCasinosOfUser = (payload) => {
  return {
    type: GET_CASHBACK_CASINOS_OF_USER,
    payload,
  };
};

export const getReviewDataByReviewId = (payload) => {
  return {
    type: GET_REVIEW_DATA_BY_REVIEW_ID,
    payload,
  };
};

export const getReviewDataByPermalink = (payload) => {
  return {
    type: GET_REVIEW_DATA_BY_PERMALINK,
    payload,
  };
};

export const getAffliateLinkbyAffliateKey = (payload) => {
  return {
    type: GET_AFFILATE_LINK_BY_AFFILATE_KEY,
    payload,
  };
};

export const getCompensationByReviewDetails = (payload,success) => {
  return {
    type: GET_COMPENSATION_BY_REVIEW_DETAILS,
    payload,
    success
  };
};