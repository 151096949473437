export const GET_POPUP_CONTENT_DATA = "GET_POPUP_CONTENT_DATA"
export const SET_POPUP_CONTENT_DATA = "SET_POPUP_CONTENT_DATA"


export const getPopupContent = (popupContentType, callback) => {
    return {
        type: GET_POPUP_CONTENT_DATA,
        popupContentType,
        callback
    };
};


export const setPopupContentData = (data) => {
    return {
        type: SET_POPUP_CONTENT_DATA,
        data
    }
}




