export const GET_VERIFICATION_DOCUMENTS_HISTORY = "GET_VERIFICATION_DOCUMENTS_HISTORY";
export const EDIT_VERIFY_DOCUMENT =
  "EDIT_VERIFY_DOCUMENT";
export const UPDATE_VERIFY_DOCUMENT_DETAILS = "UPDATE_VERIFY_DOCUMENT_DETAILS";
export const UPDATE_HISTORY_LIST = "UPDATE_HISTORY_LIST";
export const GET_LAST_MONEY_BACK_LIST = "GET_MONEY_BACK_LIST";
export const GET_LAST_MONEY_PAID_LIST = "GET_MONEY_PAID_LIST";
export const SET_LAST_MONEY_PAID_LIST = "SET_MONEY_PAID_LIST";
export const SET_WITHDRAWAL_AMOUNT = "SET_WITHDRAWAL_AMOUNT";

export const getDocumentsHistory = (payload) => {
  return {
    type: GET_VERIFICATION_DOCUMENTS_HISTORY,
    payload,
  };
};

export const editVerifyDocument = (payload) => {
  return {
    type: EDIT_VERIFY_DOCUMENT,
    payload,
  };
};

export const updateVerifyDocumentDetails = (payload) => {
  return {
    type: UPDATE_VERIFY_DOCUMENT_DETAILS,
    payload,
  };
};

export const updateHistoryList = (payload) => {
  return {
    type: UPDATE_HISTORY_LIST,
    payload,
  };
};

export const getLastMoneyBackList = (payload) => {
  return {
    type: GET_LAST_MONEY_BACK_LIST,
    payload,
  };
};

export const getLastMoneyPaidList = (payload) => {
  return {
    type: GET_LAST_MONEY_PAID_LIST,
    payload,
  };
};

export const setWithdrawalAmount = (amount) => {
  return {
    type: SET_WITHDRAWAL_AMOUNT,
    amount,
  };
}

export const setLastMoneyPaidList = (payload) => {
  return {
    type: SET_LAST_MONEY_PAID_LIST,
    payload,
  };
};