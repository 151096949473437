import { Accordion } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";


import "./styles.scss";
import { CassinoMoreDetails } from "../../atoms";
import defaultImg from "../../../assets/images/no-image.png";
import { ROUTES, ROUTE_CONSTANTS } from "../../../shared";
import { defaultConfig } from "../../../config/default";

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const CasinoAccordionItem = ({
  item,
  isCollapse = true,
  active,
  index,
  onClick = () => { },
  isReviewPage,
  userToken,
  headerContent = {}
}) => {
  let history = useHistory();

  const [paymentCollapsed, setPaymentCollapsed] = useState(true);
  const [bgColors, setBgColors] = useState({ bg: "", shadow: "" });

  let array = [];
  let data =
    item &&
    item.affiliatedFields &&
    item.affiliatedFields.reviewRatings &&
    item.affiliatedFields.reviewRatings.affiliatedRating;

  let { ribbonBackgroundColor, ribbonTextColor, ribbonBorderColor, logoBackgroundColor, reviewRatingBackgroundColor } =
    (item && item.affiliatedFields && item.affiliatedFields.colorSettings) || "";
  let { ribbionText } = (item && item.affiliatedFields && item.affiliatedFields.textContent) || "";
  let { ratingsValue } = (item && item.affiliatedFields && item.affiliatedFields.reviewRatings) || 0;
  let { listOfInformations, Above18AgeAndApply, } =
    (item && item.affiliatedFields && item.affiliatedFields.reviewMainFields) || "";
  let { bonusPromoTitle, bonusValue, bonusPrimaryTitle, freeSpinsPromoText, primaryTitleSpins, numberOfFreeSpins, bonusButtonSubTitle, bonusButtonTitle, viewDetailsLabelText } =
    (item && item.affiliatedFields && item.affiliatedFields.reviewMainFields) || "";

  // console.log(bonusButtonSubTitle,bonusButtonTitle,item,"bonusButtonSubTitle,bonusButtonTitle item");
  let { affiliatedLink } = (item && item.affiliatedFields && item.affiliatedFields.reviewMainFields) || {};
  let { linksList } = (item && item.affiliatedFields.reviewFooterFields) || {};
  let paymentLogos = (item && item.affiliatedFields.paymentLogos) || {};
  let paymentGatewaysOrder = (item && item.affiliatedFields.paymentGatewaysOrder) || [];
  let acceptedCountries = (item && item.affiliatedFields.acceptedCountries) || {};

  // let isFloatRating = ratingsValue &&
  //   ratingsValue.toString().includes(".") &&
  //   ratingsValue.toString().substr(ratingsValue.toString().indexOf(".") + 1, ratingsValue.toString().length) != "0";
  let ratingsInPercent = (ratingsValue * 100) / 5;

  useEffect(() => {
    if (history.location.pathname.includes(ROUTES.REVIEW)) {
      window.scrollTo(0, 0);
    }
    if (!isCollapse) {
      setPaymentCollapsed(isCollapse);
    }
  }, [isCollapse]);

  const showAfterLoginButtonTitleAndSubTitle = () => {

    let compensation100 = 100;
    let compensation50 = 50;
    let compensation25 = 25;
    let compensation10 = 10;

    item?.compensationCashbackPercent?.map((itm) => {
      if (itm?.compensation == 100) {
        compensation100 = itm?.cashbackPercent ? itm?.cashbackPercent : 100;
      } else if (itm?.compensation == 50) {
        compensation50 = itm?.cashbackPercent ? itm?.cashbackPercent : 50;
      } else if (itm?.compensation == 25) {
        compensation25 = itm?.cashbackPercent ? itm?.cashbackPercent : 25;
      } else if (itm?.compensation == 10) {
        compensation10 = itm?.cashbackPercent ? itm?.cashbackPercent : 10;
      }
    });

   if(item?.active == 100){
    return (
      <React.Fragment>
        {item?.cashbackButtonTitle?.replaceAll("{{compensation}}", `${compensation100}%`) || "Saa {{compensation}} Cashbackia"?.replaceAll("{{compensation}}", `${compensation100}%`)}
        <span>{item?.cashbackButtonSubtitle?.replaceAll("{{maxCompensation}}", item?.maxCompensation100) || "jopa {{maxCompensation}} € asti"?.replaceAll("{{maxCompensation}}", item?.maxCompensation100)}</span>
      </React.Fragment>
    );
   } else if(item?.active == 50){
    return(
      <React.Fragment>
        {item?.cashbackButtonTitle?.replaceAll("{{compensation}}", `${compensation50}%`) || "Saa {{compensation}} Cashbackia"?.replaceAll("{{compensation}}", `${compensation50}%`)}
        <span>{item?.cashbackButtonSubtitle?.replaceAll("{{maxCompensation}}", item?.maxCompensation50) || "jopa {{maxCompensation}} € asti"?.replaceAll("{{maxCompensation}}", item?.maxCompensation50)}</span>
      </React.Fragment>
    );
   } else if(item?.active == 25){
    return(
      <React.Fragment>
        {item?.cashbackButtonTitle?.replaceAll("{{compensation}}", `${compensation25}%`) || "Saa {{compensation}} Cashbackia"?.replaceAll("{{compensation}}", `${compensation25}%`)}
        <span>{item?.cashbackButtonSubtitle?.replaceAll("{{maxCompensation}}", item?.maxCompensation25) || "jopa {{maxCompensation}} € asti"?.replaceAll("{{maxCompensation}}", item?.maxCompensation25)}</span>
      </React.Fragment>
    );
   } else if(item?.active == 10){
    return(
      <React.Fragment>
        {item?.cashbackButtonTitle?.replaceAll("{{compensation}}", `${compensation10}%`) || "Saa {{compensation}} Cashbackia"?.replaceAll("{{compensation}}", `${compensation10}%`)}
        <span>{item?.cashbackButtonSubtitle?.replaceAll("{{maxCompensation}}", item?.maxCompensation10) || "jopa {{maxCompensation}} € asti"?.replaceAll("{{maxCompensation}}", item?.maxCompensation10)}</span>
      </React.Fragment>
    );
   }else {
      return (
        <React.Fragment>
          {bonusButtonTitle} 
          <span>{bonusButtonSubTitle}</span>
        </React.Fragment>
      );
    }

  };

  const isLocked = () => {
    let locked = false;
    let locked100 = false,locked50 = false,locked25 = false,locked10 = false;
    let enable100 = false,enable50 = false,enable25 = false,enable10 = false;

    item?.enabledCashbackForCompensation?.map((itm)=>{
      if (itm?.enabledCompensationCashbackPercent == 100 && itm?.CompensationCashbackStatus) {
        enable100 = true;
      } else if (itm?.enabledCompensationCashbackPercent == 50 && itm?.CompensationCashbackStatus) {
        enable50 = true;
      } else if (itm?.enabledCompensationCashbackPercent == 25 && itm?.CompensationCashbackStatus) {
        enable25 = true;
      } else if (itm?.enabledCompensationCashbackPercent == 10 && itm?.CompensationCashbackStatus) {
        enable10 = true;
      }
    });

    item?.lockedStatus?.map((itm) => {
      if (itm?.compensationPercent == 100 && itm?.isLocked) {
        locked100 = true;
      } else if (itm?.compensationPercent == 50 && itm?.isLocked) {
        locked50 = true;
      } else if (itm?.compensationPercent == 25 && itm?.isLocked) {
        locked25 = true;
      } else if (itm?.compensationPercent == 10 && itm?.isLocked) {
        locked10 = true;
      }
    });
    if(enable100 && locked100){
      return true;
    }else if(enable50 && locked50){
      return true;
    }else if(enable25 && locked25){
      return true;
    }else if(enable10 && locked10){
      return true;
    }
    return locked;
};

  const settleButtonColors = () => {

    let colorBeforeLogin = "";
    let colorAfterLogin100 = "";
    let colorAfterLogin50 = "";
    let colorAfterLogin25 = "";
    let colorAfterLogin10 = "";
    let activeCompensation = 0;
    item?.enabledCashbackForCompensation?.map((itm) => {
      if (itm?.CompensationCashbackStatus) {
          activeCompensation =  activeCompensation ? activeCompensation : itm.enabledCompensationCashbackPercent;
        }
    })

    headerContent?.labels?.map((itm) => {
      if (item?.active == 100 && itm?.compensationPercent == 100) {
        colorAfterLogin100 = { bg: itm.cashbackButtonBackgroundColor, shadow: itm?.cashbackButtonShadowColor };
      }
      if (item?.active == 50 && itm?.compensationPercent == 50) {
        colorAfterLogin50 = { bg: itm.cashbackButtonBackgroundColor, shadow: itm?.cashbackButtonShadowColor };
      }
      if (item?.active == 25 && itm?.compensationPercent == 25) {
        colorAfterLogin25 = { bg: itm.cashbackButtonBackgroundColor, shadow: itm?.cashbackButtonShadowColor };
      } 
      if (item?.active == 10 && itm?.compensationPercent == 10) {
        colorAfterLogin10 = { bg: itm.cashbackButtonBackgroundColor, shadow: itm?.cashbackButtonShadowColor };
      }
      if (activeCompensation == itm.compensationPercent) {
        colorBeforeLogin = { bg: itm.cashbackButtonBackgroundColor, shadow: itm?.cashbackButtonShadowColor };
      }
    })

    if (userToken) {
      if(colorAfterLogin100){
        setBgColors(colorAfterLogin100);
      } else  if(colorAfterLogin50){
        setBgColors(colorAfterLogin50);
      } else  if(colorAfterLogin25){
        setBgColors(colorAfterLogin25);
      } else if(colorAfterLogin10){
        setBgColors(colorAfterLogin10);
      }

    } else {
      setBgColors(colorBeforeLogin);
     }

  }

  useEffect(() => {
    settleButtonColors();
  },[item,headerContent])

  return (
    <Accordion className={`${userToken ? isLocked() ? "disable-casino" : "" : "" }`} defaultActiveKey="0" key={item ? item._id : ""}>
      <div className="tpbox-Wrapse" id="tpbox-Wrapse-id" key={item ? item._id : ""}>
        <div className="tprib-Mainse red">
          <div className="tprib-Angse">
            <i className="fa fa-caret-down" aria-hidden="true" style={{ color: ribbonBackgroundColor }}></i>{" "}
            <i className="fa fa-caret-down ryt-Se" aria-hidden="true" style={{ color: ribbonBackgroundColor }}></i>{" "}
          </div>
          <div
            className="tprib-text"
            style={{
              backgroundColor: ribbonBackgroundColor,
              borderColor: ribbonBorderColor,
              color: ribbonTextColor ? ribbonTextColor : "white",
            }}
          >
            <p>{ribbionText}</p>
          </div>
        </div>
        <div className="list-Boxwrap">
          <div className="top-Listse">
            <div className="row no-gutters">
              <div className="col-lg-3">
                <div className="logo-Topse" style={{ backgroundColor: logoBackgroundColor }}>
                  <Link
                    to={
                      userToken
                        ? affiliatedLink &&
                          affiliatedLink.affiliateKey &&
                          `${
                            isLocked()
                              ? "/"
                              : `${
                                  isLocked()
                                    ? "/"
                                    : `/${defaultConfig.LOCATION.toLowerCase()}/go/${affiliatedLink.affiliateKey}`
                                }`
                          }`
                        : ROUTE_CONSTANTS.LOGIN
                    }
                  >
                    <img
                      src={item && item.featuredImage ? `${REACT_APP_API_BASE_URL}${item.featuredImage}` : defaultImg}
                      alt="Add casino image from admin panel"
                    />
                  </Link>
                  <div className="ratin-Boxse rat-Bg-2" style={{ backgroundColor: reviewRatingBackgroundColor }}>
                    <div className="star-rating">
                      <Link
                        className="back-stars"
                        to={
                          userToken
                            ? item &&
                              item.permalink &&
                              `/${defaultConfig.LOCATION.toLowerCase()}${ROUTES.REVIEW + "/" + item.permalink}`
                            : item && item.permalink && `${ROUTES.REVIEW + "/" + item.permalink}`
                        }
                      >
                        <i class="fa fa-star-o" aria-hidden="true"></i>
                        <i class="fa fa-star-o" aria-hidden="true"></i>
                        <i class="fa fa-star-o" aria-hidden="true"></i>
                        <i class="fa fa-star-o" aria-hidden="true"></i>
                        <i class="fa fa-star-o" aria-hidden="true"></i>

                        <div className="front-stars" style={{ width: ratingsInPercent }}>
                          <i class="fa fa-star" aria-hidden="true"></i>
                          <i class="fa fa-star" aria-hidden="true"></i>
                          <i class="fa fa-star" aria-hidden="true"></i>
                          <i class="fa fa-star" aria-hidden="true"></i>
                          <i class="fa fa-star" aria-hidden="true"></i>
                        </div>
                      </Link>
                    </div>

                    {/* <ul>
                      
                      {
                        [1, 2, 3, 4, 5].map((data, index) => {
                          return (
                            <li key={index}>
                              {data <= ratingsValue &&
                                <a href={item && item.permalink && `${ROUTES_FI.REVIEW + "/" + item.permalink.permalink}` || "javascript:void(0);"} >
                                  <i className={`fa fa-star star-filled`} ></i>
                                </a>
                              }
                            </li>
                          );
                        })
                      }
                      {
                        isFloatRating &&
                        <a href="javascript:void(0);" style={{ "color": "#FFF", "fontSize": "12px", "margin": "0 2px" }}>
                          <i className={`fa fa-star star-half-filled`} ></i>
                        </a>
                      }
                      {
                        !ratingsValue ? [1, 2, 3, 4, 5].map((data, index) => {
                          return (
                            <li key={index}>
                              <a href="javascript:void(0);" >
                                <i className={`fa fa-star star-empty`} ></i>
                              </a>
                            </li>
                          );
                        })
                          : null
                      }
                    </ul> */}
                    {/* ) : (
                      <p style={{ color: "white", padding: "3px" }}>No rating</p>
                    )} */}
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="top-Listxt">
                  <div className="row align-items-center">
                    <div className="col-lg-6 right-Marse">
                      <div className="row no-gutters align-items-center">
                        <div class="col-lg-12">
                          <div className="information-Boxse" style={{ color: "#43b422" }}>
                            {/* <h3>
                              888 casino erbjuder dig 100% bonus
                              <br />
                              upp till 100 KR på din första insättning!
                            </h3> */}
                            {/* <p>
                              Ta del av 888 casino's generöra välkomstbonus på 100% upp
                              <br />
                              till 100 KR, de har ett stort spelutbud och erbjuder självklart <br />
                              snabba och skattefria uttag via Trustly direkt till ditt bankkonto.
                            </p> */}
                            <h3>
                              <Link
                                className="bonusPromoTitle"
                                to={
                                  userToken
                                    ? affiliatedLink &&
                                      affiliatedLink.affiliateKey &&
                                      `${
                                        isLocked()
                                          ? "/"
                                          : `/${defaultConfig.LOCATION.toLowerCase()}/go/${affiliatedLink.affiliateKey}`
                                      }`
                                    : ROUTE_CONSTANTS.LOGIN
                                }
                              >
                                {bonusPromoTitle}
                              </Link>
                            </h3>
                            <div className="bonus-Boxse">
                              {/* <h3>{bonusPromoTitle}</h3> */}
                              <p>{bonusPrimaryTitle}</p>
                              {/* <p>{bonusPrimaryTitle}</p>  */}
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-lg-4">
                          <div className="bonus-Boxse">
                            {/* <h3>{bonusPromoTitle}</h3> */}
                        {/* <p>{bonusValue}</p>
                            <p>{bonusPrimaryTitle}</p> 
                           
                          </div>
                        </div> */}
                        {/* 
                        <div className="col-lg-4">
                          <div className="spin-Boxse">
                            {/* <h3>{freeSpinsPromoText}</h3>
                            <p>{numberOfFreeSpins}</p>
                            <p>{primaryTitleSpins}</p> */}
                        {/* </div>
                        </div> */}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="row ">
                        <div className="col-lg-6 d-none d-lg-block">
                          <div className="info-Boxse">
                            <ul>
                              {listOfInformations &&
                                listOfInformations.map((item, idx) => {
                                  return <li key={idx}>{item}</li>;
                                })}
                            </ul>
                          </div>
                        </div>
                        {/* <div>
                          <p>Bonus </p>
                          <p>
                            {item.affiliatedFields.reviewMainFields.bonusValue}{" "}
                            {item.affiliatedFields.reviewMainFields.bonusPrimaryTitle}
                          </p>
                        </div> */}
                        <div className="col-lg-6">

                          {userToken ? isLocked() ? (
                            <div className="review-Boxse">
                              <div className="primary-Gbtn full-Width">
                                <h6>{headerContent?.dailyLimitReachedLabel || "Daily limit reached"}</h6>
                                <span>{headerContent?.dailyLimitReachedDescription}</span>
                              </div>{" "}
                            </div>
                          ) : (
                            <div className="review-Boxse">
                              {" "}
                              <Link
                                to={affiliatedLink &&
                                     affiliatedLink.affiliateKey &&
                                      `${
                                        isLocked()
                                          ? "/"
                                          : `/${defaultConfig.LOCATION.toLowerCase()}/go/${affiliatedLink.affiliateKey}`
                                      }`
                                }
                                className={`primary-Gbtn full-Width`}
                                style={{
                                  backgroundColor: `${bgColors?.bg || "#65c82d"}`,
                                  borderBottomColor: `${bgColors?.shadow || "#65c82d"}`,
                                }}
                              >
                                {userToken
                                  ? showAfterLoginButtonTitleAndSubTitle()
                                  : <React.Fragment>
                                  {item?.preLoginButtonTitle || "Saa 100% Cashbackia"} <span>{item?.preLoginButtonDescription || "jopa 50 € asti"}</span>
                                </React.Fragment>}

                                {/* {!item?.active === 25 && bonusButtonTitle
                                  ? <React.Fragment>
                                    {bonusButtonTitle} <span>{bonusButtonSubTitle}</span>
                                  </React.Fragment>
                                  : item?.active === 25 ?
                                    <React.Fragment>
                                      {item?.cashbackButtonTitle} <span>{item?.cashbackButtonSubtitle}</span>
                                    </React.Fragment> :
                                    <React.Fragment>
                                      {bonusButtonTitle} <span>{bonusButtonSubTitle}</span>
                                    </React.Fragment>
                                } */}
                              </Link>{" "}
                            </div>
                          ) : <div className="review-Boxse">
                          <Link
                            to={ROUTE_CONSTANTS.LOGIN}
                            className={`primary-Gbtn full-Width`}
                            style={{
                              backgroundColor: `${bgColors?.bg || "#65c82d"}`,
                              borderBottomColor: `${bgColors?.shadow || "#65c82d"}`,
                            }}
                          >
                            <React.Fragment>
                              {item?.preLoginButtonTitle || "Saa 100% Cashbackia"} <span>{item?.preLoginButtonDescription || "jopa 50 € asti"}</span>
                            </React.Fragment>
                          </Link>
                        </div>
                          }
                          <div className="list-Topse">
                            {!isCollapse ? null : (
                              <a
                                className={classNames([{ collapsed: paymentCollapsed }])}
                                onClick={() => setPaymentCollapsed(!paymentCollapsed)}
                                data-toggle="collapse"
                                data-target="#c-4"
                              >
                                {viewDetailsLabelText || "MER FAKTA"}
                                <i className="fa fa-angle-up show_Ar"></i>
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="age-Breqsec">
                  <ul>
                    {linksList &&
                      linksList.map((field, idx) => (
                        <li key={idx}>
                          <a
                            href={field.link}
                            target={field.link ? "_blank" : ""}
                            rel="noreferrer"
                            className={field.link ? "" : "no-link"}
                          >
                            {field.text}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classNames([
              "list-Bodyse",
              { "collapse-Tab": paymentCollapsed },
              `${!isCollapse ? "list-bodyse-open" : ""}`,
            ])}
            id="c-4 list-bodyse-open-id"
          >
            <CassinoMoreDetails
              userToken={userToken}
              id={item && item._id}
              permalink={item && item.permalink}
              textContent={item && item.affiliatedFields && item.affiliatedFields.textContent}
              BonusData={(item && item.affiliatedFields && item.affiliatedFields.reviewMainFields) || {}}
              linksList={linksList || []}
              paymentLogos={paymentLogos || {}}
              paymentGatewaysOrder={paymentGatewaysOrder || []}
              acceptedCountries={acceptedCountries || {}}
              isReviewPage={isReviewPage}
            />
          </div>
        </div>
      </div>
    </Accordion>
  );
};
export default CasinoAccordionItem;
