import React, { Component } from "react";

import Slider from "react-slick";

const LatestMembersList = ({ data, settings }) => (
  <Slider {...settings}>
    {data.map((data, index) => {
      return (
        <div className="money-Listse" key={index}>
          <div className="row no-gutters  align-items-center">
            <div className="col-3">
              <div className={`logo-Rmoneyse ${data.class}`}  style={{"background": data.LogoBg || ""}}>
                {" "}
                <a href="javascript:void(0);">
                  <img src={data.Logo} alt="..." className={data.LogoClass || ""}/>
                </a>{" "}
              </div>
            </div>
            <div className="col-9">
              <div className="row no-gutters  align-items-center">
                <div className="col-10">
                  <div className="row no-gutters  align-items-center">
                    <div className="col-4">
                      <div className="client-Rdetailse">
                        <h3>{data.heading}</h3>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="client-Rmoney">
                        <h3>{data.money}</h3>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="date-Rmoneyse">
                        <h3>{data.date}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 text-center">
                  <div className="check-Rmoneyse  text-center">
                    {" "}
                    <img src={data.check_money_image}  alt="..." />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    })}
  </Slider>
);

export default LatestMembersList;
