import React, { useState, useEffect } from "react";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from "@material-ui/core/TextField";
const InputComponent = ({ inputRef, ...other }) => <div {...other} />;
const OutlinedDiv = ({ children, label }) => {
    return (
        <TextField
            variant="outlined"
            label={label}
            multiline
            required
            InputLabelProps={{ shrink: true }}
            InputProps={{
                inputComponent: InputComponent
            }}
            inputProps={{ children: children }}
        />
    );
};

const MaterialRadioButtons = ({
    input,
    defaultValue,
    label,
    innerLabel = {},
    onChange = () => { },
    ...custom
}) => {
    const [radioValue, setRadioValue] = useState(defaultValue || null)

    useEffect(()=> {
        setRadioValue(defaultValue)
    }, [defaultValue])

    return (
        <OutlinedDiv label={ label || "Gender" } >
            <RadioGroup aria-label="gender" name="gender1" row required  {...input} value={radioValue}
                {...custom}
                onChange={(e, value) => {
                    input.onChange(value);
                    onChange(value);
                    setRadioValue(value)
                }}>
                <FormControlLabel value="1" control={<Radio />} label= { innerLabel?.maleLabel || "Male" } />
                <FormControlLabel value="2" control={<Radio />} label={ innerLabel?.femaleLabel ||"Female" }/>
            </RadioGroup>
        </OutlinedDiv>
    );
};
export default MaterialRadioButtons;
