import Screen from './screen';
import { connect } from 'react-redux';
import { verifyUserRequest, loginUserRequest } from '../../../../redux/actions';

const mapStateToProps = statte => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        loginUserRequest: payload => dispatch(loginUserRequest(payload)),
        verifyUserRequest: payload => dispatch(verifyUserRequest(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);