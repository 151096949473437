import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import { getAllPages } from "../../redux/actions";
import NotFound from "../NotFound";
import AffiliateLink from "../AffiliateLink";
import { Uttag } from "../../components";
import { defaultConfig } from "../../config/default";

const { ROUTE_CONSTANTS, ROUTES } = require(`../../shared`);
const { HomeScreen, Page, CasinoReview, VerificationHistory, NewsArticles, NewsItem, NotificationInbox, Maintenance } = require(`../../views/1.0`);

const AuthRouter = ({ getAllPages }) => {
  const [pages, set_pages] = useState([]);

  useEffect(() => {
    getAllPages({
      success: (data) => {
        set_pages(data.pages);
      },
      fail: (message) => {
        console.error("getAllPages", message);
      },
    });
  }, []);

  return (
    <React.Fragment>
      <Switch>
        <Route
          exact
          path={ROUTE_CONSTANTS.MAINTENANCE}
          component={Maintenance} />
        {pages &&
          pages.map((item, index) => {
            return (
              <Route
                exact
                path={`/${(defaultConfig.LOCATION).toLowerCase()}/${item.permalink}`}
                key={index}
                render={(props) => <Page id={item._id} {...props} />}
              />
            );
          })}
        {pages &&
          pages.map((item, index) => {
            return (
              <Route
                exact
                path={`/${item.permalink}`}
                key={index}
                render={(props) => <Redirect to={`/${(defaultConfig.LOCATION).toLowerCase()}/${item.permalink}`} />}
              />
            );
          })}
        <Route exact path={`/${(defaultConfig.LOCATION).toLowerCase()}` + ROUTES.REVIEW + '/:permalink'} component={CasinoReview} />
        <Route exact path={ROUTE_CONSTANTS.DASHBOARD} component={HomeScreen} />
        <Route exact path={ROUTE_CONSTANTS.ROOT} render={(props) => <Redirect to={ROUTE_CONSTANTS.DASHBOARD} />} />
        <Route
          path={ROUTE_CONSTANTS.APPROVE_WITHDRAWAL}
          render={props => {
            return (
              <HomeScreen {...props} showApprovePopup={true} />
            )
          }} >
        </Route>
        <Route exact path={ROUTES.HISTORY} component={VerificationHistory} />
        <Route exact path={`/${(defaultConfig.LOCATION).toLowerCase()}${ROUTES.NEWS}`} component={NewsArticles} />
        <Route exact path={`/${(defaultConfig.LOCATION).toLowerCase()}${ROUTES.NEWS}` + "/:id"} component={NewsItem} />
        <Route exact path={`/${(defaultConfig.LOCATION).toLowerCase()}/notifications`} component={NotificationInbox} />
        <Route path={`/${(defaultConfig.LOCATION).toLowerCase()}/go/:id`} component={AffiliateLink} />
        <Route path="*" component={NotFound} />
        {/* <Redirect to={ROUTE_CONSTANTS.ROOT} /> */}
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userToken: state.common.userToken,
    isWithdrawalPending: state.wallet.isWithdrawalPending
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllPages: (payload) => dispatch(getAllPages(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AuthRouter);
