import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";
import { TEXT_CONST } from "../../../shared";

const Select = ({
  placeholder = 'Select',
  config = {},
  options,
  widthStyle,
  input,
  onValueChange = () => { },
  handleSelectedOption = () => { },
  isSearchable = false,
  defaultVal = "",
  filterOption = () => { },
  noCasinoFoundText
}) => {
  widthStyle = widthStyle ? widthStyle : "";

  const [selectValue, setSelectvalue] = useState("");

  useEffect(() => {
    setSelectvalue(defaultVal);
  }, [defaultVal]);

  return (
    <>
      <div className="form-group">
        <ReactSelect
          placeholder={placeholder}
          {...input}
          {...config}
          options={options}
          width={widthStyle}
          isSearchable={isSearchable}
          value={selectValue}
          onChange={(value) => {
            onValueChange(value.value);
            setSelectvalue(value);
            handleSelectedOption(value);
          }}
          // className={"form-select"}
          filterOption={filterOption}
          className={"react-select-dropdown"}
          classNamePrefix={"react-select-dropdown-prefix"}
          onBlur={(event) => event.preventDefault()}
          noOptionsMessage={() => noCasinoFoundText || TEXT_CONST.NO_OPTION}
        />
      </div>
    </>
  );
};
export default Select;
