import { takeLatest, all, put } from "redux-saga/effects";
import { getRequest } from "../../helpers";
import { API, CATEGORY_TYPE, STATUS_CODE } from "../../shared";
import { GET_ALL_NEWS, GET_NEWS_BY_CATEGORY_ID, GET_NEWS_BY_PERMALINK, GET_NEWS_CATEGORIES, startLoading, stopLoading } from "../actions";

const LOCATION_ID = process.env.REACT_APP_LOCATION_ID;



function* getNews({
    limit, 
callback
}) {
    try {
        yield put(startLoading());
        let params = `?locationId=${LOCATION_ID}`
        if(limit) {
            params += `&limit=${limit}`
        }
        const response = yield getRequest({
            API: `${API.GET_ALL_NEWS}${params}`,
        });

        if (response.status !== STATUS_CODE.successful) 
            callback([]);
        else 
            callback(response.data.data);
    } catch (error) {
        callback([]);
        console.error("getNews", error)
    } finally {
        yield put(stopLoading());
    }
}

function* getNewsByPermalink({ permalink, callback }) {
  try {
    yield put(startLoading());
    let params = `?locationId=${LOCATION_ID}&permalink=${permalink}`;

    const response = yield getRequest({
      API: `${API.GET_NEWS_BY_PERMALINK}${params}`,
    });
    if (response.status !== STATUS_CODE.successful) callback([]);
    else callback(response.data.data);
  } catch (error) {
    callback([]);
  } finally {
    yield put(stopLoading());
  }
}

function* getNewsCategories({
  payload: {
    success = () => {},
    fail = () => {},
  } = {},
}) {
  try {
    yield put(startLoading());
    const { data } = yield getRequest({
      API: `${API.GET_CASINO_CATEGORIES_FOR_REVIEWS}?locationId=${LOCATION_ID}&type=${CATEGORY_TYPE.NEWS_ARTICLES}`,
    });
    if (data.statusCode == STATUS_CODE.successful) {
      success(data.data);
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* getNewsByCategoryId({
  payload: {
    payload,
    success = () => {},
    fail = () => {},
  } = {},
}) {
  try {
    yield put(startLoading());

    let apiLink = `${API.GET_NEWS_LIST_BY_CATEGORY_ID}?locationId=${LOCATION_ID}&categoryId=${payload.categoryId}`;
    if(payload.limit){
      apiLink += `&limit=${payload.limit}`;
    }
    const { data } = yield getRequest({
      API: apiLink,
      DATA: { ...payload },
    });

    if (data.statusCode == STATUS_CODE.successful) {
      success(data.data);
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* NewsSaga() {
  yield all([
    takeLatest(GET_ALL_NEWS, getNews),
    takeLatest(GET_NEWS_BY_PERMALINK, getNewsByPermalink),
    takeLatest(GET_NEWS_CATEGORIES, getNewsCategories),
    takeLatest(GET_NEWS_BY_CATEGORY_ID, getNewsByCategoryId),
  ]);
}

export default NewsSaga;
