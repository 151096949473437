import { takeLatest, all, put } from "redux-saga/effects";
import { postRequest, getRequest } from "../../helpers";
import { API, STATUS_CODE } from "../../shared";
import { startLoading, stopLoading, GET_ALL_PAGES, GET_PAGE_DATA_BY_PAGE_ID } from "../actions";
const LOCATION_ID = process.env.REACT_APP_LOCATION_ID;

function* getAllPages({ payload: { payload, success = () => {}, fail = () => {} } = {} }) {
  try {
    yield put(startLoading());
    const { data } = yield getRequest({
      API: `${API.GET_ALL_PAGES}?locationId=${LOCATION_ID}&status=publish`,
      DATA: { ...payload },
    });

    if (data.statusCode == STATUS_CODE.successful) {
      success(data.data);
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* getPageData({ payload: { payload, success = () => {}, fail = () => {} } = {} }) {
  try {
    yield put(startLoading());
    const { data } = yield getRequest({
      API: `${API.GET_PAGE_DATA_BY_PAGE_ID}/${payload.pageId}?locationId=${LOCATION_ID}`,
      DATA: { ...payload },
    });

    if (data.statusCode == STATUS_CODE.successful) {
      success(data.data);
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* PagesSaga() {
  yield all([takeLatest(GET_ALL_PAGES, getAllPages), takeLatest(GET_PAGE_DATA_BY_PAGE_ID, getPageData)]);
}

export default PagesSaga;
