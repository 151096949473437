import {
  SET_USER_WALLET_DATA,
  SET_WITHDRAWAL_DATA,
  SET_WITHDARWAL_PENDING_STATUS,
  SET_WITHDARWAL_APRROVE_SUCCESS,
  RESET_WALLET
} from "../actions";

const initialCommonState = {
  walletData: {},
  withdrawalData: {},
  isWithdrawalPending: false,
  isWithdrawalApprovalSuccess: false
};

const WalletReducer = (state = { ...initialCommonState }, action) => {
  switch (action.type) {
    case SET_USER_WALLET_DATA:
      return {
        ...state,
        walletData: action.data,
      };

    case SET_WITHDRAWAL_DATA:
      return {
        ...state,
        withdrawalData: action.data
      }
    case SET_WITHDARWAL_PENDING_STATUS:
      return {
        ...state,
        isWithdrawalPending: action.data
      }
    case SET_WITHDARWAL_APRROVE_SUCCESS:
      return {
        ...state,
        isWithdrawalApprovalSuccess: action.data
      }
    case RESET_WALLET:
      return {
        ...state,
        walletData: {},
        withdrawalData: {},
        isWithdrawalPending: false,
        isWithdrawalApprovalSuccess: false
      };
    default:
      return state;
  }
};

export default WalletReducer;
