export const checkFileSize = (size) => {
  if (size > 5000000) {
    return false;
  }
  return true;
};

export const fileType = (type) => {
  if (type == "image/jpeg" || type == "image/png") {
    return true;
  }
  return false;
};
