import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSnackbar } from "notistack";

import { Footer, Header } from "../../../../components";
import "./styles.scss";
import clock_icon from "../../../../assets/images/clock_icon.svg";
import { IMAGES } from "../../../../../src/shared/constants/images";
import { TEXT_CONST, SERVER_URL } from "../../../../shared";
import InfiniteScroll from "react-infinite-scroll-component";

const Screen = ({
  markAsReadMessage,
  getAllNotifications,
  notificationClearAt,
  clearAllNotifications,
  notificationScreenContent = {}
}) => {
  let [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  let [currentIdx, setCurrentIdx] = useState(0);
  const [pageno,setPageNo] = useState(1);
  const [notifications,setNotifications] = useState([]);
  const [totalCount,setTotalCount] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const setMessageData = (idx) => {
    setShow(true);
    setCurrentIdx(idx);
  };

  const markAsRead = async (data,index) => {

    let tempNotifications = [...notifications];
    tempNotifications[index].msgRead =  true;
    setNotifications(tempNotifications); 
    
    await markAsReadMessage(data._id);
  };

  const clearAll = () => {
    clearAllNotifications((success) => {
      if (!success) {
        enqueueSnackbar(TEXT_CONST.NOTIFICATION_CLEAR_ERROR, {
          variant: "error",
        });
      } else {
        let payload = {
          page : 1,
          limit : 20
        }
        getAllNotifications(payload,(data)=>{
          setNotifications(data.data);
          setTotalCount(data.total);
          setPageNo(2);
        });
        enqueueSnackbar(TEXT_CONST.NOTIFICATION_CLEAR_SUCCESS, {
          variant: "success",
        });
      }
    });
  };

  const fetchNotifications = () =>{
    let payload = {
      page : pageno,
      limit : 20,
      filterByDate : startDate
    }
    getAllNotifications(payload,(data)=>{
      setNotifications(prevState => {
        return [...prevState,...data.data];
      }); 
      setTotalCount(data.total);
      setPageNo((prevState)=> prevState + 1);
    });
  }

  useEffect(()=>{
  fetchNotifications();
  },[])

  return (
    <>
      <div className="main-content">
        <div className="sub_header">
          <Header />
        </div>
        <div class=" notification-modal mid-Mainsec">
          <div class="container_width">
            <div class="row align-items-end flex-column">
              <div class="col-lg-12 mb-3">
                <div class="title-Betse">
                  <h2>{notificationScreenContent.title || `Meddelande Inkorg`}</h2>
                </div>
              </div>

              <div class="col-md-12 notification_filter">
                <div class="filter-options">
                  <div class="d-flex align-items-center justify-content-center">
                    <label className="pr-2">
                      <strong>{notificationScreenContent.filterLabel || `Filtrera`}</strong>
                    </label>
                    <DatePicker
                      className="form-control"
                      selected={startDate}
                      maxDate={new Date()}
                      minDate={new Date(notificationClearAt)}
                      onChange={(date) => {
                        setStartDate(date);
                        let payload = {
                          filterByDate: date,
                          page: 1,
                          limit: 20,
                        };
                        getAllNotifications(payload, (data) => {
                          setNotifications(data.data);
                          setTotalCount(data.total);
                          setPageNo(data.length < 20 ? 1 : 2);
                        });
                      }}
                    />
                  </div>
                  {notifications?.length ? (
                    <div class="mb-1 mt-2 d-flex align-items-center justify-content-end clear_all">
                      <p onClick={clearAll}>{notificationScreenContent.clearAllLabel || `Rensa inkorgen`} </p>
                    </div>
                  ) : null}
                </div>
                {!notifications?.length ? (
                  <div className="my-5 no-inbox-data text-center">
                    <div className="inbox-empty ">
                      {" "}
                      <div class="col-sm-4 col-12 mx-auto bg-white p-md-4 p-2 rounded">
                        <img src={IMAGES.BLANK_INBOX} width="200" />
                        <p className="pt-3">
                          {notificationScreenContent.emptyInboxMessage || "DU HAR INGEN MEDDELANDE"}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <ul id="scrollableDiv1" className="notification-list notifi_detail mt-0">
                    <InfiniteScroll
                      dataLength={notifications?.length || 0}
                      next={fetchNotifications}
                      loader={
                        <div className="loader_text">
                          <span data-loading-text="Loading..."></span>
                        </div>
                      }
                      hasMore={totalCount > notifications?.length}
                      scrollableTarget="scrollableDiv1"
                    >
                      {notifications?.map((notification, idx) => (
                        <li
                          className={`list-group-item list-group-item-action text-left mb-2 ${
                            !notification.msgRead ? "unread-msg unread-msg-list" : ""
                          } ${notification?.isLocked ? "disable-casino" : null}`}
                          key={idx}
                          onClick={() => markAsRead(notification, idx)}
                        >
                          <div className="col-md-12 notification-info">
                            <div className={`row align-items-start notification-list-user-block`}>
                              {notification.image && (
                                <div
                                  className="img-pr pr-md-3"
                                  style={{
                                    backgroundColor: notification?.additionalInfo?.logoBackgroundColor || "transparent",
                                  }}
                                >
                                  <img src={`${SERVER_URL}${notification.image}`} alt="featuredImage" />
                                </div>
                              )}
                              <div className={`notification_summ ${notification.image ? "" : "notification-detail"}`}>
                                <div className="notification-list-user-name">
                                  <h6>
                                    {notification.link ? (
                                      <a href={notification.link} target="_blank">
                                        {notification.title}
                                      </a>
                                    ) : (
                                      notification.title
                                    )}
                                  </h6>
                                </div>
                                <div className="notification-date">
                                  <i className="mr-1">
                                    <img src={clock_icon} alt="" width="12" />
                                  </i>{" "}
                                  {moment(notification.createdAt).format("DD-MM-YYYY")}{" "}
                                  <span> at {moment(notification.createdAt).format("hh:mm a")}</span>
                                </div>

                                {notification?.isLocked ? (
                                  <div className="disable-casion">Daily limit Reached</div>
                                ) : (
                                  <div
                                    className={
                                      `notification-body` + `${idx == currentIdx && show ? " show-details" : ""}`
                                    }
                                  >
                                    {!(idx == currentIdx && show) ? (
                                      notification.body && (
                                        <div
                                          className="affiliateLink"
                                          dangerouslySetInnerHTML={{ __html: notification.body }}
                                        ></div>
                                      )
                                    ) : (
                                      <div
                                        className="affiliateLink"
                                        dangerouslySetInnerHTML={{ __html: notification.body }}
                                      ></div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </InfiniteScroll>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
const mapStateToProps = (state) => {
  return { notificationScreenContent: state.common.notificationContent };
};

export default connect(mapStateToProps)(Screen);

