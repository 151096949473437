import React from 'react'
import { Checkbox } from "@material-ui/core";
import { IMAGES} from "../../../shared";


function ModalContent({ popupContent, showModalAgain, setShowModalAgain, handleChangeLanguage }) {
    return (
        <div className="row no-gutters">
            <div className="col-lg-7">
                <div className="left-Logse text-left">
                    <h2 className="mb-3 text-center" dangerouslySetInnerHTML={{ __html: popupContent.leftTitle ? popupContent.leftTitle : null }} />
                    <div dangerouslySetInnerHTML={{ __html: popupContent.leftIntroContent ? popupContent.leftIntroContent : null }}></div>
                    <div class="d-flex justify-content-center mb-2">
                        <img src={IMAGES.UK_FLAG_ROUND} alt="UK" onClick={() => {handleChangeLanguage("en")}} class="mr-3"/>
                        <img src={IMAGES.FINLAND_FLAG_ROUND} alt="Finland" onClick={() => {handleChangeLanguage("fi")}}/>                    
                    </div>
                    <Checkbox
                        onChange={(e) => setShowModalAgain(prev => !prev)}
                        checked={showModalAgain}
                    />
                    <span className="check-box" dangerouslySetInnerHTML={{ __html: popupContent.checkboxLabel ? popupContent.checkboxLabel : null }} />
                </div>
            </div>

            <div className="col-lg-5 d-none d-lg-flex">
                <div className="right-Logse">
                    <h3 dangerouslySetInnerHTML={{ __html: popupContent.rightTitle  }}>

                    </h3>
                    <div className="binny-Mose">
                        <img src={IMAGES.RABIT_ANIMATION} alt="..." />{" "}
                    </div>
                    <p dangerouslySetInnerHTML={{
                        __html: popupContent.rightIntroContent }}>

                    </p>
                </div>
            </div>

        </div>
    )
}

export default ModalContent
