import { takeLatest, all, put } from "redux-saga/effects";
import { getRequest } from "../../helpers";
import { API, STATUS_CODE } from "../../shared";
import { GET_FREQUENT_ASKED_QUESTIONS, startLoading, stopLoading } from "../actions";

export const LOCATION_ID = process.env.REACT_APP_LOCATION_ID;

function* getFrequentlyAskQuestions({
  payload: { payload, validateUser = false, success = () => {}, fail = () => {} } = {},
}) {
  try {
    yield put(startLoading());

    const { data } = yield getRequest({
      API: `${API.GET_FAQ_QUESTIONS}?locationId=${LOCATION_ID}`,
      DATA: { ...payload },
    });

    if (data.statusCode == STATUS_CODE.successful) {
      if (validateUser) {
        success();
      } else {
        success(data.data);
      }
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* FaQSaga() {
  yield all([takeLatest(GET_FREQUENT_ASKED_QUESTIONS, getFrequentlyAskQuestions)]);
}

export default FaQSaga;
