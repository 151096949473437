import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { getAffliateLinkbyAffliateKey } from "../redux/actions";
import { ROUTE_CONSTANTS } from "../shared";
import NotFound from "./NotFound";

const AffiliateLink = ({ getAffliateLinkbyAffliateKey = () => { }, affiliateKey }) => {
  const history = useHistory();
  const [is404Error, set404Error] = useState(false);
  useEffect(() => {
    let payload = {
      affiliateKey: affiliateKey,
    };
    getAffliateLinkbyAffliateKey({
      payload,
      success: (data) => {
        let { affiliateLink } = data;
        if (affiliateLink) {
          window.location.assign(affiliateLink);
        } else history.push(ROUTE_CONSTANTS.DASHBOARD);
      },
      fail: (message) => {
        history.push(ROUTE_CONSTANTS.DASHBOARD);
      },
    });
  }, []);

  return <React.Fragment>{!is404Error ? null : <NotFound />}</React.Fragment>;
};
const mapStateToProps = (state, props) => {
  let { params } = props.match || {};
  let { id } = params || {};

  return {
    affiliateKey: id || "",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAffliateLinkbyAffliateKey: (payload) =>
      dispatch(getAffliateLinkbyAffliateKey(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateLink);
