import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import "swiper/swiper.scss";

import "./styles.scss";

import { HEADER_CONTENT_TYPES, } from "../../../shared";
import CardForSteps from "../../atoms/CardForSteps";
import { getHowItWorksSteps } from "../../../redux/actions";

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const settings = {
  dots: true,
  infinite: false,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: false,
  arrows: false,
  autoplaySpeed: false,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },

    {
      breakpoint: 767,

      settings: {
        slidesToShow: 1,
      },
    },

    {
      breakpoint: 420,

      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const HowItWorks = ({ getHowItWorksSteps, headerContent }) => {
  const [allStepsData, set_allStepsData] = useState([]);

  useEffect(() => {
    getHowItWorksSteps({
      success: (data) => {
        set_allStepsData(data);
      },
      fail: (message) => { },
    });
  }, []);
  return (
    <div className="working-Mainse">
      <div className="title-Betse">
        {allStepsData.length ? (
          <>
            <h2 dangerouslySetInnerHTML={{ __html: headerContent && headerContent.title }}></h2>
            {headerContent.content && headerContent.content.trim() &&
              <p className="step-header-content" dangerouslySetInnerHTML={{ __html: headerContent && headerContent.content }}></p>
            }
          </>
        ) : null}
      </div>
      <div className="inner-Pwayse">
        <div className="row center">
          <div className="col-lg-12">
            {allStepsData.length ? (
              <Slider {...settings}>
                {allStepsData.map((item, index) => {
                  return (
                    <div key={index}>
                      <CardForSteps
                        order={item.order}
                        key={index}
                        index={index}
                        cardIcon={`${REACT_APP_API_BASE_URL}${item.imgUrl}`}
                        heading={item.title}
                        description={item.description}
                      />
                    </div>
                  );
                })}
              </Slider>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    headerContent:
      state.common.dynamicContent[HEADER_CONTENT_TYPES.HOW_DOES_IT_WORKS] || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHowItWorksSteps: (payload) => dispatch(getHowItWorksSteps(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HowItWorks);
