import React from "react";
import './style.css';
// import { IMAGES } from "../../../shared";

const ReviewsSection = ({ headerContent }) => {
  return (
    <div className="ratingSec">
      <div className="row no-gutters align-items-center">
        <div className="col-md-12">
          <div className="trustpilot-widget-container">

            {/* TrustBox widget - Micro Review Count */}
            <div class="trustpilot-widget" data-locale="fi-FI" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="6113b84bf7afe5001d6a8ec8" data-style-height="24px" data-style-width="100%" data-theme="dark">
              <a href="https://fi.trustpilot.com/review/riskivapaa.com" target="_blank" rel="noopener">Trustpilot</a>
            </div>
            {/* End TrustBox widget  */}
          </div>
        </div>
        {/* <div className="col-lg-4">
        <div className="rat-Leftse">
          <h3 dangerouslySetInnerHTML={{ __html: headerContent && headerContent.seeOurReviewLabel }}></h3>
        </div>
        <div className="spcae-Topse3 d-lg-none"> </div>
      </div>
      <div className="col-lg-5">
        <div className="rat-Midse">
          <ul>
            <li>
              <span>
                <img src={IMAGES.STAR} alt="..." />
              </span>
            </li>
            <li>
              <span>
                <img src={IMAGES.STAR} alt="..." />
              </span>
            </li>
            <li>
              <span>
                <img src={IMAGES.STAR} alt="..." />
              </span>
            </li>
            <li>
              <span>
                <img src={IMAGES.STAR} alt="..." />
              </span>
            </li>
            <li>
              <span className="halfBg">
                <img src={IMAGES.STAR} alt="..." />
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="spcae-Topse3 d-lg-none"> </div>
        <div className="rat-Leftse">
          {" "}
          <img src={IMAGES.TRUST_LOGO} alt="..." />
        </div>
      </div> */}
      </div>
    </div>
  );
};

export default ReviewsSection;
