import { defaultConfig } from "../../config/default";
import { IMAGES } from "./images";
import { TEXT_CONST } from "./strings";

const REACT_APP_LOCATION = process.env.REACT_APP_LOCATION;

export const EMAIL_REGX = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NAME_REGX = /^[A-Z.a-z'\b ]+$/;
export const OTP_REGX = /^[0-9]{6}$/;
export const JWT_SIGN_KEY = "fasdkfjklandfkdsfjladsfodfafjalfadsfkads";
export const SE_PHONE_REGEX = /^(([+]\d{2}[ ][1-9]\d{0,2}[ ])|([0]\d{1,3}[-]))((\d{2}([ ]\d{2}){2})|(\d{3}([ ]\d{3})*([ ]\d{2})+))$/;
export const FI_PHONE_REGEX = /^(([+]\d{2}[ ][1-9]\d{0,2}[ ])|([0]\d{1,3}[-]))((\d{2}([ ]\d{2}){2})|(\d{3}([ ]\d{3})*([ ]\d{2})+))$/;

export const REGISTER_STEPS = [
  {
    cardIcon: IMAGES.BANK_ID_SVG,
    heading: "Registrera dig med BankID",
    description:
      "Det är enkelt och gratis att skapa ett konto hos oss, klicka på Registrera dig-knappen, fyll i namn och epost så verifierar du dig själv med BankID efter du har gjort det så är du en registrerad medlem hos oss!",
  },
  {
    cardIcon: IMAGES.SPIN_CAS,
    heading: "Välj ett casino att spela på",
    description:
      "I topplistan nedan har vi tagit fram både nya och de bästa casinon som innehar en svensk licens. Läs och välj ett casino som du inte har spelat på tidigare, klicka på gröna knappen och gör din första insättning på casinot.",
  },
  {
    cardIcon: IMAGES.DOC_SEARCH,
    heading: "Hade du otur att förlora pengarna?",
    description:
      "Ingen fara, vi är här för att hjälpa!  Gå till menyn Verifiering. Där kan du skicka in en bild på att du har gjort en insättning på casinot och förlorat det. Vi granskar det och betalar sedan tillbaka summan som du förlorade!",
  },
  {
    cardIcon: IMAGES.WALLET_SVG,
    heading: "Automatiska utbetalningar",
    description:
      "När granskningen har godkänds så uppdateras din kontobalans automatiskt. Där kan du sedan välja att göra ett uttag. Verifiera dig med BankID och pengarna är tillbaka på ditt personliga bankkonto på några minuter.",
  },
];

export const STATUS_CODE = {
  unAuthorized: 401,
  successful: 200,
};

export const FOOTER_TYPES = {
  UPPER: 1,
  LOWER: 2,
};

export const HEADER_CONTENT_TYPES = {
  REVIEW: 1,
  NEWS: 2,
  MAIN_HEADER: 3,
  HOW_DOES_IT_WORKS: 4,
  MONEY_BACK_AND_PAID_OUT: 5,
  FAQ: 6,
  MAIN_FOOTER: 7,
  TITLE_AND_META_DESCRIPTION: 8,
  REVIEW_DETAILS_PAGE: 9,
  MAINTENANCE: 10
};

export const POPUP_FORM_CONTENT_TYPES = {
  REGISTER: 1,
  LOGIN: 2,
  UPDATE_PROFILE: 3,
  ONBOARDING_CONTENT: 5
};

export const SIDEBAR_CONTENT_TYPES = {
  VERIFICATION: 1,
  WALLET: 2,
  HISTORY: 3,
  WITHDRAWAL_APPROVAL: 4,
  KYC: 5
};

export const CATEGORY_TYPE = {
  REVIEWS: 1,
  NEWS_ARTICLES: 2,
};

export const VALIDATION_MESSAGES = {
  EMAIL_VALIDATION: TEXT_CONST.EMAIL_VALIDATION,
  PHONE_NUMBER_VALIDATION: TEXT_CONST.PHONE_NUMBER_VALIDATION,
  ADDRESS_VALIDATION: TEXT_CONST.ADDRESS_VALIDATION,
  PHONE_NUMBER_REQUIRED: TEXT_CONST.PHONE_NUMBER_REQUIRED,
};

export const ROUTES_SE = {
  NEWS: "/nyheter",
  REVIEWS: `/recensioner`,
  REVIEW: `/recension`,
  HISTORY: `/${(defaultConfig.LOCATION).toLowerCase()}/historik`,
};

export const ROUTES_FI = {
  NEWS: "/uutiset",
  REVIEWS: `/arvostelut`,
  REVIEW: `/arvostelut`,
  HISTORY: `/${(defaultConfig.LOCATION).toLowerCase()}/historic`,
};

export const ROUTES = REACT_APP_LOCATION == "FI" ? ROUTES_FI : ROUTES_SE ;

export const VERIFICATION_STATUS = {
  PENDING: { title: "Pending", code: 1 },
  APPROVED: { title: "Approved", code: 2 },
  NEED_MODIFICATIONS: { title: "Need Modifications", code: 3 },
  DENIED: { title: "Rejected", code: 4 },
};

export const PERSIST_KEYS = {
  ADMIN: "persist:admin",
  USER: "persist:user",
};
