import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import OnboardingModal from '../../../../components/atoms/OnboardingModal/index'
import {
  CasinoAccordionList,
  Footer,
  Header,
  HomeBanner,
  HowItWorks,
  LatestMembers,
  NewsList,
  QA_Accordion,
} from "../../../../components";
import "./styles.scss";
import { ROUTE_CONSTANTS } from "../../../../shared";

const HomeScreen = ({
  location,
  history,
  showLogin,
  showRegister,
  userToken,
  isLandingPage,
  showApprovePopup = false,
  popupContent,
  updateOnboardingModal = () => { },
  token,
  initialModalOpen,
  showModal,
  closeOnboardingModal,
  getProfileRequest,
  maintenancePageContent
}) => {
  let timeout;
  const [showModalAgain, setShowModalAgain] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);

  useEffect(() => {
    if (maintenancePageContent?.underMaintenance) {
      history.push(ROUTE_CONSTANTS.MAINTENANCE);
    }
    if (token?.userToken) {
      getProfile()
    }

    return () => clearTimeout(timeout);
  }, [])

  const getProfile = (payload = {}) => {
    getProfileRequest({ payload });
  };

  useEffect(() => {
    if (token?.userToken && !initialModalOpen) {
      openModal()
    }
  }, [initialModalOpen]);

  const openModal = () => {
    if (!initialModalOpen) {
      timeout = setTimeout(() => { setShowOnboarding(showModal) }, 5000);
    }
  }

  const onModalClose = () => {
    if (showModalAgain) {
      updateOnboardingModal(popupContent?._id, { showOnboardingPopup: showModalAgain }, {})
      setShowOnboarding((prev) => !prev)
      closeOnboardingModal()
    }
    else {
      setShowOnboarding((prev) => !prev)
      closeOnboardingModal()
    }
  }

  return (
    <React.Fragment>
      {popupContent?.underMaintenance ? (!!showOnboarding && (
        <OnboardingModal
          show={showOnboarding}
          onClose={() => onModalClose()}
          showModalAgain={showModalAgain}
          setShowModalAgain={setShowModalAgain}
        />)) : null}
      <div className="main-content">
        <Header showApprovePopup={showApprovePopup} />
        <HomeBanner
          data={location.state || {}}
          showLogin={showLogin}
          showRegister={showRegister} />
        <div className="mid-Mainsec" id="home_detail">
          <div className="container">
            <HowItWorks />
            {!isLandingPage && <CasinoAccordionList />}
            <NewsList history={history} />
            <LatestMembers />
            <QA_Accordion />
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default HomeScreen;
