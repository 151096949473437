export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const UPDATE_REDUCER_AUTH_TOKEN = 'UPDATE_REDUCER_AUTH_TOKEN';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';

export const getUserDataSuccess = payload => {
    return {
        type: GET_USER_DATA_SUCCESS,
        payload
    }
}

export const updateReducerAuthToken = payload => {
    return {
        type: UPDATE_REDUCER_AUTH_TOKEN,
        payload
    }
}


export const logoutRequest = (payload = {}, callback) => {
    return {
        type: LOGOUT_REQUEST,
        payload,
        callback
    }
}