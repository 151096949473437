export const GET_ALL_NEWS = "GET_ALL_NEWS";
export const GET_NEWS_BY_PERMALINK = "GET_NEWS_BY_PERMALINK";
export const GET_NEWS_BY_CATEGORY_ID = "GET_NEWS_BY_CATEGORY_ID";
export const GET_NEWS_CATEGORIES = "GET_NEWS_CATEGORIES";

export const getAllNews = (limit, callback) => {
    return {
        type: GET_ALL_NEWS,
        limit,
        callback
    }
}

export const getNewsbyPermalink = (permalink, callback) => {
  return {
    type: GET_NEWS_BY_PERMALINK,
    permalink,
    callback,
  };
};

export const getNewsCategories = (payload) => {
  return {
    type: GET_NEWS_CATEGORIES,
    payload,
  };
};

export const getNewsByCategoryId = (payload) => {
  return {
    type: GET_NEWS_BY_CATEGORY_ID,
    payload,
  };
};
