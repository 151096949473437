import moment from "moment";

export const getAuthToken = (state) => state.common.userToken
export const getWithdrawalData = (state) => state.wallet.withdrawalData
export const getDeviceToken = (state) => state.common.deviceToken
export const getUserData = (state) => state.common.userData


export const checkOffline = () => {
  return !window.navigator.onLine;
};

export const getPrettyDate = (date) => {
  var now = new Date(date);
  var dateString = moment(now).format("DD-MM-YYYY");

  return dateString;
};

export const getPrettyTime = (date) => {
  var now = new Date(date);
  var time = moment(now).format("hh:mm a");
  return time;
};

export const deepClone = (data) => {
  if (Array.isArray(data)) {
    return data.slice();
  } else {
    return JSON.parse(JSON.stringify(data));
  }
};

export const getMonthDateRange = (year, month) => {
  // month in moment is 0 based, subtract 1 to compensate
  // array is 'year', 'month', 'day', etc
  var start = moment([year, month - 1])
    .startOf("month")
    .format("YYYY-MM-DD");

  // Clone the value before .endOf()
  var end = moment([year, month - 1])
    .endOf("month")
    .format("YYYY-MM-DD");

  // make sure to call toDate() for plain JavaScript date type
  return { startDate: start, endDate: end };
};

export const getAutomaticPayoutDate = (date) => {
  var now = new Date(date);
  var dateString = moment(now).format("YYYY.MM.DD");

  return dateString;
};

export const getDataFromLocalStorage = (key) => {
  let data = localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  }
  return null;
}

export const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

export const percentToNumber = (totalValue, percentage) => {
  return (totalValue * percentage) / 100;
}