import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../../../../shared";
import AuthService from "../../../../shared/services/authServices";
import qs from "query-string";
import "./styles.scss";
import { useSnackbar } from "notistack";

const LoginScreen = ({ verifyUserRequest, loginUserRequest }) => {
  let history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    let { query: { token = "", id_token = "", type = "" } = {} } = qs.parseUrl(window.location.href);
    (async () => {
      await new AuthService()
        .signinRedirectCallback()
        .then((data = {}) => {
          let { profile: { ssn = "", name = "", country = "", hetu = "" } = {} } = data;

          if (type == "verify" && token) {
            verifyUserRequest({
              payload: {
                country,
                _id: token,
                name,
                ssn: hetu ? hetu : ssn,
              },
              success: () => {
                history.replace(ROUTE_CONSTANTS.ROOT);
              },
              fail: (message) => {
                history.replace(ROUTE_CONSTANTS.ROOT);
                enqueueSnackbar(message, { variant: "error" });
              },
            });
          } else if (type == "login") {
            loginUserRequest({
              payload: {
                email: token,
                ssn: hetu ? hetu : ssn,
              },
              success: () => {
                history.replace(ROUTE_CONSTANTS.ROOT);
              },
              fail: (message) => {
                history.replace(ROUTE_CONSTANTS.ROOT);
                enqueueSnackbar(message, { variant: "error" });
              },
            });
          }
        })
        .catch((error) => {
          history.replace(ROUTE_CONSTANTS.ROOT);
        });
    })();
  }, []);
  return <div>Fetching data......</div>;
};

export default LoginScreen;
