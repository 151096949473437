export const GET_PROFILE_DATA = "GET_PROFILE_DATA";
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const UPDATE_PROFILE_DATA = "UPDATE_PROFILE_DATA";

export const getProfileRequest = (payload) => {
  return {
    type: GET_PROFILE_DATA,
    payload,
  };
};

export const updateProfileRequest = (payload) => {
  return {
    type: UPDATE_PROFILE_DATA,
    payload,
  };
};

export const setProfileData = (payload) => {
  return {
    type: SET_PROFILE_DATA,
    payload
  };
}
