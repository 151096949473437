import React, {useState} from "react"
import './style.scss'

const ReadMore = ({content, readMoreText="...read more", readLessText=" read less"}) => {
    const text = content;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text">
        {isReadMore ? <p dangerouslySetInnerHTML={{ __html: text?.slice(0, 250) }}></p> : <p dangerouslySetInnerHTML={{ __html: text }}></p>}
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? readMoreText : readLessText}
        </span>
      </p>
    );
  };

export default ReadMore