import { combineReducers } from "redux";
import CommonReducer from "./common";
import { reducer as formReducer } from "redux-form";
import WalletReducer from "./wallet";
import VerificationHistory from "./verificationHistory";

const rootReducer = combineReducers({
  common: CommonReducer,
  form: formReducer,
  wallet: WalletReducer,
  verification: VerificationHistory,
});

export default rootReducer;
