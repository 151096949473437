export const GET_LOCATION_DATA = "GET_LOCATION_DATA";
export const SET_LOCATION_DATA = "SET_LOCATION_DATA";

export const getLocationData = (payload) => {
    return {
        type: GET_LOCATION_DATA,
        payload,
    };
};

export const setLocationData = (data) => {
    return {
        type: SET_LOCATION_DATA,
        data,
    };
};
