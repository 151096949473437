import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import LiveChat from "react-livechat";
import jwt from "jsonwebtoken";
import Helmet from 'react-helmet';
import ReactSEOMetaTags from 'react-seo-meta-tags'

import SecuredRoutes from "./secured-routes";
import PublicRoutes from "./public-routes";
import { HEADER_CONTENT_TYPES, JWT_SIGN_KEY, ROUTE_CONSTANTS, TEXT_CONST } from "../../shared";
import {
  getHeaderDynamicContent,
  logoutRequest,
  getPopupContent,
  getSideBarMenuList,
  getSideBarMenuContent,
  getNotificationContent,
  getLocationData,
} from "../../redux/actions";
import ScrollToTop from "../ScrollToTop";
import { updateAuthToken } from "../../helpers";
const GROUP = process.env.REACT_APP_LIVE_CHAT_GROUP;

const RootRouter = ({
  userToken,
  userData,
  logoutRequest,
  getHeaderDynamicContent,
  getPopupContent,
  getSideBarMenuList,
  getSideBarMenuContent,
  getNotificationContent,
  getLocationData,
  dynamicContent = {}
}) => {
  let history = useHistory();

  useEffect(() => {
    getHeaderDynamicContent({ payload: {}, success: () => { } });
    getPopupContent({ payload: {}, success: () => { } });
    getSideBarMenuList({ payload: {}, success: () => { } });
    getSideBarMenuContent({ payload: {}, success: () => { } });
    getNotificationContent({ payload: {}, success: () => { } });
    getLocationData({ payload: {}, success: () => { } });
  }, [])


  useEffect(() => {
    if (userToken) {
      checkTokenValidation();
      var poll = setInterval(() => {
        checkTokenValidation(poll);
      }, 1000);
    }
  }, [userToken]);

  const checkTokenValidation = (poll) => {
    try {
      jwt.verify(userToken, JWT_SIGN_KEY);
    } catch (err) {
      console.error("ERROR::TOKEN", err.message);
      logoutRequest({}, cb => {
        history.push(ROUTE_CONSTANTS.ROOT);
        updateAuthToken("");
      });
      poll && clearInterval(poll);
    }
  };

  return (
    <React.Fragment>
      <ReactSEOMetaTags
        render={(el) => <Helmet>
          <title>{dynamicContent.title || TEXT_CONST.TITLE}</title>
          <meta name="description" content={dynamicContent.content || TEXT_CONST.META_DESCRIPTION} data-react-helmet="true" />
        </Helmet>
        }
      />
      <ScrollToTop>
        {userToken ? <SecuredRoutes /> : <PublicRoutes />}
      </ScrollToTop>
      <LiveChat
        license={12533709}
        visitor={{ name: userData.name || "", email: userData.email || "" }}
        group={GROUP ? Number(GROUP) : 0}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userToken: state.common.userToken,
    userData: state.common.userData || {},
    loading: state.common.loading,
    dynamicContent:
      (state.common.dynamicContent &&
        state.common.dynamicContent[HEADER_CONTENT_TYPES.TITLE_AND_META_DESCRIPTION]) ||
      {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutRequest: (payload, callback) => dispatch(logoutRequest(payload, callback)),
    getHeaderDynamicContent: (payload) => dispatch(getHeaderDynamicContent(payload)),
    getPopupContent: (payload) => dispatch(getPopupContent(payload)),
    getSideBarMenuList: (payload) => dispatch(getSideBarMenuList(payload)),
    getSideBarMenuContent: (payload) => dispatch(getSideBarMenuContent(payload)),
    getNotificationContent: (payload) => dispatch(getNotificationContent(payload)),
    getLocationData: (payload) => dispatch(getLocationData(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RootRouter);
