import React, { useEffect } from "react";
import {
  Footer,
  Header,
  NewsList,
} from "../../../../components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles.scss';

const NewsArticles = ({ history }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div className="main-content">
        <div className="sub_header">
          <Header />
        </div>
        <div className="mid-Mainsec">
          <div className="container">
            <NewsList history={history} isNewsRoute={true} />
          </div>
        </div>
      </div>
      <Footer />

    </React.Fragment>
  );
};

export default NewsArticles;
