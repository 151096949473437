import { takeLatest, all, put, select } from "redux-saga/effects";
import { getRequest, postRequest } from "../../helpers";
import { API, getUserData, STATUS_CODE } from "../../shared";
import {
  CLEAR_ALL_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS,
  MARK_AS_READ_MESSAGE,
  setNotificationData,
  setNotificationDataForListPopup,
  setUnreadMsgCount,
  startLoading,
  stopLoading,
  getUserDataSuccess
} from "../actions";

const LOCATION_ID = process.env.REACT_APP_LOCATION_ID;

function* getAllNotifications({ Data : {filterByDate,page,limit},callback = ()=>{} }) {
  try {
    let apiURL = `${API.GET_ALL_NOTIFICATIONS}?locationId=${LOCATION_ID}&limit=${limit}&page=${page}`;
    if (filterByDate) 
      apiURL = `${apiURL}&filterByDate=${filterByDate}`;
       
    const { data } = yield getRequest({
      API: apiURL,
    });

    if (data.statusCode == STATUS_CODE.successful) {
      yield put(setUnreadMsgCount(data.unreadMsgsCount));
      if (!filterByDate)  yield put(setNotificationDataForListPopup(data.data))
      yield put(setNotificationData(data.data))
      callback(data.data);
    } else {
      throw new Error(data.msg);
    }
  } catch (error) {
    console.log(error,"error");
    yield put(setUnreadMsgCount(0));
    yield put(setNotificationData([]))
  }
}

function* clearAllNotifications({ callback }) {
  try {

    yield put(startLoading())
    let userData = yield select(getUserData)
    const {data}  = yield postRequest({
      API: `${API.CLEAR_ALL_NOTIFICATIONS}`,
      DATA: {notificationClearAt: new Date()}
    });

    if (data.statusCode == STATUS_CODE.successful) {
      callback(true)
      userData.notificationClearAt = new Date()
      yield put(getUserDataSuccess(userData))
    } else {
      throw new Error(data.msg);
    }
  } catch (error) {
    callback(false)
  }
  finally {
    yield put(stopLoading())
  }
}

function* markReadMessage({ messageId }) {
  try {

   yield postRequest({
      API: `${API.MARK_AS_READ_MESSAGE}`,
      DATA: { messageId : messageId}
    });

  } catch (error) {
  }
}

function* NotificationSaga() {
  yield all([takeLatest(GET_ALL_NOTIFICATIONS, getAllNotifications)]);
  yield all([takeLatest(CLEAR_ALL_NOTIFICATIONS, clearAllNotifications)]);
  yield all([takeLatest(MARK_AS_READ_MESSAGE, markReadMessage)]);

}

export default NotificationSaga;
