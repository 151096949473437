export const GET_HEADER_CONTENT = "GET_HEADER_CONTENT";
export const SET_HEADER_CONTENT = "SET_HEADER_CONTENT";


export const getHeaderDynamicContent = (payload) => {
  return {
    type: GET_HEADER_CONTENT,
    payload
  };
};

export const setHeaderDynamicContent = (data) => {
    return {
        type: SET_HEADER_CONTENT,
        data
    }
}
