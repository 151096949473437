// const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {SERVER_URL,ROUTES } from "../../../shared";
import defaultImg from "../../../assets/images/no-image.png";
import { defaultConfig } from "../../../config/default";

const NewsCard = ({
  key,
  data,
  history,
  isNewsRoute = false,
  headerContent,
  userToken
}) => {
  const newsExcerpt = data.excerpt;
  const newsTitle = data.title;
  const [content, setContent] = useState('');

  useEffect(() => {
    if (data.content) {
      // let str = data.content.substr(0, 200);
      let doc = new DOMParser().parseFromString(data.content, 'text/html');
      if (doc.querySelector('body')) {
        let textContent = doc.querySelector('body').textContent;
        let _content = textContent
          ? textContent.length > 120
            ? textContent.substr(0, 110) + "..."
            : textContent
          : "";
        setContent(_content);
      }
    }
  }, [data.content])


  return (
    <div className={`${isNewsRoute ? 'col-lg-4' : 'col-lg-12'} bg_white`} key={key}>
      <div className="news-Wrap">
        <div className="news-Topimg">
          {data.featuredImage
            ? <img
              style={{ cursor: "pointer" }}
              src={`${SERVER_URL}${data.featuredImage}`}
              alt="..."
              onClick={() =>
                history.push(
                  `${data.permalink.indexOf("/") !== -1
                    ?
                    `${userToken
                      ? ('/' + (defaultConfig.LOCATION).toLowerCase() + ROUTES.NEWS + data.permalink)
                      : (ROUTES.NEWS + data.permalink)}`
                    :
                    `${userToken
                      ? ('/' + (defaultConfig.LOCATION).toLowerCase() + ROUTES.NEWS + "/" + data.permalink)
                      : (ROUTES.NEWS + "/" + data.permalink)}`
                  }`
                )
              } />
            : <img
              style={{ cursor: "pointer" }}
              src={defaultImg}
              alt="..."
              onClick={() =>
                history.push(
                  `${data.permalink.indexOf("/") !== -1
                    ?
                    `${userToken
                      ? ('/' + (defaultConfig.LOCATION).toLowerCase() + ROUTES.NEWS + data.permalink)
                      : (ROUTES.NEWS + data.permalink)}`
                    :
                    `${userToken
                      ? ('/' + (defaultConfig.LOCATION).toLowerCase() + ROUTES.NEWS + "/" + data.permalink)
                      : (ROUTES.NEWS + "/" + data.permalink)}`
                  }`
                )
              } />}
        </div>
        <div className="box-Centersec">
          <div className="nwbox-title">
            <h3
              style={{ cursor: "pointer" }}
              onClick={() =>
                history.push(
                  `${data.permalink.indexOf("/") !== -1
                    ?
                    `${userToken
                      ? ('/' + (defaultConfig.LOCATION).toLowerCase() + ROUTES.NEWS + data.permalink)
                      : (ROUTES.NEWS + data.permalink)}`
                    :
                    `${userToken
                      ? ('/' + (defaultConfig.LOCATION).toLowerCase() + ROUTES.NEWS + "/" + data.permalink)
                      : (ROUTES.NEWS + "/" + data.permalink)}`
                  }`
                )
              }>{newsTitle}</h3>
          </div>
          <div className="news-Boxdate">
            <div className="pub-Mainse">
              <div className="pub-Mainsec">
                <div className="pub-Leftsec">
                  <h4>
                    <span> {data.author?.name || data.author}</span>{" "}
                  </h4>
                </div>
                <div className="pub-Right">
                  <h4>
                    <span className="right_Atinfo">
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                      {"\u00a0\u00a0"}
                      {moment(data.updatedAt).format("DD-MM-YYYY")}
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="news-Boxtxt">
            <p>{newsExcerpt}</p>
          </div>
          {content ?
            <div className="news-Boxtxt-description">
              <p dangerouslySetInnerHTML={{ __html: content }}></p>
            </div>
            : null}
          <div className="read-Bnwsec">
            {" "}
            <Link
              to={
                `${data.permalink.indexOf("/") !== -1
                  ?
                  `${userToken
                    ? ('/' + (defaultConfig.LOCATION).toLowerCase() + ROUTES.NEWS + data.permalink)
                    : (ROUTES.NEWS + data.permalink)}`
                  :
                  `${userToken
                    ? ('/' + (defaultConfig.LOCATION).toLowerCase() + ROUTES.NEWS + "/" + data.permalink)
                    : (ROUTES.NEWS + "/" + data.permalink)}`
                }`
              }
              className="primary-Gbtn full-Width"
              rel="nofollow"
              onClick={() =>
                history.push(
                  `${data.permalink.indexOf("/") !== -1
                    ?
                    `${userToken
                      ? ('/' + (defaultConfig.LOCATION).toLowerCase() + ROUTES.NEWS + data.permalink)
                      : (ROUTES.NEWS + data.permalink)}`
                    :
                    `${userToken
                      ? ('/' + (defaultConfig.LOCATION).toLowerCase() + ROUTES.NEWS + "/" + data.permalink)
                      : (ROUTES.NEWS + "/" + data.permalink)}`
                  }`
                )
              }
            >
              {headerContent.readMoreNewsLabel || "LÄS MER"}
            </Link>
          </div>
        </div>
      </div>
    </div>

  )
};

export default NewsCard;
