import reducer from "../reducers";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createStore, applyMiddleware } from "redux";
import dataSaga from "../sagas";

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "user",
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducer);
export const store = createStore(persistedReducer, applyMiddleware(...[sagaMiddleware]));
export const persistor = persistStore(store);
sagaMiddleware.run(dataSaga);
