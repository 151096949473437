import "./App.scss";
import { Provider } from "react-redux";
import { createRef } from "react";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/lib/integration/react";
import { BrowserRouter } from "react-router-dom";
import {
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

import RootRouter from "./routes/1.0";
import { SnackbarProvider } from "notistack";
import { LoaderHOC, NotificationToast } from "./components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function initFontAwesomeIcons() {
  library.add(
    faTimesCircle,
  );
}

function App() {
  initFontAwesomeIcons();
  const notistackRef = createRef();

  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <LoaderHOC>
          <SnackbarProvider
            autoHideDuration={3000}
            maxSnack={1}
            ref={notistackRef}
            action={(key) => (
              <button onClick={onClickDismiss(key)} className="close">
                <FontAwesomeIcon icon="times-circle" />
              </button>
            )}
          >
            <NotificationToast />
            <BrowserRouter>
              <RootRouter />
            </BrowserRouter>
          </SnackbarProvider>
        </LoaderHOC>
      </Provider>
    </PersistGate>
  );
}

export default App;
