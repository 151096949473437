import Screen from "./screen";
import { connect } from "react-redux";
import {
  getAllNotifications,
  markAsReadMessage,
  clearAllNotifications
} from "../../../../redux/actions";

const mapStateToProps = (state) => {
  let {notificationClearAt} = state.common.userData
  return { notifications: state.common.notificationData, notificationClearAt: notificationClearAt ? notificationClearAt : null};
};

const mapDispatchToProps = (dispatch) => {
  return {  
    getAllNotifications: (data,callback) => dispatch(getAllNotifications(data,callback)), 
    markAsReadMessage: (messageId) => dispatch(markAsReadMessage(messageId)),
    clearAllNotifications: (callback) =>
    dispatch(clearAllNotifications(callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
