import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";

import { IMAGES, TEXT_CONST, OTP_REGX, POPUP_FORM_CONTENT_TYPES, ROUTE_CONSTANTS } from "../../../shared";
import MaterialPhoneInput from "../Material-Phone-Input";
import "./styles.css";
import { loginUserRequest, verifyUserRequest } from "../../../redux/actions";
import MaterialInput from "../Material-Input";

let LoginModal = ({
  onClose = () => { },
  loginFirstStep = () => { },
  loginSecondStep = () => { },
  onOpenSignup = () => { },
  show,
  handleSubmit = () => { },
  popupContent = {}
}) => {
  const history = useHistory();
  const [error, setError] = useState({});
  const [phoneInfo, setPhoneInfo] = useState({});
  const [mobile, setMobile] = useState("");
  const [loginStep, setLoginStep] = useState(1);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleOnChange = (name) => {
    setError((err) => ({ ...err, [name]: "" }));
  };

  const checkForValidation = (values) => {
    let { mobileNo } = values || {};
    if (!mobileNo) {
      setError((err) => ({
        ...err,
        mobileNumber: TEXT_CONST.LOGIN_OR_SIGNUP.PHONE_NUMBER_REQUIRED,
      }));
      return true;
    }
    else if (mobileNo.length < 8) {
      setError((err) => ({
        ...err,
        mobileNumber: TEXT_CONST.LOGIN_OR_SIGNUP.PHONE_NUMBER_INVALID,
      }));
      return true;
    }
    else if (mobileNo.length > 11) {
      setError((err) => ({
        ...err,
        mobileNumber: TEXT_CONST.LOGIN_OR_SIGNUP.PHONE_NUMBER_INVALID,
      }));
      return true;
    }
    return false;
  };

  const submit = (values, dispatch) => {
    let { mobileNumber, otp } = values || {};

    let { dialCode, countryCode, value } = phoneInfo || {};
    setMobile(value);
    let sanitizedMobilNo = (value || "").replaceAll(/\s/g, "");
    sanitizedMobilNo = sanitizedMobilNo.replaceAll("-", "");
    sanitizedMobilNo = sanitizedMobilNo.replaceAll("(", "");
    sanitizedMobilNo = sanitizedMobilNo.replaceAll(")", "");
    let mobileNo = sanitizedMobilNo.substr(
      (dialCode || "").length + 1,
      (value || "").length
    );
    values.mobileNo = mobileNo;

    if (loginStep == 1) {
      let isValidationError = checkForValidation(values);
      if (isValidationError) return;
      loginFirstStep({
        payload: {
          mobileNumber: sanitizedMobilNo,
          processType: 2,
        },
        success: () => {
          setLoginStep(2);
        },
        fail: (message) => {
          enqueueSnackbar(message, { variant: "error" });
        },
      });
      return;
    }
    if (!otp || !otp.trim()) {
      setError((err) => ({ ...err, otp: TEXT_CONST.LOGIN_OR_SIGNUP.OTP_REQUIRED }));
      return true;
    }
    else if (!OTP_REGX.test(otp)) {
      setError((err) => ({ ...err, otp: TEXT_CONST.LOGIN_OR_SIGNUP.OTP_INVALID }));
      return true;
    }
    loginSecondStep({
      payload: {
        mobileNumber: sanitizedMobilNo,
        OTP: otp,
      },
      success: () => {
        setError({});
        // onClose();
        history.push(ROUTE_CONSTANTS.DASHBOARD)
        dispatch(reset("login-form"));
      },
      fail: (message) => {
        enqueueSnackbar(message, { variant: "error" });
      },
    });
  };


  useEffect(() => {
    document.querySelector("header").style.position = "unset"
    return document.querySelector("header").style.position = "fixed"
  })

  return (
    <Modal onHide={onClose} show={show} size="lg" centered backdrop="static">
      <div className=" modal-Wrapse">
        <div className="close-Modse">
          <a onClick={onClose}>+</a>
        </div>
        <div className="login-Wrapse sign-Wrapse">
          <div className="row no-gutters">
            <div className="col-lg-7">
              <div className="left-Logse">
                <h3 dangerouslySetInnerHTML={{ __html: popupContent.title || TEXT_CONST.LOGIN }}></h3>
                <p dangerouslySetInnerHTML={{
                  __html: popupContent.introContent || ` Välkommen tillbaka till Riskfritt.se, passa på att jämföra,
                  läsa recensionerna och se vad som passar dig bäst innan du
                  bestämmer dig för att spela på ett casino.`}}>
                </p>
                {/* <div className="inputGrp"> */}
                <form onSubmit={handleSubmit(submit)} className="text-left">
                  <div className="signup-form-items">
                    {loginStep == 2 ? (
                      <React.Fragment>
                        <div className="form-mat-input mt-3">
                          <h3>{popupContent.otpInfoLabel1 || `A Code has been sent to ${mobile}`} <br /> {popupContent.otpInfoLabel2 || <> Please enter the Code to verify your <br /> phone number.</>} </h3>
                          <Field
                            name="otp"
                            component={MaterialInput}
                            type="text"
                            placeholder={popupContent.otpFieldLabel || "Code"}
                            config={{ required: true }}
                            onChange={() => handleOnChange("otp")}
                          />
                        </div>
                        {!!error.otp && (
                          <label className={"error-txt"}>{error.otp}</label>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="form-mat-input mt-3">
                          <Field
                            name="mobileNumber"
                            component={MaterialPhoneInput}
                            placeholder={popupContent.phoneNumberLabel || "Phone Number"}
                            config={{ required: true, disabled: true }}
                            onChange={(value) => {
                              handleOnChange("mobileNumber")
                              setPhoneInfo(value)
                            }}
                          />
                        </div>
                        {!!error.mobileNumber && (
                          <label className={"error-txt"}>
                            {error.mobileNumber}
                          </label>
                        )}
                      </React.Fragment>
                    )}
                    <div className="lg-Btnse">
                      <button
                        type="submit"
                        className="primary-Gbtn full-Width dark-Green "
                      >
                        {loginStep == 1 ? `${popupContent.buttonLabel || "LOGGA IN"}` : `${popupContent.otpVerifyButtonLabel || "VERIFY ACCOUNT"}`}

                      </button>
                    </div>
                    <div className="frgtSec text-center">
                      <p >
                        {popupContent.haveAnAccountLabel || "Har du inget konto?"}
                        {"\u00a0"}
                        <a onClick={onOpenSignup}>{popupContent.loginHereLabel || "Skapa ett här!"}</a>
                      </p>
                    </div>
                  </div>
                </form>
                {/* </div> */}
              </div>
              <div class="bottom-line-container">
                <p className="bottomLine" dangerouslySetInnerHTML={{ __html: popupContent.bottomLine || "By continuing you will receive a one-time verification code to your phone number by SMS. Message and data rates may apply." }}></p>
              </div>
            </div>
            <div className="col-lg-5 d-none d-lg-flex">
              <div className="right-Logse">
                <h3 dangerouslySetInnerHTML={{ __html: popupContent.subTitle || "Är det första gången <br /> du loggar in? " }}>
                </h3>
                <div className="binny-Mose">
                  <img src={IMAGES.RABIT_ANIMATION} alt="..." />
                </div>
                <p dangerouslySetInnerHTML={{
                  __html: popupContent.bottomContent || `Ingen fara, det är väldigt enkelt att logga in. Du behöver
                  endast ange din e-postadress som du registrerade dig med och
                  licka på Logga in-knappen. Sedan öppnar du din BankID app och
                  bekräftar att du vill logga in. `}}>

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

LoginModal = reduxForm({
  // a unique name for the form
  form: "login-form",
})(LoginModal);

const mapStateToProps = (state) => {
  return {
    initialVlaues: {},
    popupContent: state.common.popupContent && state.common.popupContent[POPUP_FORM_CONTENT_TYPES.LOGIN] || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginFirstStep: (payload) => dispatch(verifyUserRequest(payload)),
    loginSecondStep: (payload) => dispatch(loginUserRequest(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
