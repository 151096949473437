import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import "./styles.scss";
import {
  FOOTER_TYPES,
  HEADER_CONTENT_TYPES,
  IMAGES,
  SERVER_URL,
} from "../../../shared";
import { TEXT_CONST } from "../../../shared/constants/strings";
import { getFooterLinks } from "../../../redux/actions/footer";
import { Link } from "react-router-dom";
import { defaultConfig } from "../../../config/default";

const Footer = ({ getAllFooterLinks, footerLinksData, headerContent }) => {
  const [upperFootersFirstArr, setUpperFootersFirstArr] = useState([]);
  const [upperFootersSecondArr, setUpperFootersSecondArr] = useState([]);

  useEffect(() => {
    getAllFooterLinks({
      payload: {
        type: FOOTER_TYPES.UPPER,
      },
      success: () => {
        getAllFooterLinks({
          payload: {
            type: FOOTER_TYPES.LOWER,
          },
          success: () => {},
        });
      },
    });
  }, []);

  useEffect(() => {
    setUpperFootersFirstArr((footerLinksData.upper || []).slice(0, 4));
    setUpperFootersSecondArr(
      (footerLinksData.upper || []).slice(4, footerLinksData.upper.length)
    );
  }, [footerLinksData.upper.length]);

  return (
    <React.Fragment>
      <div className="footer-bottom">
        <div className="lay-wrap">
          <div className="bot-Layse">
            {/* <img src={IMAGES.FOOTER_LAYER} alt="..." /> */}
          </div>
        </div>
        <footer>
          <div className="top-Footsec">
            <div className="container">
              <div className="inner-Topfoot">
                <div className="row">
                  <div className="col-md-12">
                    <div className="footer-Logosec">
                      <a href="javascript:void(0);">
                        <img
                          src={
                            defaultConfig.LOCATION.toLowerCase() === "fi"
                              ? IMAGES.RISKIVAPAA_LOGO
                              : IMAGES.LOGO_2
                          }
                          alt="logo"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-md-12 d-lg-block">
                    <div className="foot-Toplkse">
                      <ul>
                        {upperFootersFirstArr.map((item, index) => (
                          <li key={index}>
                            <a
                              href={index == 2 ? null : `${item.link}`}
                              onClick={
                                index == 2
                                  ? () => window.LC_API.open_chat_window()
                                  : null
                              }
                            >
                              {item.text}
                            </a>
                          </li>
                        ))}
                      </ul>
                      <ul className="bottom-Rnav">
                        {upperFootersSecondArr.map((item, index) => (
                          <li key={index}>
                            <a href={`${item.link}`}>{item.text}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="foot-Wraptx">
                      <div className="mobile-Rfooter">
                        {/* <div className="mobile-Rfinse ">
                          <div className="row d-lg-none">
                            <div className="col-6">
                              <div className="foot-Robse">
                                <div className="fot-Rtitlese">
                                  <h3> MER OM RISKFRITT.SE</h3>
                                </div>
                                <ul>
                                  {TEXT_CONST.FOOTER_HEADINGS.map((item, index) => (
                                    <li key={index}>
                                      <Link to={item.link || "/"}>
                                        <i className="fa fa-angle-double-right"></i>
                                        {item.title}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="foot-Robse">
                                <div className="fot-Rtitlese">
                                  <h3>VÄNLIGEN LÄS IGENOM</h3>
                                </div>
                                <ul>
                                  {TEXT_CONST.FOOTER_HEADINGS.map((item, index) => (
                                    <li key={index}>
                                      <Link to={item.link || "/"}>
                                        <i className="fa fa-angle-double-right"></i>
                                        {item.title}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="fot-Rwrapse">
                          <div className="fot-Rlogose">
                            <div className="fot-Rtitlese">
                              <h3
                                dangerouslySetInnerHTML={{
                                  __html:
                                    headerContent &&
                                    headerContent.weUseOutsideLabel,
                                }}
                              ></h3>
                            </div>
                            <ul>
                              {/* <li>
                              <a href="https://www.bankid.com/en/">
                                <img src={IMAGES.FT_LOGO_1} alt="..." />
                              </a>
                            </li> */}
                              <li>
                                <a href="https://www.zimpler.com/">
                                  <img src={IMAGES.ZIMPLER} alt="..." />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-Footer">
            <div className="container">
              <div className="fot-Botsec">
                {/* <ul className="mob-Stes  d-lg-none">
                  <li className="midse">
                    <span>18+</span> {TEXT_CONST.PLAY_TOO_CONTACT}{" "}
                    <a >
                      {" "}
                      <img src={IMAGES.BOT_IMG_1} alt="..." />{" "}
                    </a>
                  </li>
                </ul> */}
                <ul>
                  {(footerLinksData.lower || []).map((data, index) => {
                    if (index == 0) {
                      return (
                        <li className="bor-Rno" key={index}>
                          2022 © <a href={data.link}>{data.text}</a>
                        </li>
                      );
                    } else if (index == 1) {
                      return (
                        <li className="midse d-block" key={index}>
                          <span>18+</span> {data.text}
                          {"\u00a0"}
                          <a href={data.link}>
                            {" "}
                            <i className="">
                              <img
                                src={`${SERVER_URL}${data.iconPath}`}
                                alt="..."
                              />{" "}
                            </i>
                          </a>
                        </li>
                      );
                    } else if (index == 2) {
                      return (
                        <li key={index}>
                          {" "}
                          <a href={data.link}>
                            {" "}
                            <i className="mr-md-2">
                              <img
                                src={`${SERVER_URL}${data.iconPath}`}
                                alt="..."
                              />
                            </i>{" "}
                            {/* {data.text} */}
                          </a>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    footerLinksData: state.common.footerLinksData,
    headerContent:
      state.common.dynamicContent[HEADER_CONTENT_TYPES.MAIN_FOOTER] || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllFooterLinks: (payload) => dispatch(getFooterLinks(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
