import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

import {
  CasinoAccordionList,
  Footer,
  Header,
  HomeBanner,
  HowItWorks,
  LatestMembers,
  NewsCard,
  NewsList,
  QA_Accordion,
} from "../../../../components";
import { getDataFromLocalStorage, PERSIST_KEYS } from "../../../../shared";

const Page = ({ userToken, location, id, getPageByPageId }) => {
  let array = [
    {
      component: <QA_Accordion />,
      name: "QA_Accordion",
    },
    {
      component: <NewsList />,
      name: "NewsList",
    },
    {
      component: <CasinoAccordionList />,
      name: "CasinoAccordionList",
    },
    {
      component: <HowItWorks />,
      name: "HowItWorks"
    }
  ];

  const [adminData, setAdminData] = useState({});
  const [pageData, set_pageData] = useState({});
  const [isRender, set_isRender] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [contentShortcodes, set_contentShortcodes] = useState([]); // state for shortcodes in content
  const [contentData, set_contentData] = useState(""); // state for only strings in content
  const [sectionsShortCodes, set_sectionsShortCodes] = useState([]);
  const [sectionContent, set_sectionContent] = useState({});
  const [section1, set_section1] = useState([]);
  const [section2, set_section2] = useState([]);

  useEffect(() => {
    let payload = {
      pageId: id,
    };
    getPageByPageId({
      payload,
      success: (data) => {
        set_pageData(data);
      },
      fail: (message) => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      },
    });
  }, [id]);

  // let string = `This {{Location}} text is set {{Thissisisisisi}} using dangerouslySetInnerHTML {{HowItWorks}} `;

  // let pattern = /[^{{}}]*(?=\})/g;
  // let d = string.match(pattern);
  // let e = d.filter((item) => item);
  // console.log("string searcg results", e);
  // console.log("pageSara", pageData);
  useEffect(() => {
    if (pageData) {
      let admin = getDataFromLocalStorage(PERSIST_KEYS.ADMIN);
      if (admin && admin.CommonReducer) {
        setAdminData(JSON.parse(admin.CommonReducer));
      }
      set_contentData(pageData.content);
      pageData && pageData.sections && sectionsShort(pageData.sections);
      set_isRender(true);

      // let content_shortcodes = pageData && pageData.content && findShortCodes(pageData.content);
      // let content = pageData && pageData.content && removeShortcodes(pageData.content);
      // set_contentData(content);
      // set_contentShortcodes(content_shortcodes);
      // let r = pageData && pageData.sections && sectionsShort(pageData.sections);
      // // let totalsections = pageData && pageData.sections && pageData.sections.length;
      // // console.log("sect", totalsections, pageData.sections);
      // // pageData && pageData.sections.map((item) => console.log("item in sections", item, totalsections));
      // let section1 = pageData && pageData.section && pageData.section[0] && findShortCodes(pageData.section[0]);
      // set_section1(section1);
      // let section2 =
      //   pageData && pageData && pageData.section && pageData.section[1] && findShortCodes(pageData.section[1]);
      // set_section2(section2);
      // set_isRender(true);
    }
  }, [pageData]);

  const sectionsShort = (sections) => {
    let obj = {};
    let obj2 = {};
    sections.map((item, index) => {
      let data = item.content;
      let data2 = item.content;
      obj[`section${index + 1}`] = data;
      obj2[`section${index + 1}`] = data2;
    });
    set_sectionContent({ ...obj });
    set_sectionsShortCodes({ ...obj2 });
  };

  // const removeShortcodes = (string) => {
  //   return string.replace(/{[^}]*}}/g, "");
  // };

  // const findShortCodes = (string) => {
  //   let pattern = /[^{{}}]*(?=\})/g;
  //   let d = string.match(pattern);
  //   let e = d && d.filter((item) => item);
  //   return e;
  // };

  // sectionContent.map((item, index) => (
  //   console.log("item,")
  // ))

  return (
    <React.Fragment>
      {pageData.title ?
        <Helmet>
          <title>{pageData.title}</title>
        </Helmet>
        : null}
      <div className="main-content">
        <Header isHomepage={false} />
        {/* <HomeBanner data={location.state || {}} title={pageData.title} /> */}
        <div className="mid-Mainsec text-left userpanel_view">
          <div className="container">
            {!userToken && adminData.access_token && (
              <Link className="customise-icon-container" to={`/admin/pages/edit-page/${id}`}>
                <span className="customise-text">Edit</span>
                <i class="fa fa-edit"></i>
              </Link>
            )}
            <div className="dyn_pages " dangerouslySetInnerHTML={{ __html: contentData }} />
            {/* {contentShortcodes &&
              contentShortcodes.map((item) => {
                let result = array.findIndex((data) => data.name == item);

                let c = array[result] && array[result].component;
                return c;
              })} */}
            {sectionContent &&
              Object.keys(sectionContent).map((item, index) => {
                return (
                  <div key={index}>
                    <div key={index} dangerouslySetInnerHTML={{ __html: sectionContent[item] }} />
                    {/* {sectionsShortCodes &&
                      sectionsShortCodes[item] &&
                      sectionsShortCodes[item].map((items) => {
                        let result = array.findIndex((data) => data.name == items);
                        let c = array[result] && array[result].component;
                        return c;
                      })} */}
                  </div>
                );
              })}
            {/* {sectionsShortCodes &&
            Object.keys(sectionsShortCodes).map((item, index) => {
              console.log("item", sectionsShortCodes[item]);
              return sectionsShortCodes[item].map((items) => {
                console.log("itemsss", items);
                let result = array.findIndex((data) => data.name == items);
                console.log("result", result);
                let c = array[result].component;
                return c;
              });
            })} */}
            {/* {sectionsShortCodes &&
            sectionsShortCodes.map((item) => {
              //let Data = item.toString();
              //  onsole.log("Data", Data); c
              let result = array.findIndex((data) => data.name == item);
              console.log("result", result);
              let c = array[result].component;
              return c;
            })} */}
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Page;
