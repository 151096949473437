import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { Field, reduxForm, reset } from "redux-form";
import { useSnackbar } from "notistack";

import "./styles.css";
import { IMAGES, TEXT_CONST, EMAIL_REGX, NAME_REGX, OTP_REGX, POPUP_FORM_CONTENT_TYPES, ROUTE_CONSTANTS } from "../../../shared";
import MaterialInput from "../Material-Input";
import MaterialPhoneInput from "../Material-Phone-Input";
import MaterialRadioButtons from "../Material-Radio-Buttons";
import MaterialDateInput from "../Material-Date-Input";
import { verifyUserRequest, registerUserRequest } from "../../../redux/actions";

let SignUpModal = ({
  show,
  onOpenLogin = () => { },
  signupFirstStep = () => { },
  signupSecondStep = () => { },
  onClose = () => { },
  handleSubmit = () => { },
  footerLinksData,
  popupContent
}) => {
  const history = useHistory();
  const [termsAccepted, toggleTerms] = useState(false);
  const [receivePromotionSMS, toggleReceivePromotionSMS] = useState(false);
  const [signupStep, setSignupStep] = useState(1);
  const [mobile, setMobile] = useState("");
  const [error, setError] = useState({});
  const [phoneInfo, setPhoneInfo] = useState({});
  const [termsLink, setTermsLink] = useState("terms-and-conditions");

  let clicked = useRef(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


  const handleOnChange = (name) => {
    setError((err) => ({ ...err, [name]: "" }));
  };

  const onClickTerms = () => {
    if (!clicked.current) {
      setError((err) => ({ ...err, terms: "" }));
      toggleTerms(!termsAccepted);
      clicked.current = true;
      setTimeout(() => {
        clicked.current = false;
      }, 500);
    }
    if (termsAccepted) {
      toggleReceivePromotionSMS(true);
    }
  };

  const onClickReceivePromotionSMS = () => {
    if (!clicked.current) {
      setError((err) => ({ ...err, terms: "" }));
      toggleReceivePromotionSMS(!receivePromotionSMS);
      clicked.current = true;
      setTimeout(() => {
        clicked.current = false;
      }, 500);
    }
  }

  const submit = (values, dispatch) => {
    let { email, name, dob, gender, mobileNumber, otp } = values || {};
    let { dialCode, countryCode, value } = phoneInfo || {};
    setMobile(value);
    let sanitizedMobilNo = (value || "").replaceAll(/\s/g, "");
    sanitizedMobilNo = sanitizedMobilNo.replaceAll("-", "");
    sanitizedMobilNo = sanitizedMobilNo.replaceAll("(", "");
    sanitizedMobilNo = sanitizedMobilNo.replaceAll(")", "");
    let mobileNo = sanitizedMobilNo.substr(
      (dialCode || "").length + 1,
      (value || "").length
    );
    values.mobileNo = mobileNo;

    if (signupStep == 1) {
      let isValidationError = checkForValidation(values);
      if (isValidationError) return;
      signupFirstStep({
        payload: {
          mobileNumber: sanitizedMobilNo,
          processType: 1,
        },
        success: () => {
          setSignupStep(2);
        },
        fail: (message) => {
          enqueueSnackbar(message, { variant: "error" });
        },
      });
      return;
    }
    if (!otp || !otp.trim()) {
      setError((err) => ({ ...err, otp: TEXT_CONST.LOGIN_OR_SIGNUP.OTP_REQUIRED }));
      return true;
    }
    else if (!OTP_REGX.test(otp)) {
      setError((err) => ({ ...err, otp: TEXT_CONST.LOGIN_OR_SIGNUP.OTP_INVALID }));
      return true;
    }
    signupSecondStep({
      payload: {
        email,
        name,
        dob,
        // gender,
        mobileNumber: sanitizedMobilNo,
        OTP: otp,
        country: (countryCode || "").toUpperCase(),
        areTermsAccepted: true,
        receivePromotionSMS: receivePromotionSMS
      },
      success: () => {
        setError({});
        history.push(ROUTE_CONSTANTS.DASHBOARD);
        // onClose();
        dispatch(reset("signup-form"));
      },
      fail: (message) => {
        enqueueSnackbar(message, { variant: "error" });
      },
    });
  };

  const checkForValidation = (values) => {
    let { email, name, dob, gender, mobileNumber, mobileNo } = values || {};
    let age = moment().diff(moment(dob, "YYYY-MM-DD"), "years");
    if (!email) {
      setError((err) => ({ ...err, email: TEXT_CONST.SIGNUP.EMAIL_REQUIRED }));
      return true;
    } else if (!EMAIL_REGX.test(email)) {
      setError((err) => ({ ...err, email: TEXT_CONST.SIGNUP.EMAIL_INVALID }));
      return true;
    }
    if (!name || !name.trim()) {
      setError((err) => ({ ...err, name: TEXT_CONST.SIGNUP.NAME_REQUIRED }));
      return true;
    }
    // else if (!NAME_REGX.test(name)) {
    //   setError((err) => ({ ...err, name: TEXT_CONST.SIGNUP.NAME_INVALID }));
    //   return true;
    // }
    if (age < 18) {
      setError((err) => ({ ...err, dob: TEXT_CONST.SIGNUP.DOB }));
      return true;
    }
    // if (!gender) {
    //   setError((err) => ({ ...err, gender: TEXT_CONST.SIGNUP.GENDER_REQUIRED }));
    //   return true;
    // }
    if (!mobileNo) {
      setError((err) => ({
        ...err,
        mobileNumber: TEXT_CONST.LOGIN_OR_SIGNUP.PHONE_NUMBER_REQUIRED,
      }));
      return true;
    }
    else if (mobileNo.length < 8) {
      setError((err) => ({
        ...err,
        mobileNumber: TEXT_CONST.LOGIN_OR_SIGNUP.PHONE_NUMBER_INVALID,
      }));
      return true;
    }
    else if (mobileNo.length > 11) {
      setError((err) => ({
        ...err,
        mobileNumber: TEXT_CONST.LOGIN_OR_SIGNUP.PHONE_NUMBER_INVALID,
      }));
      return true;
    }

    if (!termsAccepted) {
      setError((err) => ({ ...err, terms: TEXT_CONST.SIGNUP.ACCEPT_TERMS }));
      return true;
    }
    return false;
  };

  useEffect(() => {
    document.querySelector("header").style.position = "unset"
    return document.querySelector("header").style.position = "fixed"
  })

  useEffect(() => {
    var term_data = footerLinksData.upper.find((item) => (item.text || "").toLowerCase().includes("term"))
    if (term_data && term_data.link) {
      setTermsLink(term_data.link)
    }
  }, [footerLinksData.upper.length])

  return (
    <Modal
      onHide={() => {
        setError({});
        onClose();
      }}
      show={show}
      size="lg"
      centered
      backdrop="static"
    >
      {" "}
      <div className="open_Mod09 ">
        <div className="close-Modse">
          <a
            onClick={() => {
              setError({});
              onClose();
            }}
          >
            +
          </a>
        </div>
        <div className="login-Wrapse sign-Wrapse">
          <div className="row no-gutters">
            <div className="col-lg-7">
              <div className="left-Logse">
                <h3 dangerouslySetInnerHTML={{ __html: popupContent.title || TEXT_CONST.REGISTER }}></h3>
                <p dangerouslySetInnerHTML={{
                  __html: popupContent.introContent || ` Det är både enkelt och gratis att registrera sig på
                  Riskfritt.se. När du har registrerat dig kan du logga in med
                  din e-postadress och BankID för att ta del av våra fördelar
                  där du kan få tillbaka upp till 500 KR på din första
                  insättning, när du spelar på ett nytt casino.`}}>
                </p>
                <form onSubmit={handleSubmit(submit)} className="text-left">
                  <div className="signup-form-items">
                    {signupStep == 2 ? (
                      <React.Fragment>
                        <div className="form-mat-input mt-3">
                          <h3>{popupContent.otpInfoLabel1 || `A Code has been sent to ${mobile}`} <br /> {popupContent.otpInfoLabel2 || <> Please enter the Code to verify your <br /> phone number.</>} </h3>
                          <Field
                            name="otp"
                            component={MaterialInput}
                            type="text"
                            placeholder={popupContent.otpFieldLabel || "Code"}
                            config={{ required: true }}
                            onChange={() => handleOnChange("otp")}
                          />
                        </div>
                        {!!error.otp && (
                          <label className={"error-txt"}>{error.otp}</label>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="form-mat-input mt-3">
                          <Field
                            name="name"
                            component={MaterialInput}
                            type="text"
                            placeholder={popupContent.nameLabel || TEXT_CONST.ENTER_NAME}
                            config={{ required: true }}
                            onChange={() => handleOnChange("name")}
                          />
                        </div>
                        {!!error.name && (
                          <label className={"error-txt"}>{error.name}</label>
                        )}
                        <div className="form-mat-input mt-3">
                          <Field
                            name="email"
                            component={MaterialInput}
                            type="email"
                            placeholder={popupContent.emailLabel || TEXT_CONST.ENTER_EMAIL}
                            config={{ required: true }}
                            onChange={() => handleOnChange("email")}
                          />
                        </div>
                        {!!error.email && (
                          <label className={"error-txt"}>{error.email}</label>
                        )}
                        <div className="form-mat-input mt-3">
                          <Field
                            name="mobileNumber"
                            component={MaterialPhoneInput}
                            placeholder={popupContent.phoneNumberLabel || "Phone Number"}
                            config={{ required: true }}
                            onChange={(value) => {
                              handleOnChange("mobileNumber");
                              setPhoneInfo(value);
                            }}
                          />
                          {!!error.mobileNumber && (
                            <label className={"error-txt"}>
                              {error.mobileNumber}
                            </label>
                          )}
                        </div>

                        <div className="form-mat-input mt-3">
                          <Field
                            name="dob"
                            component={MaterialDateInput}
                            type="date"
                            onChange={() => handleOnChange("dob")}
                            placeholder={popupContent.dobLabel || "Date of Birth"}
                            config={{ required: true }}
                          />
                        </div>
                        {!!error.dob && (
                          <label className={"error-txt"}>{error.dob}</label>
                        )}
                        {/* <div className="form-mat-input mt-3">
                            <Field
                              name="gender"
                              component={MaterialRadioButtons}
                              config={{ required: true }}
                              onChange={() => handleOnChange("gender")}
                            />
                          </div>
                          {!!error.gender && (
                            <label className={"error-txt"}>{error.gender}</label>
                          )} */}

                        <div onClick={onClickTerms} className="chk-Bxse">
                          <div className="btn-group-toggle" data-toggle="buttons">
                            <label
                              className={`btn ${termsAccepted ? "active" : ""}`}
                            >
                              <input type="checkbox" />
                              {popupContent.checkboxLabel || "Jag har läst och godkänt"} <span class="color-green"><a href={popupContent.checkboxAnchorLink || `${termsLink}`} onClick={(e) => { e.preventDefault(); window.open(popupContent.checkboxAnchorLink || `${termsLink}`, "_top") }}> {popupContent.checkboxAnchorLabel || "Användarvillkoren"}</a></span>
                            </label>
                          </div>
                          {!!error.terms && (
                            <label className={"error-txt"}>{error.terms}</label>
                          )}
                        </div>
                        <div onClick={onClickReceivePromotionSMS} className="chk-Bxse">
                          <div className="btn-group-toggle" data-toggle="buttons">
                            <label
                              className={`btn ${receivePromotionSMS ? "active" : ""}`}
                            >
                              <input type="checkbox" />
                              {popupContent.checkboxLabelForReceiveSMS || "Haluan saada tekstiviestejä."}
                            </label>
                          </div>

                        </div>
                      </React.Fragment>
                    )}
                    <div className="lg-Btnse">
                      {" "}
                      <button
                        type={"submit"}
                        className="primary-Gbtn full-Width dark-Green "
                      >
                        {signupStep == 1 ? `${popupContent.buttonLabel || "REGISTRERA DIG"}` : `${popupContent.otpVerifyButtonLabel || "VERIFY ACCOUNT"}`}
                      </button>
                    </div>
                    {
                      signupStep == 1 ?
                        <div className="frgtSec text-center">
                          <p>
                            {popupContent.haveAnAccountLabel || "Har du inget konto?"}
                            {"\u00a0"}
                            <a onClick={onOpenLogin}>{popupContent.loginHereLabel || "Logga in här!"}</a>
                          </p>
                        </div>
                        : null
                    }
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-5 d-none d-lg-flex">
              <div className="right-Logse">
                <h3 dangerouslySetInnerHTML={{ __html: popupContent.subTitle || "Är det första gången <br /> du loggar in? " }}>

                </h3>
                <div className="binny-Mose">
                  <img src={IMAGES.RABIT_ANIMATION} alt="..." />{" "}
                </div>
                <p dangerouslySetInnerHTML={{
                  __html: popupContent.bottomContent || `Ingen fara, det är väldigt enkelt att logga in. Du behöver
                  endast ange din e-postadress som du registrerade dig med och
                  licka på Logga in-knappen. Sedan öppnar du din BankID app och
                  bekräftar att du vill logga in. `}}>

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
SignUpModal = reduxForm({
  // a unique name for the form
  form: "signup-form",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SignUpModal);

const mapStateToProps = (state) => {
  var date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return {
    initialValues: {
      dob: moment(date).format("YYYY-MM-DD")
    }, footerLinksData: state.common.footerLinksData,
    popupContent: state.common.popupContent[POPUP_FORM_CONTENT_TYPES.REGISTER] || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signupFirstStep: (payload) => dispatch(verifyUserRequest(payload)),
    signupSecondStep: (payload) => dispatch(registerUserRequest(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpModal);
