import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Checkbox } from "@material-ui/core";
import ReactSelect from "react-select";
import { IMAGES } from "../../../shared";

import "./style.css";
import { POPUP_FORM_CONTENT_TYPES } from "../../../shared";
import { loginUserRequest, verifyUserRequest } from "../../../redux/actions";
import ModalContent from "../../cells/CasinoAccordionList/ModalContent";

const options = [
  {
    value: "fi",
    label: "Fi"
  },
  {
    value: "en",
    label: "En"
  },
]

let OnboardingModal = ({
  onClose = () => { },
  show,
  showModalAgain,
  setShowModalAgain = () => { },
  popupContent = {},
}) => {

  const [language, setLanguage] = useState('fi')
  const [popupEnglishContent, setPopupEnglishContent] = useState({});
  const [popupFinnishContent, setPopupFinnishContent] = useState({});

  useEffect(() => {
    setPopupEnglishContent({
      checkboxLabel: popupContent?.checkboxLabel_En,
      leftIntroContent: popupContent?.leftIntroContent_En,
      rightIntroContent: popupContent?.rightIntroContent_En,
      leftTitle: popupContent?.leftTitle_En,
      rightTitle: popupContent?.leftTitle_En,
    })
    setPopupFinnishContent({
      checkboxLabel: popupContent?.checkboxLabel_Fi,
      leftIntroContent: popupContent?.leftIntroContent_Fi,
      rightIntroContent: popupContent?.rightIntroContent_Fi,
      leftTitle: popupContent?.leftTitle_Fi,
      rightTitle: popupContent?.leftTitle_Fi,
    })
  }, [])

  const handleChangeLanguage = (language) => {
    setLanguage(language)
  }

  return (
    <Modal onHide={onClose} show={show} size="lg" centered backdrop="static">
      <div className="onboarding-modal modal-Wrapse">
        {/* <div className="close-Modse">
          <a onClick={onClose}>+</a>
        </div> */}
        {/* <div class="modal-header">
          <div className="drop-down" >
            <i class="fa fa-exclamation"></i><h5 className="" dangerouslySetInnerHTML={{ __html: popupContent.title_Fi ? (language === "fi" ? popupContent.title_Fi : popupContent.title_En) : 'Pamagat sa Filipino' }} />
          </div>
          <ReactSelect
            label="select"
            options={options}
            onChange={(value) => setLanguge(value.value)}
            defaultValue={options[0]}
            className={"react-select-dropdown mb-3"}
            classNamePrefix={"react-select-dropdown-prefix"}
            isSearchable={false}
          />


        </div> */}
        <div className="modal-content">
        <div className="close-Modse">
          <a onClick={onClose}>+</a>
        </div> 

          {/* <div class="binny-Mose">
            <img src={IMAGES.RABIT_ANIMATION} alt="Image" />
          </div> */}

          {
            language === "fi" ? 
            <ModalContent showModalAgain={showModalAgain} setShowModalAgain={setShowModalAgain} popupContent={popupFinnishContent} handleChangeLanguage={handleChangeLanguage}/> 
            : 
            <ModalContent showModalAgain={showModalAgain} setShowModalAgain={setShowModalAgain} popupContent={popupEnglishContent} handleChangeLanguage={handleChangeLanguage}/>
          }

        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    initialVlaues: {},
    popupContent: state.common.popupContent && state.common.popupContent[POPUP_FORM_CONTENT_TYPES.ONBOARDING_CONTENT] || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginFirstStep: (payload) => dispatch(verifyUserRequest(payload)),
    loginSecondStep: (payload) => dispatch(loginUserRequest(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingModal);
