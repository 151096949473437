import React, { useEffect } from "react";
import {
    CasinoAccordionList,
    Footer,
    Header,
} from "../../../../components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import './styles.scss';

const CasinoReview = ( props) => {
    return (
        <React.Fragment>
            <div className="main-content">
            <div className="sub_header">
                <Header />
            </div>
            <div className="mid-Mainsec">
                <div className="container">
                    <CasinoAccordionList hideLoadMoreBtn = {true} />
                </div>
            </div>
            </div>
            <Footer />
        </React.Fragment>
    );
};

export default CasinoReview;
