export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const VERIFY_USER_REQUEST = 'VERIFY_USER_REQUEST';
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';

export const registerUserRequest = (payload) => {
    return {
        type: REGISTER_USER_REQUEST,
        payload
    };
};

export const verifyUserRequest = (payload) => {
    return {
        type: VERIFY_USER_REQUEST,
        payload
    };
};

export const loginUserRequest = (payload) => {
    return {
        type: LOGIN_USER_REQUEST,
        payload
    };
};