import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { connect } from "react-redux";

import "./styles.scss";

import { getFrequentAskedQuestions } from "../../../redux/actions";
import { HEADER_CONTENT_TYPES } from "../../../shared";

const QA_Accordion = ({ getFrequentAskedQuestions, headerContent }) => {
  const [openedQuestion, setOpenedQuestion] = useState(new Set());
  const [allQuestionsForFirstCol, set_allQuestionsForFirstCol] = useState([]);
  const [allQuestionsForSecondCol, set_allQuestionsForSecondCol] = useState([]);

  const [showFull, set_showFull] = useState(false);
  const [id, set_id] = useState("");

  useEffect(() => {
    getFrequentAskedQuestions({
      success: (data) => {
        if (data) {
          if (data.length <= 1) {
            set_allQuestionsForFirstCol(data)
            set_allQuestionsForSecondCol([])
          }
          else {
            let arr_mid = parseInt(data.length / 2)
            set_allQuestionsForFirstCol(data.slice(0, arr_mid))
            set_allQuestionsForSecondCol(data.slice(arr_mid, data.length))
          }
        }
      },
      fail: (message) => { },
    });
  }, []);



  const returnFAQDiv = (item, index, array) => {
    return (
      <div className="play-Boxtxt mb-2" key={index}>
        <div
          onClick={() => {
            let questionsClone = new Set(openedQuestion);
            if (questionsClone.has(`b-${index + 1}-${array}`)) {
              questionsClone.delete(`b-${index + 1}-${array}`);
            } else {
              questionsClone.add(`b-${index + 1}-${array}`);
            }
            setOpenedQuestion(questionsClone);
          }}
          className="play-Header"
        >
          <h3
            className={classNames([{ collapsed: !openedQuestion.has(`b-${index + 1}-${array}`) }])}
            data-toggle="collapse"
            data-target={`#b-${index + 1}`}
          >
            <span>✅</span>
            {item.question}
          </h3>
        </div>
        <div
          className={classNames(["play-Bodyse", { "collapse-Tab": !openedQuestion.has(`b-${index + 1}-${array}`) }])}
          id={`#b-${index + 1}-${array}`}
        >
          <div className="play-Bodytxt acc-Bottomse">
            <p>
              {showFull && item._id == id ? item.answer : item && item.answer}
              {/* <span>{!showFull && item.answer.length >= 160 ? "..." : null}</span> */}
            </p>

            {/* {showFull && item._id == id ? (
              <span
                style={{ cursor: "pointer", fontWeight: "400", fontSize: "12px", color: "#0D532D" }}
                onClick={() => {
                  set_showFull(false);
                }}
              >
                show less
              </span>
            ) : item.answer.length >= 160 ? (
              <span
                style={{ cursor: "pointer", fontSize: "12px", fontWeight: "400", color: "#0D532D" }}
                onClick={() => {
                  set_id(item._id);
                  set_showFull(true);
                }}
              >
                show more
              </span>
            ) : null} */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="play-Boxse">
      {allQuestionsForFirstCol.length ? (
        <div className="title-Betse">
          <h2 dangerouslySetInnerHTML={{ __html: headerContent && headerContent.title }}></h2>
          <p dangerouslySetInnerHTML={{ __html: headerContent && headerContent.content }}></p>
        </div>
      ) : null}
      <div className="row">
        {
          allQuestionsForFirstCol.length ?
            <div className="col-lg-6">
              {allQuestionsForFirstCol.map((item, index) => {
                return returnFAQDiv(item, index, 1)
              })}
            </div>
            : null
        }
        {
          allQuestionsForSecondCol.length ?
            <div className="col-lg-6">
              {allQuestionsForSecondCol.map((item, index) => {
                return returnFAQDiv(item, index, 2)
              })}
            </div>
            : null
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { headerContent: state.common.dynamicContent[HEADER_CONTENT_TYPES.FAQ] || {}, };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFrequentAskedQuestions: (payload) => dispatch(getFrequentAskedQuestions(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QA_Accordion);
