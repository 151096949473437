import React from "react";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";

const LoaderHOC = ({ loading, timeout, children }) => (
  <React.Fragment>
    {loading && (
      <div
        style={{
          alignItems: "center",
          backgroundColor: `rgba(0,0,0, 0.5)`,
          bottom: 0,
          display: "flex",
          height: "100%",
          justifyContent: "center",
          left: 0,
          position: "fixed",
          right: 0,
          top: 0,
          zIndex: 999999,
        }}
      >
        <Loader color="#FFFFFF" height={75} type="Watch" visible width={75} />
      </div>
    )}
    {children}
  </React.Fragment>
);

const mapStateToProps = (state) => {
  return {
    loading: state.common.loading,
  };
};

export default connect(mapStateToProps)(LoaderHOC);
