import { takeLatest, all, put, select } from "redux-saga/effects";
import { getRequest } from "../../helpers";
import { API, FOOTER_TYPES, STATUS_CODE } from "../../shared";
import { GET_FOOTER_LINKS, setFooterLinksData } from "../actions/footer";
const LOCATION_ID = process.env.REACT_APP_LOCATION_ID;


function* getAllFooterLinks({ payload: { payload, success = () => {} } = {} }) {
  try {
   
    const { data } = yield getRequest({
      API: `${API.GET_ALL_FOOTER_LINKS}?locationId=${LOCATION_ID}&type=${payload.type}`,
    });
    if (data.statusCode == STATUS_CODE.successful) {
      let obj = {}
      if(payload.type == FOOTER_TYPES.UPPER){
        obj.upper = data.data
      }
      else if (payload.type == FOOTER_TYPES.LOWER){
        obj.lower = data.data
      }
      yield put(setFooterLinksData(obj));
      success(true)
    } else {
      throw new Error(data.msg);
    }
  } catch (error) {
    yield put(setFooterLinksData({
      upper:[],
      lower:[]
    }));
  }
}


function* FooterSaga() {
  yield all([takeLatest(GET_FOOTER_LINKS, getAllFooterLinks)]);

}

export default FooterSaga;
