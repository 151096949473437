import { takeLatest, all, put } from "redux-saga/effects";
import { getRequest } from "../../helpers";
import { API, STATUS_CODE } from "../../shared";
import { GET_LOCATION_DATA, setLocationData } from "../actions";

export const LOCATION_ID = process.env.REACT_APP_LOCATION_ID;

function* getLocationData({
    payload: { payload, validateUser = false, success = () => { }, fail = () => { } } = {},
}) {
    try {
        const { data } = yield getRequest({
            API: `${API.GET_LOCATION_BY_ID}/${LOCATION_ID}`,
        });
        if (data.statusCode == STATUS_CODE.successful) {
            yield put(setLocationData(data.data));
            success(true)
        } else {
            throw new Error(data.msg);
        }
    } catch (error) {
        yield put(setLocationData({}));
    }
}

function* LocationSaga() {
    yield all([takeLatest(GET_LOCATION_DATA, getLocationData)]);
}

export default LocationSaga;
